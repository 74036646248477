var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Alert", {
    attrs: { show: _vm.showAlert, "show-close": true },
    on: { cancel: _vm.handleClose },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v("Send Payment Link")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "form",
              [
                _c("div", { staticClass: "flex flex-col text-gray-500" }, [
                  _vm.booking.PassengerNotificationPhone
                    ? _c("span", [
                        _vm._v(
                          "Please confirm the last three digits of the passenger's number or enter the number manually:"
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          "Please enter the passenger's number to send SMS"
                        ),
                      ]),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-2 mb-4" },
                  [
                    !_vm.editMode
                      ? _c("div", { staticClass: "form-input flex" }, [
                          _c(
                            "span",
                            { staticClass: "flex-1 mt-1 font-semibold" },
                            [_vm._v(_vm._s(_vm.shownNumber))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "material-icons-outlined",
                              on: {
                                click: function ($event) {
                                  _vm.editMode = true
                                },
                              },
                            },
                            [_vm._v("edit")]
                          ),
                        ])
                      : _c("VueTelInput", {
                          staticClass: "form-input",
                          attrs: { mode: "international", open: true },
                          on: { validate: _vm.handleValidation },
                          scopedSlots: _vm._u(
                            [
                              _vm.booking.PassengerNotificationPhone
                                ? {
                                    key: "icon-right",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "material-icons-outlined block mt-1",
                                            on: {
                                              click: function ($event) {
                                                _vm.editMode = false
                                              },
                                            },
                                          },
                                          [_vm._v("close")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.phoneNumber,
                            callback: function ($$v) {
                              _vm.phoneNumber = $$v
                            },
                            expression: "phoneNumber",
                          },
                        }),
                    _vm.phoneNumber && !_vm.isValidNumber
                      ? _c("div", { staticClass: "text-red-600 mt-1" }, [
                          _c("span", [_vm._v("Number provided is invalid")]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("action-button", {
                  staticClass: "w-full mt-2",
                  attrs: {
                    disabled: _vm.editMode && !_vm.isValidNumber,
                    text: "Send",
                    circular: false,
                    color: "#1FD0A3",
                    async: true,
                    action: _vm.sendLink,
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }