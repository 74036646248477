var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex p-2 border border-gray-300 rounded mb-2 gap-4 items-center",
      class: {
        "border-red-600 bg-red-600 text-white": _vm.danger,
        "border-green-600 bg-green-600 text-white": _vm.success,
        "opacity-50": _vm.disabled,
      },
    },
    [
      _c("span", { staticClass: "material-icons-outlined text-3xl" }, [
        _vm._v(_vm._s(_vm.icon)),
      ]),
      _c("span", { staticClass: "text-lg font-semibold flex-1" }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
      _c("span", { staticClass: "material-icons-outlined text-3xl" }, [
        _vm._v("east"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }