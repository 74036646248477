import axios from 'axios';
import Vue from 'vue';

export const verifyDriverToken = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${process.env.VUE_APP_CAB9ENDPOINT}/auth/token`, {
                headers: {
                    Authorization: `bearer ${Vue.$cookies.get('token')}`,
                },
            });

            if (result.data.DriverId) {
                resolve(result.data);
            } else {
                reject(new Error('Not a driver token'));
            }
        } catch (err) {
            return reject(err);
        }
    });
};

export const getBookingDetails = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${process.env.VUE_APP_ENDPOINT}/driver-api/booking/details?bookingId=${bookingId}`, {
                headers: {
                    Authorization: `Bearer ${Vue.$cookies.get('token')}`,
                },
            });
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const getDriverState = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${process.env.VUE_APP_ENDPOINT}/driver-api/state`, {
                headers: {
                    Authorization: `Bearer ${Vue.$cookies.get('token')}`,
                },
            });
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const getDriverAppConfig = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${process.env.VUE_APP_ENDPOINT}/driver-api/app-settings`, {
                headers: {
                    Authorization: `Bearer ${Vue.$cookies.get('token')}`,
                },
            });
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};
