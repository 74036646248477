<template>
    <div class="h-full relative select-none">
        <BookingWrapper :booking="booking" v-if="booking" :use-local-meter="useLocalMeter" :local-meter="localMeter"></BookingWrapper>
        <div class="flex align-middle h-screen items-center text-center p-4" v-else>
            <div class="w-full text-center flex flex-col items-center">
                <img src="@/assets/images/icons/icon.jpg" class="w-24 border border-gray-100 rounded-xl mb-4" />
                <h2 class="mb-2 text-gray-400">Cab9 Go</h2>
                <p>There was an error loading the booking details. Please close this page and try again.</p>
                <button class="close" @click="closePage()">Close Page</button>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from '@/main';
import {getBookingDetails} from '@/services/BookingService';
import {getQueuePosition} from './services/CallRequestService';
import {handleJourneyCompletion} from '@/services/PostMessageService';
import {handleMeterPriceError} from './utils/sentryErrors';
import {initialiseSockets} from '@/socket';
import {localMeterMixin} from '@/mixins/localMeterMixin';
import {recheckMobileState} from './services/PostMessageService';
import BookingWrapper from '@/components/BookingWrapper';

export default {
    name: 'Cab9Go',
    props: {
        initialBooking: {
            type: Object,
        },
    },
    components: {
        BookingWrapper,
    },
    mixins: [localMeterMixin],
    data() {
        return {
            booking: null,
        };
    },
    methods: {
        updateBookingFinancials(newValue) {
            const {ActualCost, AsDirected, MeterCost, Extras, Adjustments, WaitingTotal, PricingMode, BookingStatus, TaxAmount, WaitingTax, PaymentMethod} = newValue;

            let total = 0;

            if (this.useLocalMeter) {
                if (AsDirected) {
                    total = this.localMeter.MeterCost || 0;
                } else if (PricingMode === 'AsQuoted') {
                    total = ActualCost;
                } else {
                    total = this.localMeter.MeterCost || 0;
                }

                total += this.localMeter.WaitingCost || 0;

                if (this.localMeter.TaxAmount) {
                    total += this.localMeter.TaxAmount;
                }
            } else {
                if (AsDirected) {
                    total = MeterCost || 0;
                } else if (PricingMode === 'AsQuoted') {
                    total = ActualCost;
                } else {
                    if (BookingStatus === 'InProgress' || BookingStatus === 'Arrived' || BookingStatus === 'Clearing') {
                        total = MeterCost || 0;
                    } else {
                        total = ActualCost;
                    }
                }

                if (WaitingTotal && WaitingTotal > 0) {
                    total += WaitingTotal;
                }
                if (WaitingTax && WaitingTax > 0) {
                    total += WaitingTax;
                }

                if (TaxAmount) {
                    total += TaxAmount;
                }
            }

            let extrasTotal = 0;
            if (Extras && Extras.length) {
                Extras.forEach(extra => {
                    if (PaymentMethod === 'OnAccount' || PaymentMethod === 'Card') {
                        total += extra.DriverAmount + (extra.DriverTaxAmount || 0);
                        extrasTotal += extra.DriverAmount + (extra.DriverTaxAmount || 0);
                    } else {
                        total += extra.Amount + (extra.TaxAmount || 0);
                        extrasTotal += extra.Amount + (extra.TaxAmount || 0);
                    }
                });
            }

            if (Adjustments && Adjustments.length) {
                Adjustments.forEach(adjustment => {
                    total += adjustment.Amount;
                    total += adjustment.TaxAmount;
                });
            }

            this.$set(this.booking, '_grandTotal', total);
            this.$set(this.booking, '_extrasTotal', extrasTotal);
        },
        deregisterBusListeners() {
            bus.$off('fetchUpdatedBooking');

            bus.$off('bookingUpdate');
            bus.$off('bookingPriceUpdate');

            bus.$off('bookingLocalWaitingUpdate');
            bus.$off('clearLocalWaiting');

            bus.$off('journeyCompleted');
        },
        setInitialBooking() {
            const booking = {...this.initialBooking};
            const lastStop = booking.BookingStops[booking.BookingStops.length - 1];
            if ((booking.AsDirected || booking.BookingStops.length === 1) && lastStop.StopSummary !== 'As Directed') {
                booking.AsDirected = true;
                if (booking.BookingStatus === 'InProgress') booking.NextStop = booking.BookingStops.length + 1;
                booking.BookingStops = [...booking.BookingStops, {StopSummary: 'As Directed'}];
            }
            this.booking = booking;
        },
        closePage() {
            try {
                if (window.iOSMessageListener) {
                    window.iOSMessageListener(
                        JSON.stringify({
                            type: 'ui',
                            message: 'destroyPage',
                        })
                    );
                }

                // eslint-disable-next-line
                if (cab9GoWeb)
                    // eslint-disable-next-line
                    cab9GoWeb.postMessage(
                        JSON.stringify({
                            type: 'ui',
                            message: 'destroyPage',
                        })
                    );
            } catch (err) {
                console.error(err);
            }
        },
        async fetchQueuePosition() {
            const queue = await getQueuePosition();
            if (queue.position) {
                this.booking._callRequested = true;
            }
        },
    },
    watch: {
        booking(newValue) {
            // add last stop for 'As Directed' bookings
            const lastStop = newValue.BookingStops[newValue.BookingStops.length - 1];
            if ((newValue.AsDirected || newValue.BookingStops.length === 1) && lastStop.StopSummary !== 'As Directed') {
                if (newValue.BookingStatus === 'InProgress') this.$set(this.booking, 'NextStop', newValue.BookingStops.length + 1);
                this.$set(this.booking, 'AsDirected', true);
                this.$set(this.booking, 'BookingStops', [...newValue.BookingStops, {StopSummary: 'As Directed'}]);
            }

            this.updateBookingFinancials(newValue);
        },
        localMeter: {
            handler() {
                this.updateBookingFinancials(this.booking);
            },
            deep: true,
        },
    },
    created() {
        this.setInitialBooking();

        bus.$on('bookingUpdate', booking => {
            if (booking.Id !== this.booking.Id) return;
            booking.MeterCost = booking.MeterCost || 0;
            booking.WaitingTotal = booking.WaitingTotal || 0;

            if (this.booking.MeterCost > booking.MeterCost) {
                handleMeterPriceError(
                    `Lower meter cost received. BookingId - ${this.booking.Id}. LocalId - ${this.booking.LocalId}. Previous cost - ${this.booking.MeterCost}. New cost - ${booking.MeterCost}`
                );
                booking.MeterCost = this.booking.MeterCost;
            }

            const localWatingTime = this.booking.LocalWaitingTime;
            if (!booking.DriverId || (this.booking.DriverId && this.booking.DriverId.toLowerCase()) !== booking.DriverId.toLowerCase()) {
                recheckMobileState();
            }

            this.booking = {...booking, LocalWaitingTime: localWatingTime};
        });

        bus.$on('bookingLocalWaitingUpdate', (time = 1) => {
            if (this.booking.LocalWaitingTime === 0) {
                this.$set(this.booking, 'LocalWaitingTime', time);
            } else {
                const currentWaiting = this.booking.LocalWaitingTime ? this.booking.LocalWaitingTime : this.useLocalMeter ? this.localMeter.WaitingTime : this.booking.WaitingTimeInSeconds;
                this.$set(this.booking, 'LocalWaitingTime', currentWaiting + time);
            }
        });

        bus.$on('clearLocalWaiting', () => {
            this.$set(this.booking, 'LocalWaitingTime', null);
        });

        bus.$on('fetchUpdatedBooking', async (clearLocalWaiting = false, fetchInBackground = false) => {
            try {
                if (!fetchInBackground) {
                    bus.$emit('loading', true);
                }

                const _updatedBooking = await getBookingDetails(this.booking.Id);

                _updatedBooking.MeterCost = _updatedBooking.MeterCost || 0;
                _updatedBooking.WaitingTotal = _updatedBooking.WaitingTotal || 0;
                if (this.booking.MeterCost > _updatedBooking.MeterCost) {
                    handleMeterPriceError(
                        `Lower meter cost received. BookingId - ${this.booking.Id}. LocalId - ${this.booking.LocalId}. Previous cost - ${this.booking.MeterCost}. New cost - ${_updatedBooking.MeterCost}`
                    );
                    _updatedBooking.MeterCost = this.booking.MeterCost;
                }

                // preserve local waiting time, if required
                _updatedBooking.LocalWaitingTime = clearLocalWaiting ? null : this.booking.LocalWaitingTime;

                if (!_updatedBooking.DriverId || (this.booking.DriverId && this.booking.DriverId.toLowerCase()) !== _updatedBooking.DriverId.toLowerCase()) {
                    recheckMobileState();
                }

                this.booking = _updatedBooking;

                if (!fetchInBackground) {
                    bus.$emit('loading', false);
                }
            } catch (err) {
                console.error(err);
                if (!fetchInBackground) {
                    bus.$emit('loading', false);
                }
            }
        });

        bus.$on('journeyCompleted', () => {
            handleJourneyCompletion();
        });

        bus.$on('bookingPriceUpdate', val => {
            if ((val.BookingId && val.BookingId.toLowerCase()) !== (this.booking.Id && this.booking.Id.toLowerCase())) return;
            val.MeterCost = val.MeterCost || 0;
            if (this.booking.MeterCost > val.MeterCost) {
                handleMeterPriceError(
                    `Lower meter cost received. BookingId - ${this.booking.Id}. LocalId - ${this.booking.LocalId}. Previous cost - ${this.booking.MeterCost}. New cost - ${val.MeterCost}`
                );
                val.MeterCost = this.booking.MeterCost;
            }
            this.$set(this.booking, 'MeterCost', val.MeterCost);
            this.$set(this.booking, 'ActualCost', val.ActualCost);
            this.$set(this.booking, 'WaitingTimeInSeconds', val.WaitingTime);
            this.$set(this.booking, 'WaitingTotal', val.WaitingCost);
            this.$set(this.booking, 'ActualDistance', val.ActualDistance);
            this.updateBookingFinancials(this.booking);
        });

        this.fetchQueuePosition();
    },
    async mounted() {
        setTimeout(() => {
            const bindSocketsToApp = initialiseSockets.bind(this);
            bindSocketsToApp();
        }, 500);
    },
    destroyed() {
        this.deregisterBusListeners();
    },
};
</script>

<style lang="scss">
.close {
    padding: 8px 12px;
    background-color: #e90000;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 16px;
}
</style>
