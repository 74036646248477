var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-full flex flex-col" }, [
    _c(
      "div",
      {
        staticClass:
          "flex items-center px-2 py-4 border-b border-gray-300 relative justify-center",
      },
      [
        _c("action-button", {
          staticClass: "absolute left-0",
          attrs: {
            icon: "arrow_back",
            color: "transparent",
            action: _vm.close,
          },
        }),
        _c("h5", { staticClass: "text-center m-0 font-semibold" }, [
          _vm._v("Add Via Stop"),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex-1 flex flex-col px-1 py-3 gap-2 overflow-hidden" },
      [
        _c(
          "div",
          { staticClass: "flex flex-col flex-1 overflow-hidden pr-6" },
          [
            _vm._l(_vm.previousStops, function (stop) {
              return _c(
                "div",
                {
                  key: stop.StopOrder,
                  staticClass: "flex items-center py-1 stop",
                },
                [
                  _c("span", {
                    staticClass: "bg-green-500 w-2 h-2 rounded-full m-2",
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-1 items-center bg-white border border-gray-200 rounded px-2 py-1",
                    },
                    [
                      _c("input", {
                        staticClass: "flex-1 h-10 bg-white p-2",
                        attrs: { disabled: "" },
                        domProps: { value: stop.StopSummary },
                      }),
                    ]
                  ),
                ]
              )
            }),
            _c("div", { staticClass: "flex items-center py-1 stop" }, [
              _c("span", {
                staticClass: "bg-red-500 w-2 h-2 rounded-full m-2",
              }),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-1 items-center gap-2 bg-white border border-gray-200 rounded px-2 py-1",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchText,
                        expression: "searchText",
                      },
                    ],
                    staticClass: "flex-1 h-10 bg-white p-2 focus:outline-none",
                    attrs: { placeholder: "Search address" },
                    domProps: { value: _vm.searchText },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchText = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass:
                        "material-icons-outlined cursor-pointer text-gray-500",
                      on: { click: _vm.clearSearch },
                    },
                    [_vm._v("close")]
                  ),
                ]
              ),
            ]),
            _vm._l(_vm.afterStops, function (stop, index) {
              return _c(
                "div",
                {
                  key: stop.StopOrder,
                  staticClass: "flex items-center py-1",
                  class: { stop: index < _vm.afterStops.length - 1 },
                },
                [
                  _c("span", {
                    staticClass: "bg-green-500 w-2 h-2 rounded-full m-2",
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-1 items-center bg-white border border-gray-200 rounded px-2 py-1",
                    },
                    [
                      _c("input", {
                        staticClass: "flex-1 h-10 bg-white p-2",
                        attrs: { disabled: "" },
                        domProps: { value: stop.StopSummary },
                      }),
                    ]
                  ),
                ]
              )
            }),
            _vm.searchResults.length
              ? _c(
                  "div",
                  {
                    staticClass:
                      "border border-gray-300 rounded mt-2 overflow-scroll ml-6",
                  },
                  _vm._l(_vm.searchResults, function (result, index) {
                    return _c(
                      "div",
                      {
                        key: "" + index + result.StopSummary,
                        staticClass:
                          "border-b border-gray-300 px-2 py-3 bg-gray-100",
                        on: {
                          click: function () {
                            return _vm.selectAddress(result)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "font-semibold dark:text-gray-600" },
                          [_vm._v(_vm._s(result.StopSummary))]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "px-6 mt-2" },
          [
            _c("action-button", {
              attrs: {
                text: "Add",
                circular: false,
                color: "#e90000",
                action: _vm.addViaStop,
                disabled: !_vm.selectedAddress,
                async: true,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }