<template>
    <div class="h-full flex flex-col">
        <div class="flex items-center px-2 py-4 border-b border-gray-300 relative justify-center">
            <action-button icon="arrow_back" color="transparent" :action="close" class="absolute left-0" />
            <h5 class="text-center m-0 font-semibold">{{ type === 'add' ? 'Add Dropoff' : 'Amend Dropoff' }}</h5>
        </div>
        <div class="flex-1 flex flex-col p-3 gap-2 overflow-hidden">
            <span class="text-xl">Search Address</span>
            <span class="text-sm text-gray-500">Enter the drop off location of your passenger to get navigation assistance.</span>
            <SearchBar class="flex-1" :booking="booking" @select="handleAddressSelection" />
            <action-button :text="type === 'add' ? 'Add' : 'Amend'" :circular="false" color="#e90000" :action="type === 'add' ? addDropoff : amendDropoff" :disabled="!selectedAddress" :async="true" />
        </div>
    </div>
</template>

<script>
import {addBookingStop, getCompleteAddress, updateBookingStop} from '@/services/StopService.js';
import {bus} from '@/main';
import {showErrorDialog} from '@/services/DialogService';
import {showSuccessToast} from '../../services/ToastService';
import ActionButton from '@/components/ActionButton';
import SearchBar from './SearchBar';

export default {
    name: 'DropOff',
    props: {
        booking: {
            type: Object,
        },
        type: {
            type: String,
        },
    },
    components: {
        ActionButton,
        SearchBar,
    },
    data() {
        return {
            selectedAddress: null,
        };
    },
    methods: {
        async addDropoff() {
            try {
                let address = this.selectedAddress;
                if (address.PlaceId && address.LocationType && address.LocationType.includes('Google')) {
                    address = await getCompleteAddress(address.PlaceId);
                }
                await addBookingStop(this.booking.Id, {...address, Type: 'DropOff', StopSummary: this.selectedAddress.StopSummary});
                bus.$emit('openHome');
                bus.$emit('fetchUpdatedBooking');
                showSuccessToast('Drop off added successfully.');
            } catch (err) {
                showErrorDialog(err, 'There was an error adding the drop off.', 'Try Again');
            }
        },
        async amendDropoff() {
            try {
                let address = this.selectedAddress;
                if (address.PlaceId && address.LocationType && address.LocationType.includes('Google')) {
                    address = await getCompleteAddress(address.PlaceId);
                }
                const {BookingStops} = this.booking;
                await updateBookingStop(this.booking.Id, {
                    ...address,
                    StopOrder: BookingStops.length,
                    Id: BookingStops[BookingStops.length - 1].Id,
                    StopSummary: this.selectedAddress.StopSummary,
                });
                bus.$emit('openHome');
                bus.$emit('fetchUpdatedBooking');
                showSuccessToast('Drop off amended successfully.');
            } catch (err) {
                showErrorDialog(err, 'There was an error amending the drop off.', 'Try Again');
            }
        },
        close() {
            this.$emit('close');
        },
        handleAddressSelection(address) {
            this.selectedAddress = address;
        },
    },
};
</script>
