<template>
    <div class="alert fixed bottom-0 left-0 top-0 flex items-end w-full" v-if="show">
        <Transition name="slide-up-down">
            <div class="flex flex-col rounded-tl-xl rounded-tr-xl w-full bg-white p-4" v-if="showBox">
                <div class="flex justify-between">
                    <h3 class="mb-0">
                        <slot name="title"></slot>
                    </h3>
                    <div class="close-btn">
                        <ActionButton v-if="showClose" :async="false" :icon="'close'" :action="hideAlert" :color="'#e90000'" :icon-color="'#fff'"></ActionButton>
                    </div>
                </div>
                <div>
                    <slot name="body"></slot>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import ActionButton from '@/components/ActionButton';
export default {
    name: 'Alert',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        showClose: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ActionButton,
    },
    data() {
        return {
            showBox: false,
        };
    },
    methods: {
        hideAlert() {
            this.showBox = false;
            setTimeout(() => {
                this.$emit('cancel');
            }, 400);
        },
    },
    created() {
        setTimeout(() => {
            this.showBox = true;
        }, 0);
    },
};
</script>

<style scoped lang="scss">
.alert {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
}
.close-btn {
    transform: scale(0.6);
    transform-origin: top right;
}

.slide-up-down-enter-active {
    animation-duration: 200ms;
    animation-fill-mode: both;
    animation-name: slideInUp;
}

.slide-up-down-leave-active {
    animation-duration: 200ms;
    animation-fill-mode: both;
    animation-name: slideOutDown;
}
</style>
