var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("RatePassenger", {
        staticClass: "mb-4",
        attrs: { booking: _vm.booking },
      }),
      _vm.paymentLinkSent
        ? _c("PaymentStatus", { attrs: { booking: _vm.booking } })
        : _vm._e(),
      _vm.booking.PaymentMethod === "OnAccount"
        ? _c("DriverCommission", {
            staticClass: "mb-4",
            attrs: { booking: _vm.booking },
          })
        : _vm._e(),
      _vm.loadingUpcomingBooking
        ? _c(
            "div",
            { staticClass: "flex justify-center" },
            [
              _c("LoadingTitle", {
                staticClass: "text-gray-400 text-lg font-normal",
                attrs: { title: "Checking for any follow-on job" },
              }),
            ],
            1
          )
        : _vm.upcomingBooking
        ? _c("FollowOnJob", {
            attrs: { "upcoming-booking": _vm.upcomingBooking },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }