<template>
    <div class="rounded border border-gray-200 flex flex-col items-center pb-2 pt-0 relative gap-2">
        <div class="w-full p-2">
            <span class="text-xl text-center bg-gray-100 rounded p-4 inline-block w-full font-semibold dark:text-gray-600">{{ nextJobTime }}</span>
        </div>
        <div class="flex justify-between w-full border-b border-gray-200 p-2">
            <div class="flex flex-col items-start" v-if="remainingTime > 0">
                <span class="text-lg font-semibold">{{ upcomingBooking.BookedDateTime | formatDateTime('HH:mm') }}</span>
                <span class="text-gray-400 font-semibold">{{ day }}</span>
            </div>
            <div class="flex flex-col items-start" v-else>
                <span class="text-lg font-semibold">ASAP</span>
            </div>
            <div class="flex flex-col items-end">
                <span class="text-lg font-semibold uppercase">{{ upcomingBooking.VehicleType }}</span>
            </div>
        </div>
        <div class="flex justify-center w-full p-2">
            <div class="flex flex-col items-start flex-1">
                <span class="text-lg font-semibold">{{ pickup.Address1 }}</span>
                <span class="text-gray-400 font-semibold">{{ pickup.Postcode }}</span>
            </div>
            <div class="flex flex-col items-center justify-center relative">
                <span class="absolute mb-8" v-if="extraStops">{{ `+${extraStops}` }}</span>
                <span class="material-icons-outlined text-4xl">trending_flat</span>
            </div>
            <div class="flex flex-col items-end flex-1">
                <span class="text-lg font-semibold text-right">{{ drop.Address1 }}</span>
                <span class="text-gray-400 font-semibold">{{ drop.Postcode }}</span>
            </div>
        </div>
        <div class="px-2 pt-2 w-full flex justify-center">
            <SwipeDragButton
                handler-icon="keyboard_double_arrow_right"
                background="bg-green-500"
                progress-bar-bg="bg-green-500"
                completed-bg="bg-green-500"
                handler-bg="bg-green-600"
                :width="buttonWidth"
                :height="60"
                text-size="24px"
                radius="32px"
                text="Start Ride"
                @passcallback="startUpcomingRide"
                ref="SwipeDragButton">
            </SwipeDragButton>
        </div>
    </div>
</template>

<script>
import {bus} from '@/main';
import {navigateBack} from '@/services/PostMessageService.js';
import {setBookingOnRoute} from '@/services/StatusService.js';
import {showErrorActionDialog} from '@/services/DialogService.js';
import dayjs from 'dayjs';
import SwipeDragButton from '@/components/SwipeDragButton';

export default {
    name: 'FollowOnJob',
    props: {
        upcomingBooking: {
            type: Object,
        },
    },
    components: {
        SwipeDragButton,
    },
    data() {
        return {
            buttonWidth: 300,
            remainingTime: 0,
        };
    },
    methods: {
        calculateRemainingTime() {
            const currentTime = new Date();
            const bookingDateTime = new Date(this.upcomingBooking.BookedDateTimeBST);

            const difference = bookingDateTime - currentTime;
            const timeInMinutes = difference / (1000 * 60);

            this.remainingTime = Math.ceil(timeInMinutes);

            this.remainingTimeTimeout = null;
            if (this.remainingTime > 0) {
                this.remainingTimeTimeout = setTimeout(() => {
                    this.calculateRemainingTime();
                }, 60 * 1000);
            }
        },
        async startUpcomingRide() {
            try {
                bus.$emit('loading', true);
                await setBookingOnRoute(this.upcomingBooking.Id);
                localStorage.setItem('bookingId', this.upcomingBooking.Id);
                bus.$emit('loading', false);
                if (this.$refs.SwipeDragButton) this.$refs.SwipeDragButton.reset();
                window.location.reload();
            } catch (err) {
                bus.$emit('loading', false);
                if (this.$refs.SwipeDragButton) this.$refs.SwipeDragButton.reset();
                bus.$emit('disallowExit', false);
                showErrorActionDialog(err, 'Something went wrong whilst starting ride.', 'Exit', navigateBack);
            }
        },
    },
    computed: {
        extraStops() {
            return this.upcomingBooking.BookingStops.length - (this.upcomingBooking.AsDirected ? 1 : 2);
        },
        pickup() {
            return this.upcomingBooking.BookingStops[0];
        },
        drop() {
            if (this.upcomingBooking.AsDirected) return {Address1: 'As Directed'};
            return this.upcomingBooking.BookingStops[this.upcomingBooking.BookingStops.length - 1];
        },
        day() {
            return dayjs(this.upcomingBooking.BookedDateTimeBST).calendar(null, {
                sameDay: '[TODAY]',
                nextDay: '[TOMORROW]',
                nextWeek: 'DD/MM/YYYY',
                lastDay: '[YESTERDAY]',
                lastWeek: 'DD/MM/YYYY',
                sameElse: 'DD/MM/YYYY',
            });
        },
        nextJobTime() {
            if (this.remainingTime > 1) {
                return `Your next job is in ${this.remainingTime} minutes`;
            } else if (this.remainingTime <= 0) {
                return 'Your next job is waiting';
            } else {
                return 'Your next job is in a minute';
            }
        },
    },
    created() {
        this.calculateRemainingTime();
    },
    mounted() {
        this.buttonWidth = window.innerWidth - 64;
        window.addEventListener('resize', this.handleWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        if (this.remainingTimeTimeout) clearTimeout(this.remainingTimeTimeout);
    },
};
</script>

<style scoped></style>
