<template>
    <div class="flex flex-col mt-2">
        <div class="flex justify-between items-center mt-0 mb-3">
            <div class="flex justify-center gap-2">
                <ActionButton
                    :icon="'navigation'"
                    :action="navigate"
                    :color="'#9e9e9e'"
                    :icon-color="'#fff'"
                    text="Navigate"
                    :disabled="!(nextStop && nextStop.Latitude && nextStop.Longitude)"></ActionButton>
                <ActionButton :icon="'list'" :action="openMenu" :color="'#9e9e9e'" :icon-color="'#fff'" text="Menu"></ActionButton>
                <div class="relative" v-if="booking.BookingSource === 'APP' && driverState.ShowPassengerChat">
                    <div v-if="chatUnreadCount" class="bg-red-500 text-white text-xs absolute -right-1.5 rounded-full w-5 h-5 flex items-center justify-center -top-1">
                        <span>{{ chatUnreadCount }}</span>
                    </div>
                    <ActionButton :icon="'chat_bubble_outline'" :action="openChat" :color="'#9e9e9e'" :icon-color="'#fff'" text="Chat"></ActionButton>
                </div>
            </div>
            <div class="mr-2">
                <ActionButton
                    :async="true"
                    :icon="'report'"
                    :action="() => (startEmergency = true)"
                    :color="'#e90000'"
                    :icon-color="'#fff'"
                    text="Emergency"></ActionButton>
            </div>
        </div>
        <div class="col-span-3">
            <div class="box">
                <div class="flex justify-between">
                    <div class="flex-1">
                        <InfoLabel label="Name" :value="passengerName" class="mb-3"></InfoLabel>
                    </div>
                    <div class="w-12">
                        <ActionButton :icon="'assignment'" :action="openDetails" :color="'#9e9e9e'" :icon-color="'#fff'" text="Details"></ActionButton>
                    </div>
                </div>

                <InfoLabel
                    :value="booking.DriverNotes | truncate(32)"
                    label="Driver Notes"
                    v-if="booking.BookingStatus === 'OnRoute' && booking.DriverNotes && booking.DriverNotes.length > 0"
                    class="mb-4"></InfoLabel>
                <div v-if="booking.FlightInfo && booking.FlightInfo.FlightNumber" class="mb-2 text-gray-600">
                    ✈️ Flight No: <span class="">{{ booking.FlightInfo.FlightNumber }}</span>
                </div>
                <div v-if="booking.BookingStatus === 'OnRoute'" class="grid gap-4">
                    <div v-for="(stop, index) in booking.BookingStops" :key="index">
                        <InfoLabel :value="stop.StopSummary" label="Pickup" v-if="index === 0">
                            <template slot="labelInfo">
                                <div
                                    v-if="pickupTime"
                                    class="font-semibold rounded flex items-center gap-1 text-base rounded-full px-3 py-1"
                                    :class="runningLate ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'">
                                    <span class="material-icons-outlined">schedule</span>
                                    <span>{{ pickupTime }}</span>
                                </div>
                            </template>
                        </InfoLabel>
                        <AddDropButton v-else-if="stop.StopSummary === 'As Directed'" :booking="booking" />
                        <InfoLabel :value="stop.StopSummary" label="DropOff" v-else-if="index === booking.BookingStops.length - 1"></InfoLabel>
                        <InfoLabel :value="stop.StopSummary" label="Via" v-else></InfoLabel>
                    </div>
                </div>
                <template v-else-if="(nextStop && nextStop.StopSummary) === 'As Directed'">
                    <AddDropButton :booking="booking" />
                </template>
                <div v-else>
                    <InfoLabel :label="`${stopType} (${booking.BookingStops.length} Stops)`" :value="nextStop && nextStop.StopSummary"></InfoLabel>
                </div>
                <WaitAndReturn v-if="booking.WaitAndReturn" />
            </div>
        </div>
        <Emergency :show="startEmergency" @cancel="startEmergency = false" />
        <Navigate v-if="showNavigationDialog" @cancel="showNavigationDialog = false" :next-stop="nextStop" />
    </div>
</template>

<script>
import {bus} from '@/main';
import {openLink} from '@/services/PostMessageService';
import ActionButton from '@/components/ActionButton';
import AddDropButton from '@/components/journey-stops/AddDropButton';
import dayjs from 'dayjs';
import Emergency from '@/components/Emergency';
import InfoLabel from '@/components/InfoLabel';
import Navigate from '@/components/Navigate';
import WaitAndReturn from '@/components/WaitAndReturn';

export default {
    name: 'JourneyInfo',
    props: {
        booking: {
            type: Object,
            required: true,
        },
        chatUnreadCount: {
            type: Number,
        },
    },
    components: {
        AddDropButton,
        InfoLabel,
        ActionButton,
        Emergency,
        Navigate,
        WaitAndReturn,
    },
    data() {
        return {
            startEmergency: false,
            showNavigationDialog: false,
        };
    },
    methods: {
        async navigate() {
            this.showNavigationDialog = true;
        },
        openMenu() {
            bus.$emit('openMenu');
        },
        openChat() {
            bus.$emit('openChat');
        },
        openDetails() {
            bus.$emit('openDetails');
        },
        openAddDrop() {
            bus.$emit('openAddDropScreen');
        },
        reloadPage() {
            window.location.reload();
        },
        openFlightTracking(flightNo) {
            openLink(`https://www.google.com/search?q=${flightNo}`);
        },
    },
    computed: {
        nextStop() {
            if (this.booking.NextStop && this.booking.BookingStatus === 'InProgress') {
                // in progress
                return this.booking.BookingStops[this.booking.NextStop - 1];
            }

            // allocated
            return this.booking.BookingStops[0];
        },
        stopType() {
            const {BookingStatus, BookingStops, NextStop} = this.booking;
            if (NextStop && BookingStatus === 'InProgress') {
                // in progress
                if (NextStop === 1) return 'Pickup';
                else if (NextStop === BookingStops.length) return 'DropOff';
                else return 'Next Stop';
            }

            // allocated
            return 'Pickup';
        },
        passengerName() {
            if (this.nextStop && this.nextStop.Passenger && this.nextStop.Passenger.Firstname) {
                return `${this.nextStop.Passenger.Firstname} ${this.nextStop.Passenger.Surname || '-'}`;
            }
            return `${this.booking.Passenger.Firstname} ${this.booking.Passenger.Surname}`;
        },
        pickupTime() {
            const currentTime = new Date();
            const bookedDateTime = new Date(this.booking.BookedDateTimeBST);

            if (currentTime > bookedDateTime && !this.driverState.ShowBookingTimeOnLateBooking) {
                return 'ASAP';
            } else {
                return dayjs(this.booking.BookedDateTimeBST).format('HH:mm');
            }
        },
        runningLate() {
            const currentTime = new Date();
            const bookedDateTime = new Date(this.booking.BookedDateTimeBST);

            return currentTime > bookedDateTime;
        },
    },
};
</script>

<style scoped></style>
