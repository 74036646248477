import {addCurrency} from './helper';
import dayjs from 'dayjs';
import Vue from 'vue';

Vue.filter('currency', addCurrency);

Vue.filter('timeDuration', (value, showHours = false) => {
    // strip off decimal
    value = Number((value || 0).toFixed(0));

    let seconds = value % 60;

    let remainingMinutes = (value - seconds) / 60;
    let minutes = remainingMinutes % 60;

    let hours = (remainingMinutes - minutes) / 60;

    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (hours < 10) {
        hours = '0' + hours;
    }
    if (remainingMinutes < 10) {
        remainingMinutes = '0' + remainingMinutes;
    }
    return showHours ? `${hours}:${minutes}:${seconds}` : `${remainingMinutes}:${seconds}`;
});

Vue.filter('formatDateTime', (value, format) => {
    return dayjs(value).utc().format(format);
});

Vue.filter('mph', value => {
    let retval = 0;
    if (value) {
        retval = value * 2.23694;
    }
    return retval.toFixed(0);
});

Vue.filter('truncate', function (value, size) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
        return value;
    }
    return value.substr(0, size) + '...';
});
