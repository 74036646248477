var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex justify-end items-start py-1 text-md" },
    [
      _vm.label && _vm.label.length > 0
        ? _c(
            "label",
            {
              staticClass:
                "detail-label flex-1 font-bold w-auto pr-4 text-gray-500",
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _c(
        "span",
        { staticClass: "detail-value font-semibold pl-4 text-right" },
        [_vm._v(_vm._s(_vm.value ? _vm.value : "-"))]
      ),
      _vm.additionalInfo
        ? _c(
            "span",
            { staticClass: "additional-info w-20 font-semibold text-right" },
            [_vm._v(_vm._s(_vm.additionalInfo))]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }