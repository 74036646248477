<template>
    <h3 class="px-4 pt-4 flex items-center">
        <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#3d3d3d" stroke-width="4"></circle>
            <path class="opacity-75" fill="#3d3d3d" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        {{ title }}
    </h3>
</template>

<script>
export default {
    name: 'LoadingTitle',
    props: {
        title: {
            type: String,
            default: 'Loading',
        },
    },
};
</script>

<style scoped></style>
