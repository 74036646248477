var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-3" },
    [
      _vm.booking.BookingStatus === "InProgress" &&
      _vm.booking.CompletingDateTime
        ? _c("RideSettlement", {
            attrs: {
              booking: _vm.booking,
              "app-data": _vm.appData,
              "use-local-meter": _vm.useLocalMeter,
              "local-meter": _vm.localMeter,
            },
          })
        : _c("RideSubmit", {
            attrs: {
              booking: _vm.booking,
              "payment-link-sent": _vm.paymentLinkSent,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }