<template>
    <div class="box mt-2 grid grid-cols-2 gap-2">
        <div class="flex items-center" v-if="!driverState.HideWaitingCost">
            <ActionButton
                :async="true"
                :icon="!allowWaiting ? 'play_disabled' : isWaiting ? 'stop_circle' : 'play_circle'"
                :action="isWaiting ? stopWaiting : startWaiting"
                :color="isWaiting ? '#e90000' : '#12c343'"
                :icon-color="'#fff'"
                :disabled="!allowWaiting"
                class="mr-2"></ActionButton>
            <InfoLabel v-if="!localPriceLoader" :label="'Waiting'" :value="waitingTime | timeDuration(false)"></InfoLabel>
            <div v-else class="w-8 py-2">
                <LoaderIcon class="h-8 w-8" color="#9e9e9e" spin-duration="1s" />
            </div>
        </div>
        <div class="flex items-center">
            <ActionButton
                :icon="'add'"
                :action="addExpense"
                :color="'#ff8907'"
                :icon-color="'#fff'"
                class="mr-2"></ActionButton>
            <InfoLabel :label="'Extras'" :value="(booking._extrasTotal || 0) | currency"></InfoLabel>
            <AddExpense v-if="showExpenseDialog" :booking="booking" @cancel="hideExpenseModal" />
        </div>
    </div>
</template>

<script>
import {bus} from '@/main';
import {showErrorToast} from '../services/ToastService';
import {startWaiting, stopWaiting} from '@/services/WaitService';
import ActionButton from '@/components/ActionButton';
import AddExpense from '@/components/AddExpense';
import InfoLabel from '@/components/InfoLabel';
import LoaderIcon from '@/components/loaders/LoaderIcon.vue';

export default {
    name: 'JourneyBreakdown',
    props: {
        booking: {
            type: Object,
            required: true,
        },
        isWaiting: {
            type: Boolean,
            required: true,
        },
        waitPointId: {
            type: String,
        },
        useLocalMeter: {
            type: Boolean,
        },
        localMeter: {
            type: Object,
        },
    },
    components: {
        InfoLabel,
        ActionButton,
        AddExpense,
        LoaderIcon,
    },
    data() {
        return {
            showExpenseDialog: false,
            localPriceLoader: false,
        };
    },
    methods: {
        setPriceLoader(val) {
            this.localPriceLoader = val;
        },
        addExpense() {
            this.showExpenseDialog = true;
        },
        hideExpenseModal() {
            this.showExpenseDialog = false;
        },
        async startWaiting() {
            try {
                const waitPoint = await startWaiting(this.booking.Id);
                bus.$emit('startManualTimer', waitPoint);
            } catch (err) {
                showErrorToast(err, 'Something went wrong whilst starting wait time.');
            }
        },
        async stopWaiting() {
            try {
                await stopWaiting(this.booking.Id, this.waitPointId);
                bus.$emit('stopManualTimer');
            } catch (err) {
                showErrorToast(err, 'Something went wrong whilst stopping wait time.');
            }
        },
    },
    computed: {
        waitingTime() {
            if (this.booking.LocalWaitingTime) {
                return this.booking.LocalWaitingTime;
            } else if (this.useLocalMeter) {
                return this.localMeter.WaitingTime;
            }

            return this.booking.WaitingTimeInSeconds;
        },
        allowWaiting() {
            if (this.booking.MeterPaused) return false;
            if (!this.driverState.CanDriverStartWaiting) return false;
            if (this.booking.BookingStatus === 'Allocated' || this.booking.BookingStatus === 'OnRoute' || this.booking.BookingStatus === 'Arrived') return false;
            if (this.booking.AutomaticStationaryTime) return false;
            return true;
        },
    },
    created() {
        bus.$on('setPriceLoader', this.setPriceLoader);
    },
    destroyed() {
        bus.$off('setPriceLoader', this.setPriceLoader);
    },
};
</script>

<style scoped></style>
