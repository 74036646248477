var render = function (_h, _vm) {
  var _c = _vm._c
  return _c("div", { class: _vm.data.staticClass }, [
    _c(
      "svg",
      {
        staticStyle: {
          margin: "auto",
          background: "rgba(241, 242, 243, 0)",
          display: "block",
        },
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          width: _vm.props.size,
          height: _vm.props.size,
          viewBox: "0 0 100 100",
          preserveAspectRatio: "xMidYMid",
        },
      },
      [
        _c(
          "circle",
          { attrs: { cx: "84", cy: "50", r: "10", fill: "#a80303" } },
          [
            _c("animate", {
              attrs: {
                attributeName: "r",
                repeatCount: "indefinite",
                dur: "0.5434782608695652s",
                calcMode: "spline",
                keyTimes: "0;1",
                values: "10;0",
                keySplines: "0 0.5 0.5 1",
                begin: "0s",
              },
            }),
            _c("animate", {
              attrs: {
                attributeName: "fill",
                repeatCount: "indefinite",
                dur: "2.1739130434782608s",
                calcMode: "discrete",
                keyTimes: "0;0.25;0.5;0.75;1",
                values: "#a80303;#e9e9e9;#dddddd;#e90000;#a80303",
                begin: "0s",
              },
            }),
          ]
        ),
        _c(
          "circle",
          { attrs: { cx: "16", cy: "50", r: "10", fill: "#a80303" } },
          [
            _c("animate", {
              attrs: {
                attributeName: "r",
                repeatCount: "indefinite",
                dur: "2.1739130434782608s",
                calcMode: "spline",
                keyTimes: "0;0.25;0.5;0.75;1",
                values: "0;0;10;10;10",
                keySplines: "0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1",
                begin: "0s",
              },
            }),
            _c("animate", {
              attrs: {
                attributeName: "cx",
                repeatCount: "indefinite",
                dur: "2.1739130434782608s",
                calcMode: "spline",
                keyTimes: "0;0.25;0.5;0.75;1",
                values: "16;16;16;50;84",
                keySplines: "0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1",
                begin: "0s",
              },
            }),
          ]
        ),
        _c(
          "circle",
          { attrs: { cx: "50", cy: "50", r: "10", fill: "#e90000" } },
          [
            _c("animate", {
              attrs: {
                attributeName: "r",
                repeatCount: "indefinite",
                dur: "2.1739130434782608s",
                calcMode: "spline",
                keyTimes: "0;0.25;0.5;0.75;1",
                values: "0;0;10;10;10",
                keySplines: "0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1",
                begin: "-0.5434782608695652s",
              },
            }),
            _c("animate", {
              attrs: {
                attributeName: "cx",
                repeatCount: "indefinite",
                dur: "2.1739130434782608s",
                calcMode: "spline",
                keyTimes: "0;0.25;0.5;0.75;1",
                values: "16;16;16;50;84",
                keySplines: "0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1",
                begin: "-0.5434782608695652s",
              },
            }),
          ]
        ),
        _c(
          "circle",
          { attrs: { cx: "84", cy: "50", r: "10", fill: "#dddddd" } },
          [
            _c("animate", {
              attrs: {
                attributeName: "r",
                repeatCount: "indefinite",
                dur: "2.1739130434782608s",
                calcMode: "spline",
                keyTimes: "0;0.25;0.5;0.75;1",
                values: "0;0;10;10;10",
                keySplines: "0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1",
                begin: "-1.0869565217391304s",
              },
            }),
            _c("animate", {
              attrs: {
                attributeName: "cx",
                repeatCount: "indefinite",
                dur: "2.1739130434782608s",
                calcMode: "spline",
                keyTimes: "0;0.25;0.5;0.75;1",
                values: "16;16;16;50;84",
                keySplines: "0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1",
                begin: "-1.0869565217391304s",
              },
            }),
          ]
        ),
        _c(
          "circle",
          { attrs: { cx: "16", cy: "50", r: "10", fill: "#e9e9e9" } },
          [
            _c("animate", {
              attrs: {
                attributeName: "r",
                repeatCount: "indefinite",
                dur: "2.1739130434782608s",
                calcMode: "spline",
                keyTimes: "0;0.25;0.5;0.75;1",
                values: "0;0;10;10;10",
                keySplines: "0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1",
                begin: "-1.6304347826086956s",
              },
            }),
            _c("animate", {
              attrs: {
                attributeName: "cx",
                repeatCount: "indefinite",
                dur: "2.1739130434782608s",
                calcMode: "spline",
                keyTimes: "0;0.25;0.5;0.75;1",
                values: "16;16;16;50;84",
                keySplines: "0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1",
                begin: "-1.6304347826086956s",
              },
            }),
          ]
        ),
      ]
    ),
    _vm.props.text
      ? _c("p", { staticClass: "text-center" }, [
          _vm._v(_vm._s(_vm.props.text)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }