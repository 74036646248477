import {post} from '@/services/ApiService';

export const addDriverEmergency = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post('driver-api/emergency');
            resolve(result);
        } catch (err) {
            reject(err);
        }
    });
};
