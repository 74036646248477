<template>
    <Transition name="toast" :duration="400">
        <div v-if="message" :class="type" class="px-5 py-3 flex justify-between">
            <span>
                {{ message }}
            </span>
        </div>
    </Transition>
</template>

<script>
export default {
    name: 'Toast',
    props: {
        type: {
            type: String,
            default: 'success',
        },
        message: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
.success {
    @apply bg-green-600;
    color: white;
}

.warning {
    @apply bg-yellow-400;
    color: white;
}

.error {
    @apply bg-red-600;
    color: white;
}

.toast-enter-active,
.toast-leave-active {
    transition: margin-top 0.4s;
}

.toast-enter,
.toast-leave-to {
    margin-top: -3rem;
}
</style>
