import {bus} from '@/main';
import {mpsToMph} from '@/utils/helper';

export const stationaryAutoWaitMixin = {
    data() {
        return {
            stationaryAutoTimerState: 'stopped',
            allowTimerStarting: true,
            allowTimerStopping: true,
            startWaitTimeout: null,
            stopWaitTimeout: null,
            speedChangeTimeout: 500,
            motionTimeout: 500,
        };
    },
    methods: {
        clearAutoWaiting() {
            if (localStorage.getItem(`${this.booking.Id}-auto-waiting`)) {
                localStorage.removeItem(`${this.booking.Id}-auto-waiting`);
                this.stationaryAutoTimerState = 'stopped';
                bus.$emit('stopAutoTimer');
            }
        },
        startAutoWaiting() {
            // start timer
            bus.$emit('startAutoTimer');
            localStorage.setItem(`${this.booking.Id}-auto-waiting`, true);

            this.stationaryAutoTimerState = 'running';

            this.startWaitTimeout = null;

            this.allowTimerStopping = false;
            setTimeout(() => {
                this.allowTimerStopping = true;
            }, this.motionTimeout);
        },
        stopAutoWaiting() {
            // stop timer
            bus.$emit('stopAutoTimer');
            localStorage.removeItem(`${this.booking.Id}-auto-waiting`);

            this.stationaryAutoTimerState = 'stopped';

            this.stopWaitTimeout = null;

            this.allowTimerStarting = false;
            setTimeout(() => {
                this.allowTimerStarting = true;
            }, this.motionTimeout);
        },
        handleSpeedUpdate(speed) {
            if (this.booking.MeterPaused) return;
            if (!this.booking.AutomaticStationaryTime) return;
            if (this.booking.BookingStatus !== 'InProgress') return;

            if (mpsToMph(speed) <= this.booking.StationarySpeedThreshold) {
                if (this.stopWaitTimeout) {
                    clearTimeout(this.stopWaitTimeout);
                    this.stopWaitTimeout = null;
                }
                if (this.allowTimerStarting && !this.startWaitTimeout && this.stationaryAutoTimerState !== 'running') {
                    this.startWaitTimeout = setTimeout(() => {
                        this.startAutoWaiting();
                    }, this.speedChangeTimeout);
                }
            } else {
                if (this.startWaitTimeout) {
                    clearTimeout(this.startWaitTimeout);
                    this.startWaitTimeout = null;
                }
                if (this.allowTimerStopping && !this.stopWaitTimeout && this.stationaryAutoTimerState !== 'stopped') {
                    this.stopWaitTimeout = setTimeout(() => {
                        this.stopAutoWaiting();
                    }, this.speedChangeTimeout);
                }
            }
        },
    },
    mounted() {
        if (this.booking.BookingStatus === 'InProgress' && this.booking.AutomaticStationaryTime && localStorage.getItem(`${this.booking.Id}-auto-waiting`)) {
            this.stationaryAutoTimerState = 'running';
        }
    },
    destroyed() {
        if (this.startWaitTimeout) clearTimeout(this.startWaitTimeout);
        if (this.stopWaitTimeout) clearTimeout(this.stopWaitTimeout);
    },
};
