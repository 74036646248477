<template>
    <div class="flex flex-col overflow-hidden">
        <div class="border border-gray-200 rounded flex items-center gap-2 bg-white">
            <span class="material-icons-outlined p-2">search</span>
            <input class="flex-1 h-10 bg-white" placeholder="Search address" v-model="searchText" ref="searchInput" />
            <span class="material-icons-outlined p-2 cursor-pointer" @click="clearSearch">close</span>
        </div>
        <div class="border border-gray-300 rounded mt-2 overflow-scroll" v-if="searchResults.length">
            <div v-for="(result, index) in searchResults" :key="`${index}${result.StopSummary}`" class="border-b border-gray-300 bg-gray-100 px-2 py-3" @click="() => selectAddress(result)">
                <span class="font-semibold dark:text-gray-600">{{ result.StopSummary }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {searchAddressMixin} from '@/mixins/searchAddressMixin.js';

export default {
    name: 'SearchBar',
    props: {
        booking: {
            type: Object,
        },
    },
    mixins: [searchAddressMixin],
    methods: {
        focusSearchInput() {
            try {
                this.$nextTick(() => {
                    if (this.$refs.searchInput) {
                        this.$refs.searchInput.focus();
                    }
                });
            } catch (err) {
                console.log(err);
            }
        },
    },
    mounted() {
        this.focusSearchInput();
    },
};
</script>
<style lang="scss" scoped>
input {
    outline: none;
}
</style>
