<template>
    <Alert :show="showAlert" :show-close="true" @cancel="handleClose">
        <template v-slot:title> Navigate </template>
        <template v-slot:body>
            <div class="flex justify-around p-6">
                <div class="flex flex-col items-center gap-1 rounded" @click="openGoogleMaps">
                    <img :src="googleImageUrl" class="h-16 w-16 p-3 rounded-xl shadow" />
                    <span class="text-gray-700">Maps</span>
                </div>
                <div class="flex flex-col items-center gap-1" @click="openWaze">
                    <img :src="wazeImageUrl" class="h-16 w-16 p-3 rounded-xl shadow" style="background: #42d4ff" />
                    <span class="text-gray-700">Waze</span>
                </div>
            </div>
        </template>
    </Alert>
</template>

<script>
import {openLink} from '@/services/PostMessageService';
import Alert from './Alert.vue';

export default {
    name: 'Navigate',
    props: {
        nextStop: {
            type: Object,
        },
    },
    components: {
        Alert,
    },
    data() {
        return {
            googleImageUrl: require('../assets/images/icons/google-map.svg'),
            showAlert: true,
            wazeImageUrl: require('../assets/images/icons/waze.svg'),
        };
    },
    methods: {
        handleClose() {
            this.showAlert = false;
            setTimeout(() => {
                this.$emit('cancel');
            }, 400);
        },
        openWaze() {
            openLink(`https://www.waze.com/ul?ll=${this.nextStop.Latitude},${this.nextStop.Longitude}&navigate=yes`);
            this.handleClose();
        },
        openGoogleMaps() {
            openLink(`https://www.google.com/maps/dir/?api=1&destination=${this.nextStop.Latitude},${this.nextStop.Longitude}&dir_action=navigate`);
            this.handleClose();
        },
    },
};
</script>

<style scoped></style>
