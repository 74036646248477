<template>
    <div class="h-full w-full bg-gray-300 opacity-50 flex items-center justify-center fixed loader-overlay">
        <LoaderIcon color="#3d3d3d" class="h-16" />
    </div>
</template>

<script>
import LoaderIcon from './LoaderIcon.vue';
export default {
    name: 'Loader',
    components: {
        LoaderIcon,
    },
};
</script>

<style lang="scss" scoped>
.loader-overlay {
    z-index: 999;
}
</style>
