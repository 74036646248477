<template>
    <Alert :show="showAlert" :show-close="true" @cancel="handleClose">
        <template v-slot:title>Add Extra</template>
        <template v-slot:body>
            <p class="block text-gray-500">Any extras added will need to be verified by the operations team.</p>
            <form>
                <div class="py-2 inline-block">
                    <label for="expense-amount" class="block font-semibold">Amount</label>
                    <div class="rounded-full bg-white flex items-center gap-2 p-2 my-1 border border-gray-200">
                        <action-button icon="remove" icon-color="#1FD0A3" color="#fff" :action="decreaseAmount" :disabled="amount < 0.1" />
                        <span class="relative text-gray-600 dark:text-gray-100 text-xl w-24">
                            <span aria-hidden="true" class="px-1">{{ amount }}</span>
                            <input class="absolute w-full left-0 bg-white px-1 text-center outline-none" name="expense-amount" id="expense-amount" v-model="amount" type="number" />
                        </span>
                        <action-button icon="add" icon-color="#1FD0A3" color="#fff" :action="increaseAmount" />
                    </div>
                </div>
                <div class="relative">
                    <label for="expense-types" class="block font-semibold">Select Type</label>
                    <select name="expense-types" id="expense-types" v-model="selectedType" class="form-input">
                        <option v-for="type in expenseTypes" :key="type.Id" :value="type.Id">
                            {{ type.Name }}
                        </option>
                    </select>
                    <LoaderIcon v-if="loading" class="absolute right-1 h-8 w-8 bottom-1" color="#9e9e9e" />
                </div>
                <div class="mt-2 mb-4">
                    <label for="expense-notes" class="block font-semibold">Notes</label>
                    <input name="expense-amount" id="expense-notes" type="text" placeholder="Optional" v-model="note" class="form-input" />
                </div>
                <action-button text="Add" :circular="false" color="#1FD0A3" :async="true" :action="addExpense" class="w-full mt-2" />
            </form>
        </template>
    </Alert>
</template>

<script>
import {addExpense, getExpenseTypes} from '@/services/ExpenseService';
import {bus} from '@/main';
import {showErrorDialog} from '@/services/DialogService';
import {showSuccessToast} from '../services/ToastService';
import ActionButton from '@/components/ActionButton';
import Alert from '@/components/Alert';
import LoaderIcon from '@/components/loaders/LoaderIcon';

export default {
    name: 'AddExpense',
    props: {
        booking: {
            type: Object,
        },
        show: {
            type: Boolean,
        },
    },
    components: {
        ActionButton,
        Alert,
        LoaderIcon,
    },
    data() {
        return {
            expenseTypes: [],
            loading: true,
            note: '',
            amount: 0,
            selectedType: null,
            error: null,
            showAlert: true,
        };
    },
    methods: {
        handleClose() {
            this.showAlert = false;
            setTimeout(() => {
                this.$emit('cancel');
            }, 400);
        },
        increaseAmount() {
            this.amount = (Number(this.amount) + (this.booking.ExtrasIncrement || 0.1)).toFixed(2);
        },
        decreaseAmount() {
            if (Number(this.amount) >= (this.booking.ExtrasIncrement || 0.1)) {
                this.amount = (Number(this.amount) - (this.booking.ExtrasIncrement || 0.1)).toFixed(2);
            }
        },
        async addExpense() {
            try {
                const expense = this.expenseTypes.find(type => type.Id === this.selectedType);
                if (!expense) {
                    showErrorDialog(null, 'Please select a type.', 'Try Again');
                    return;
                } else if (!this.amount || this.amount < 0.01) {
                    showErrorDialog(null, 'Please check the amount entered.', 'Try Again');
                    return;
                }

                await addExpense(this.booking.Id, {
                    expenseName: expense.Name,
                    expenseId: this.selectedType,
                    driverAmount: this.amount,
                    note: this.note,
                });
                this.handleClose();
                showSuccessToast('Any extras added will need to be verified by the operations team.');
                bus.$emit('fetchUpdatedBooking');
            } catch (err) {
                showErrorDialog(err, 'Something went wrong whilst adding the extra.', 'Try Again');
            }
        },
    },
    async created() {
        this.expenseTypes = await getExpenseTypes();
        if (this.expenseTypes && this.expenseTypes.length) {
            this.selectedType = this.expenseTypes[0].Id;
        }
        this.loading = false;
    },
};
</script>

<style scoped lang="scss">
.form-input {
    @apply w-full p-1 h-10 px-2;
    border: solid 1px $light-grey;
    -webkit-appearance: none;
    border-radius: 4px;
}

/* Remove spinners on number inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
</style>
