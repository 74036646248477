<template>
    <div>
        <span class="text-md font-medium flex gap-1 items-center mb-1" :style="{color: '#9e9e9e'}">
            <span>DropOff</span>
        </span>
        <ActionButton text="Add DropOff" :circular="false" class="w-max shadow-md" icon="add_location" color="#18a480" :action="openAddDrop" />
    </div>
</template>

<script>
import {bus} from '@/main';
import ActionButton from '@/components/ActionButton';

export default {
    name: 'AddDropButton',
    props: {
        booking: {
            type: Object,
            required: true,
        },
    },
    components: {
        ActionButton,
    },
    methods: {
        openAddDrop() {
            bus.$emit('openAddDropScreen');
        },
    },
};
</script>
