<template>
    <div class="info-label flex flex-col">
        <div class="label flex items-center gap-2">
            <span class="text-md font-medium flex gap-1 items-center" :style="{color: labelColour}">
                <span>{{ label }}</span>
                <span v-if="link">(Tap to Open)</span>
                <slot name="labelInfo"></slot>
            </span>
            <span v-if="icon" class="material-icons-outlined text-base text-white rounded w-5 h-5 text-center leading-5" style="background: #9e9e9e">{{ icon }}</span>
        </div>
        <slot name="valueInfo"></slot>
        <a :href="link" v-if="link" target="_blank">
            <span class="value font-medium" :class="size">{{ value }}</span>
        </a>
        <span class="value font-medium" :class="size" v-else>{{ value }}</span>
        <span class="text-sm sub-text" v-if="subText">{{ subText }}</span>
    </div>
</template>

<script>
export default {
    name: 'InfoLabel',
    props: {
        label: {
            type: String,
            default: 'Name',
            required: true,
        },
        value: {
            default: '-',
            required: true,
        },
        subText: {
            type: String,
        },
        icon: {
            type: String,
        },
        size: {
            type: String,
            default: 'text-2xl',
        },
        link: {
            type: String,
        },
        labelColour: {
            type: String,
            default: '#9e9e9e',
        },
    },
};
</script>

<style lang="scss" scoped>
.label {
    line-height: 1.1rem;
}

.sub-text {
    color: #9e9e9e;
}
</style>
