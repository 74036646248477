import {post} from '@/services/ApiService';

export const startWaiting = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post(`driver-api/booking/start-waiting?bookingId=${bookingId}`);
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const stopWaiting = (bookingId, waitPointId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post(`driver-api/booking/stop-waiting?bookingId=${bookingId}&id=${waitPointId}`);
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};
