var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "rounded border border-gray-200 flex justify-center items-center gap-3 p-4 relative",
    },
    [
      _c("div", { staticClass: "absolute left-0 ml-2" }, [
        _vm.booking.Passenger.ImageUrl
          ? _c("img", {
              staticClass: "w-16 h-16 rounded-full",
              attrs: { src: _vm.booking.Passenger.ImageUrl },
            })
          : _c(
              "span",
              {
                staticClass:
                  "material-icons-outlined text-5xl text-center text-white rounded-full p-1 w-16 h-16",
                staticStyle: { background: "#9e9e9e" },
              },
              [_vm._v("person")]
            ),
      ]),
      _c("div", { staticClass: "flex flex-col items-center justify-center" }, [
        _c("span", { staticClass: "font-semibold" }, [
          _vm._v(_vm._s(_vm.passengerName)),
        ]),
        _c(
          "div",
          _vm._l(_vm.starsHighlight, function (highlight, index) {
            return _c(
              "span",
              {
                key: index,
                staticClass: "material-icons-outlined text-4xl",
                class: {
                  "text-yellow-400": highlight,
                  "text-gray-300": !highlight,
                },
                on: {
                  click: function () {
                    return _vm.updateRating(index)
                  },
                },
              },
              [_vm._v("\n                star\n            ")]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }