<template>
    <div>
        <div class="flex gap-1 px-2 pb-1 pt-2 overflow-scroll no-scrollbar w-full">
            <PreWrittenMessage v-for="(msg, index) in preWrittenMessages" :text="msg" :key="index" @click.native="() => setPreWrittenMessage(msg)" />
        </div>
        <div class="flex bg-white shadow-lg px-3 py-2 items-center gap-2 border-t border-gray-100 rounded-t-2xl">
            <div>
                <div class="emojis-wrapper">
                    <div class="emojis-container">
                        <emoji-picker @emoji="append" :search="search">
                            <button class="icon-button flex items-center" slot="emoji-invoker" slot-scope="{events: {click: clickEvent}}" @click.stop="clickEvent">
                                <span class="material-icons-outlined">sentiment_satisfied_alt</span>
                            </button>
                            <div slot="emoji-picker" slot-scope="{emojis, insert}">
                                <div style="position: relative">
                                    <div class="emoji-picker">
                                        <div class="form-group mb-2">
                                            <input aria-label="emoji-picker" class="form-control border rounded-md px-1.5 py-1 w-full" type="text" v-model="search" placeholder="Search" />
                                        </div>
                                        <div>
                                            <div v-for="(emojiGroup, category) in emojis" :key="category">
                                                <h5>{{ category }}</h5>
                                                <div class="emojis">
                                                    <span class="emoji" v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)" :title="emojiName">{{ emoji }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </emoji-picker>
                    </div>
                </div>
            </div>
            <div class="flex-1">
                <textarea class="w-full border-b resize-none outline-none py-2 bg-transparent" v-model="content" rows="1" placeholder="Type message" />
            </div>
            <ActionButton color="#10B981" class="send" icon="send" :action="sendMessage" :small="true" icon-color="white" :async="true" />
        </div>
    </div>
</template>

<script>
import ActionButton from '@/components/ActionButton';
import EmojiPicker from 'vue-emoji-picker';
import PreWrittenMessage from './PreWrittenMessage';

export default {
    name: 'MessageInput',
    props: {
        send: {
            type: Function,
            required: true,
        },
    },
    components: {
        ActionButton,
        EmojiPicker,
        PreWrittenMessage,
    },
    data() {
        return {
            search: '',
            content: '',
            preWrittenMessages: ['Hi', 'Arrived', 'On my way', 'Call', 'Waiting'],
        };
    },
    methods: {
        async sendMessage() {
            await this.send(this.content);
            this.clearContent();
        },
        async setPreWrittenMessage(msg) {
            this.content = msg;
        },
        clearContent() {
            this.content = '';
        },
        append(emoji) {
            this.content += emoji;
        },
    },
};
</script>

<style lang="scss">
.emojis-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .emojis-container {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0.5rem 0;
        position: relative;
        .emoji-picker {
            position: absolute;
            z-index: 1;
            left: 0rem;
            bottom: 3rem;
            top: auto;
            font-family: inherit;
            border: 1px solid $line-color;
            width: 15rem;
            height: 20rem;
            overflow: scroll;
            padding: 1rem;
            box-sizing: border-box;
            border-radius: 0.5rem;
            background: #fff;
            box-shadow: 1px 1px 0.5rem $box-shadow;
            h5 {
                margin-bottom: 0;
                color: #b1b1b1;
                text-transform: uppercase;
                font-size: 0.8rem;
                cursor: default;
            }
            .emojis {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                &:after {
                    content: '';
                    flex: auto;
                }
                span {
                    padding: 0.2rem;
                    cursor: pointer;
                    border-radius: 0.5rem;
                    font-size: 20px;
                    &:hover {
                        background: #ececec;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>
