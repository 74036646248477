var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "dragVerify",
      staticClass: "drag_verify",
      class: _vm.background,
      style: _vm.dragVerifyStyle,
      on: {
        mousemove: _vm.dragMoving,
        mouseup: _vm.dragFinish,
        mouseleave: _vm.dragFinish,
        touchmove: _vm.dragMoving,
        touchend: _vm.dragFinish,
      },
    },
    [
      _c("div", {
        ref: "progressBar",
        staticClass: "dv_progress_bar",
        class: { goFirst2: _vm.isOk },
        style: _vm.progressBarStyle,
      }),
      _c(
        "div",
        { ref: "message", staticClass: "dv_text", style: _vm.textStyle },
        [
          _vm.$slots.textBefore ? _vm._t("textBefore") : _vm._e(),
          _vm._v("\n        " + _vm._s(_vm.message) + "\n        "),
          _vm.$slots.textAfter ? _vm._t("textAfter") : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          ref: "handler",
          staticClass: "dv_handler dv_handler_bg",
          class:
            ((_obj = { goFirst: _vm.isOk }),
            (_obj[_vm.handlerBg] = _vm.handlerBg),
            _obj),
          style: _vm.handlerStyle,
          on: { mousedown: _vm.dragStart, touchstart: _vm.dragStart },
        },
        [
          _c(
            "i",
            {
              staticClass:
                "material-icons-outlined text-5xl align-middle text-white mb-1 ml-1",
            },
            [_vm._v(_vm._s(_vm.handlerIcon))]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }