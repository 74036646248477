<template>
    <div class="box">
        <div class="relative">
            <div class="flex flex-col">
                <InfoLabel label="Payment" :value="displayedPaymentMethod" size="text-3xl" class="mb-2" v-if="displayedPaymentMethod"></InfoLabel>
                <template v-else>
                    <div v-if="updatingPrice || localPriceLoader">
                        <label class="font-medium" :style="{color: '#9e9e9e'}">{{ journeyTotalLabel }}</label>
                        <div class="w-16 py-2">
                            <LoaderIcon class="h-16 w-16" color="#10b981" spin-duration="1s" />
                        </div>
                    </div>
                    <InfoLabel v-else :label="journeyTotalLabel" :value="journeyTotal | currency" size="text-7xl"></InfoLabel>
                </template>
                <span class="text-red-500 font-semibold" v-if="partialPaymentsActive">Partial Payments Active</span>
                <span class="text-green-500 font-semibold mt-0.5" v-if="voucherApplied">Voucher Applied</span>
                <span class="text-green-500 font-semibold mt-0.5 text-xl" v-if="!displayedPaymentMethod && booking.PaymentMethod === 'Card'">Prepaid Card</span>
                <span
                    v-if="booking.BookingStatus === 'InProgress' && booking.PricingMode === 'ActualRoute'"
                    class="font-semibold mb-0"
                    :class="booking.MeterPaused ? 'text-red-500 pulse' : 'text-yellow-500'">{{ booking.MeterPaused ? 'Meter Paused' : 'Meter Running' }}</span>
                <div class="flex justify-between items-center">
                    <div class="flex flex-col">
                        <span v-if="useLocalMeter">Actual Distance: {{ (localMeter && localMeter.ActualDistance) || 0 }} mi</span>
                        <template v-else-if="booking.AsDirected || booking.PricingMode === 'ActualRoute'">
                            <span v-if="booking.BookingStatus === 'OnRoute'" class="text-sm"> Estimated Distance: {{ booking.EstimatedDistance }} mi </span>
                            <span v-else class="text-sm"> Actual Distance: {{ booking.ActualDistance || 0 }} mi </span>
                        </template>
                        <template v-if="booking.PaymentMethod !== 'OnAccount' && !driverState.HideWaitingCost">
                            <strong class="text-lg" v-if="useLocalMeter">Includes Waiting: {{ (localMeter.WaitingCost || 0) | currency }} </strong>
                            <strong class="text-lg" v-else>Includes Waiting: {{ (booking.WaitingTotal + (booking.WaitingTax || 0)) | currency }} </strong>
                        </template>
                    </div>
                </div>
            </div>
            <Speed />
            <div class="pause-journey absolute bottom-0 right-0" v-if="booking.BookingStatus === 'InProgress' && booking.PricingMode === 'ActualRoute'">
                <ActionButton
                    :async="true"
                    :icon="booking.MeterPaused ? 'play_circle' : 'pause'"
                    :action="booking.MeterPaused ? resumeMeter : pauseMeter"
                    :color="booking.MeterPaused ? '#12c343' : '#9e9e9e'"
                    :icon-color="'#fff'"></ActionButton>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from '@/main';
import {getBookingDetails} from '@/services/BookingService';
import ActionButton from '@/components/ActionButton';
import InfoLabel from '@/components/InfoLabel';
import LoaderIcon from '@/components/loaders/LoaderIcon.vue';
import Speed from '@/components/Speed';

export default {
    name: 'JourneyTotal',
    props: {
        booking: {
            type: Object,
            required: true,
        },
        journeySpeed: {
            type: Number,
        },
        pauseMeter: {
            type: Function,
        },
        resumeMeter: {
            type: Function,
        },
        useLocalMeter: {
            type: Boolean,
        },
        localMeter: {
            type: Object,
        },
    },
    components: {
        ActionButton,
        InfoLabel,
        Speed,
        LoaderIcon,
    },
    data() {
        return {
            partialPaymentsActive: false,
            updatingPrice: false,
            localPriceLoader: false,
        };
    },
    methods: {
        async refetchPrice() {
            try {
                this.updatingPrice = true;
                const updatedBooking = await getBookingDetails(this.booking.Id);
                updatedBooking.LocalWaitingTime = this.booking.LocalWaitingTime;

                bus.$emit('bookingUpdate', updatedBooking);

                this.updatingPrice = false;
            } catch (err) {
                console.error(err);
                this.updatingPrice = false;
            }
        },
        setPriceLoader(val) {
            this.localPriceLoader = val;
        },
    },
    computed: {
        journeyTotal() {
            return this.booking._grandTotal;
        },
        voucherApplied() {
            if (this.booking.Adjustments && this.booking.Adjustments.length) {
                const found = this.booking.Adjustments.find(ad => ad.Name && (ad.Name.includes('Coupon Code Discount') || ad.Name.includes('Loyalty Points Discount')) && ad.Amount);
                if (found) {
                    return true;
                }
            }
            return false;
        },
        journeyTotalLabel() {
            if (this.booking.BookingStatus === 'Arrived' || this.booking.BookingStatus === 'InProgress' || this.booking.BookingStatus === 'Clearing') {
                return 'Journey Total';
            } else {
                return 'Journey Estimate';
            }
        },
        displayedPaymentMethod() {
            if (this.booking.PaymentMethod === 'OnAccount') return 'OnAccount';
            if (this.booking._grandTotal < 0) return 'Voucher/PrePaid';

            if (this.booking.PaymentMethod !== 'Card' || this.driverState.ShowCardBookingsAmount) {
                return null;
            } else {
                return this.booking.PaymentMethod === 'Card' ? 'Prepaid/Card' : this.booking.PaymentMethod;
            }
            ///TEMP FIX FOR ACCOUNT COMPANIES
        },
    },
    created() {
        bus.$on('refetchPrice', this.refetchPrice);
        bus.$on('setPriceLoader', this.setPriceLoader);
        if (this.booking.Adjustments && this.booking.Adjustments.length) {
            const found = this.booking.Adjustments.find(ad => ad.Name === 'Account Partial Payment');
            if (found) {
                this.partialPaymentsActive = true;
            }
        }
    },
    destroyed() {
        bus.$off('refetchPrice', this.refetchPrice);
        bus.$off('setPriceLoader', this.setPriceLoader);
    },
};
</script>

<style scoped></style>
