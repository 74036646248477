var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Alert", {
    attrs: { show: _vm.showAlert, "show-close": true },
    on: { cancel: _vm.handleClose },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" Navigate ")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "flex justify-around p-6" }, [
              _c(
                "div",
                {
                  staticClass: "flex flex-col items-center gap-1 rounded",
                  on: { click: _vm.openGoogleMaps },
                },
                [
                  _c("img", {
                    staticClass: "h-16 w-16 p-3 rounded-xl shadow",
                    attrs: { src: _vm.googleImageUrl },
                  }),
                  _c("span", { staticClass: "text-gray-700" }, [
                    _vm._v("Maps"),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex flex-col items-center gap-1",
                  on: { click: _vm.openWaze },
                },
                [
                  _c("img", {
                    staticClass: "h-16 w-16 p-3 rounded-xl shadow",
                    staticStyle: { background: "#42d4ff" },
                    attrs: { src: _vm.wazeImageUrl },
                  }),
                  _c("span", { staticClass: "text-gray-700" }, [
                    _vm._v("Waze"),
                  ]),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }