var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col overflow-hidden" }, [
    _c(
      "div",
      {
        staticClass:
          "border border-gray-200 rounded flex items-center gap-2 bg-white",
      },
      [
        _c("span", { staticClass: "material-icons-outlined p-2" }, [
          _vm._v("search"),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchText,
              expression: "searchText",
            },
          ],
          ref: "searchInput",
          staticClass: "flex-1 h-10 bg-white",
          attrs: { placeholder: "Search address" },
          domProps: { value: _vm.searchText },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchText = $event.target.value
            },
          },
        }),
        _c(
          "span",
          {
            staticClass: "material-icons-outlined p-2 cursor-pointer",
            on: { click: _vm.clearSearch },
          },
          [_vm._v("close")]
        ),
      ]
    ),
    _vm.searchResults.length
      ? _c(
          "div",
          {
            staticClass: "border border-gray-300 rounded mt-2 overflow-scroll",
          },
          _vm._l(_vm.searchResults, function (result, index) {
            return _c(
              "div",
              {
                key: "" + index + result.StopSummary,
                staticClass: "border-b border-gray-300 bg-gray-100 px-2 py-3",
                on: {
                  click: function () {
                    return _vm.selectAddress(result)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "font-semibold dark:text-gray-600" },
                  [_vm._v(_vm._s(result.StopSummary))]
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }