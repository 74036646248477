<template>
    <div class="h-full flex flex-col">
        <div class="flex items-center px-2 py-4 border-b border-gray-300 relative justify-center">
            <action-button icon="arrow_back" color="transparent" :action="close" class="absolute left-0" />
            <h5 class="text-center m-0 font-semibold">Add Via Stop</h5>
        </div>
        <div class="flex-1 flex flex-col px-1 py-3 gap-2 overflow-hidden">
            <div class="flex flex-col flex-1 overflow-hidden pr-6">
                <div v-for="stop in previousStops" :key="stop.StopOrder" class="flex items-center py-1 stop">
                    <span class="bg-green-500 w-2 h-2 rounded-full m-2"></span>
                    <div class="flex flex-1 items-center bg-white border border-gray-200 rounded px-2 py-1">
                        <input class="flex-1 h-10 bg-white p-2" :value="stop.StopSummary" disabled />
                    </div>
                </div>
                <div class="flex items-center py-1 stop">
                    <span class="bg-red-500 w-2 h-2 rounded-full m-2"></span>
                    <div class="flex flex-1 items-center gap-2 bg-white border border-gray-200 rounded px-2 py-1">
                        <input class="flex-1 h-10 bg-white p-2 focus:outline-none" placeholder="Search address" v-model="searchText" />
                        <span class="material-icons-outlined cursor-pointer text-gray-500" @click="clearSearch">close</span>
                    </div>
                </div>
                <div v-for="(stop, index) in afterStops" :key="stop.StopOrder" class="flex items-center py-1" :class="{stop: index < afterStops.length - 1}">
                    <span class="bg-green-500 w-2 h-2 rounded-full m-2"></span>
                    <div class="flex flex-1 items-center bg-white border border-gray-200 rounded px-2 py-1">
                        <input class="flex-1 h-10 bg-white p-2" :value="stop.StopSummary" disabled />
                    </div>
                </div>
                <div v-if="searchResults.length" class="border border-gray-300 rounded mt-2 overflow-scroll ml-6">
                    <div v-for="(result, index) in searchResults" :key="`${index}${result.StopSummary}`" class="border-b border-gray-300 px-2 py-3 bg-gray-100" @click="() => selectAddress(result)">
                        <span class="font-semibold dark:text-gray-600">{{ result.StopSummary }}</span>
                    </div>
                </div>
            </div>
            <div class="px-6 mt-2">
                <action-button text="Add" :circular="false" color="#e90000" :action="addViaStop" :disabled="!selectedAddress" :async="true" />
            </div>
        </div>
    </div>
</template>

<script>
import {addBookingStop, getCompleteAddress} from '@/services/StopService';
import {bus} from '@/main';
import {searchAddressMixin} from '@/mixins/searchAddressMixin.js';
import {showErrorDialog} from '@/services/DialogService';
import {showSuccessToast} from '../../services/ToastService';
import ActionButton from '@/components/ActionButton';

export default {
    name: 'AddViaStop',
    props: {
        booking: {
            type: Object,
        },
    },
    components: {
        ActionButton,
    },
    mixins: [searchAddressMixin],
    data() {
        return {
            previousStops: [],
            afterStops: [],
        };
    },
    methods: {
        async addViaStop() {
            try {
                let address = this.selectedAddress;
                if (address.PlaceId) {
                    address = await getCompleteAddress(address.PlaceId);
                }
                await addBookingStop(this.booking.Id, {...address, Type: 'Via', StopSummary: this.selectedAddress.StopSummary});
                bus.$emit('openHome');
                showSuccessToast('Stop added successfully.');
                bus.$emit('fetchUpdatedBooking');
                this.clearSearch();
            } catch (err) {
                showErrorDialog(err, 'There was an error adding the stop.', 'Try Again');
            }
        },
        close() {
            this.$emit('close');
        },
    },
    watch: {
        'booking.BookingStops': {
            immediate: true,
            handler() {
                const {BookingStops, NextStop} = this.booking;
                this.previousStops = BookingStops.slice(0, NextStop - 1);
                this.afterStops = BookingStops.slice(NextStop - 1);
            },
        },
    },
};
</script>

<style lang="scss">
.stop {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        left: 0.75rem;
        top: 50%;
        width: 1px;
        height: 100%;
        @apply bg-gray-300;
        z-index: -1;
    }
}
</style>
