<template>
    <div class="flex flex-col">
        <div class="header border-gray-200 border-b px-3 py-2 flex items-center justify-between">
            <div @click="handleClick">
                <span class="material-icons-outlined text-xl font-bold" v-if="buttonType !== 'none'">{{ buttonType === 'close' ? 'close' : 'arrow_back_ios_new' }}</span>
            </div>
            <h4 class="m-0">{{ journeyStatus }}</h4>
            <div class="socket-status">
                <div class="w-4 h-4 rounded-full" :class="socketClass"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from '@/main';
import {resumeBooking} from '@/services/StatusService.js';
import {showErrorToast} from '../services/ToastService';

export default {
    name: 'Header',
    props: {
        booking: {
            type: Object,
        },
        status: {
            type: String,
            default: 'InProgress',
        },
        socketStatus: {
            type: String,
            default: 'CONNECTING',
        },
        buttonType: {
            type: String,
            default: 'close',
        },
    },
    methods: {
        navigateBack() {
            bus.$emit('closeWindow');
        },
        async handleClick() {
            if (this.buttonType === 'close') this.navigateBack();
            else if (this.buttonType === 'back') {
                try {
                    bus.$emit('loading', true);
                    await resumeBooking(this.booking.Id);
                    bus.$emit('fetchUpdatedBooking');
                } catch (err) {
                    bus.$emit('loading', false);
                    showErrorToast(err, 'There was an error whilst trying to resume the booking.');
                }
            }
        },
    },
    computed: {
        socketClass() {
            switch (this.socketStatus) {
                case 'CONNECTING':
                    return 'bg-yellow-600';
                case 'CONNECTED':
                    return 'bg-green-600';
                case 'DISCONNECTED':
                    return 'bg-red-600';
                default:
                    return 'bg-orange-600';
            }
        },
        journeyStatus() {
            switch (this.status) {
                case 'Allocated':
                    return 'Booking Allocated';
                case 'OnRoute':
                    return 'EnRoute to Passenger';
                case 'Arrived':
                    return 'Awaiting Passenger';
                case 'InProgress':
                    return 'Journey In Progress';
                case 'Completed':
                    return 'Journey Completion';
            }
            return 'InProgress';
        },
    },
};
</script>
