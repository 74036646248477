import {bus} from './main';
import io from 'socket.io-client';
import Vue from 'vue';

export const initialiseSockets = function () {
    Vue.prototype.io = io;
    Vue.prototype.socket = io(window.socketendpoint, {
        query: {
            authorization: Vue.$cookies.get('token'),
        },
        transports: ['websocket'],
    });
    this.socket.on('connect', () => {
        bus.$emit('SOCKET_CONNECTED');
    });

    this.socket.on('disconnect', () => {
        bus.$emit('SOCKET_DISCONNECTED');
    });

    this.socket.on('reconnect', () => {
        bus.$emit('SOCKET_RECONNECTING');
    });

    this.socket.on('BOOKING_UPDATE', booking => {
        bus.$emit('bookingUpdate', booking);
    });

    this.socket.on('BOOKING_PRICE_UPDATE', booking => {
        bus.$emit('bookingPriceUpdate', booking);
    });

    this.socket.on('NEW_MESSAGE_ARRIVED', data => {
        bus.$emit('newChatMessage', data);
    });
};
