var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.takeSignature
        ? [
            _vm.error
              ? _c("span", { staticClass: "font-semibold text-red-500" }, [
                  _vm._v(_vm._s(_vm.error)),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "border-b border-gray-200 h-72 rounded bg-gray-100",
              },
              [
                _c("VueSignaturePad", {
                  ref: "signaturePad",
                  staticClass: "signature-pad",
                }),
              ],
              1
            ),
            _vm._m(0),
          ]
        : _c("div", [
            _c("img", {
              staticClass: "w-full p-2 bg-gray-100",
              attrs: {
                src: _vm.signatureUrl || _vm.booking.PassengerSignatureImageUrl,
              },
            }),
          ]),
      _c(
        "div",
        { staticClass: "flex gap-1 my-2" },
        [
          _vm.takeSignature
            ? _c("ActionButton", {
                staticClass: "flex-1",
                attrs: {
                  text: "Reset",
                  circular: false,
                  color: "#9e9e9e",
                  action: _vm.reset,
                },
              })
            : _c("ActionButton", {
                staticClass: "flex-1",
                attrs: {
                  text: "Cancel",
                  circular: false,
                  color: "#9e9e9e",
                  action: _vm.cancel,
                },
              }),
          _vm.takeSignature
            ? _c("ActionButton", {
                staticClass: "flex-1",
                attrs: {
                  text: "Save",
                  circular: false,
                  color: "#1FD0A3",
                  async: true,
                  action: _vm.save,
                },
              })
            : _c("ActionButton", {
                staticClass: "flex-1",
                attrs: {
                  text: "Change",
                  circular: false,
                  color: "#1FD0A3",
                  async: true,
                  action: function () {
                    return (_vm.takeSignature = true)
                  },
                },
              }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex flex-col items-center my-2 gap-1" }, [
      _c("span", { staticClass: "font-semibold" }, [
        _vm._v("Please sign here"),
      ]),
      _c("span", { staticClass: "text-center text-sm text-gray-400" }, [
        _vm._v(
          "By signing above you agree to our all ride charges and terms and conditions."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }