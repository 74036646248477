var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "rounded border border-gray-200 flex items-center justify-center gap-2 p-4 relative",
    },
    [
      _c("div", { staticClass: "flex flex-col flex-1" }, [
        _c("span", { staticClass: "font-semibold" }, [_vm._v("Journey Total")]),
        _c("div", { staticClass: "flex flex-col" }, [
          _c("span", { staticClass: "text-5xl font-semibold" }, [
            _vm._v(_vm._s(_vm._f("currency")(_vm.booking.DriverCommission))),
          ]),
          _c("span", { staticClass: "mt-2" }, [
            _vm._v(
              "This excludes any extras or waiting charges. Extras and waiting charges will be paid every week when driver statement is issued."
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }