import {bus} from '@/main';

export const localMeterMixin = {
    data() {
        return {
            localMeter: {
                MeterCost: 0,
                ActualDistance: 0,
                StationaryCost: 0,
                StationaryTime: 0,
                WaitingTime: 0,
                WaitingCost: 0,
                TaxAmount: 0,
            },
        };
    },
    methods: {
        updateLocalMeter(data) {
            this.localMeter = data;
        },
    },
    computed: {
        useLocalMeter() {
            if (this.booking.PricingMode !== 'ActualRoute' && !this.booking.AsDirected) {
                return false;
            }

            if (this.booking.BookingStatus === 'InProgress') {
                return this.driverState.UseLocalMeter;
            }

            return false;
        },
    },
    created() {
        bus.$on('updateLocalMeter', this.updateLocalMeter);
    },
};
