var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-full flex flex-col" }, [
    _c(
      "div",
      {
        staticClass:
          "flex items-center px-2 py-4 border-b border-gray-300 relative justify-center",
      },
      [
        _c("action-button", {
          staticClass: "absolute left-0",
          attrs: {
            icon: "arrow_back",
            color: "transparent",
            action: _vm.close,
          },
        }),
        _c("h5", { staticClass: "text-center m-0 font-semibold" }, [
          _vm._v("Remove Via Stop"),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex-1 flex flex-col px-1 py-3 gap-2 overflow-hidden" },
      [
        _c(
          "div",
          { staticClass: "flex flex-col flex-1 overflow-hidden pr-6" },
          [
            _vm._l(_vm.booking.BookingStops, function (stop, index) {
              return [
                _vm.removedStop !== stop.Id
                  ? _c(
                      "div",
                      {
                        key: stop.StopOrder,
                        staticClass: "flex items-center py-1",
                        class: {
                          stop: index < _vm.booking.BookingStops.length - 1,
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "bg-green-500 w-2 h-2 rounded-full m-2",
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-1 items-center bg-white border border-gray-200 rounded px-2 py-1",
                          },
                          [
                            _c("input", {
                              staticClass: "flex-1 h-10 bg-white p-2",
                              attrs: { disabled: "" },
                              domProps: { value: stop.StopSummary },
                            }),
                            !_vm.removedStop &&
                            _vm.booking.NextStop <= stop.StopOrder &&
                            index !== _vm.booking.BookingStops.length - 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "material-icons-outlined cursor-pointer text-gray-500",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearStop(stop.Id)
                                      },
                                    },
                                  },
                                  [_vm._v("close")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "px-6 mt-2" },
          [
            _c("action-button", {
              attrs: {
                text: "Remove",
                circular: false,
                color: "#e90000",
                action: _vm.removeViaStop,
                disabled: !_vm.removedStop,
                async: true,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }