var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col" }, [
    _c(
      "div",
      {
        staticClass:
          "header border-gray-200 border-b px-3 py-2 flex items-center justify-between",
      },
      [
        _c("div", { on: { click: _vm.handleClick } }, [
          _vm.buttonType !== "none"
            ? _c(
                "span",
                { staticClass: "material-icons-outlined text-xl font-bold" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.buttonType === "close"
                        ? "close"
                        : "arrow_back_ios_new"
                    )
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _c("h4", { staticClass: "m-0" }, [_vm._v(_vm._s(_vm.journeyStatus))]),
        _c("div", { staticClass: "socket-status" }, [
          _c("div", {
            staticClass: "w-4 h-4 rounded-full",
            class: _vm.socketClass,
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }