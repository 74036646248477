<template functional>
    <div :class="data.staticClass">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="margin: auto; background: rgba(241, 242, 243, 0); display: block"
            :width="props.size"
            :height="props.size"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid">
            <circle cx="84" cy="50" r="10" fill="#a80303">
                <animate attributeName="r" repeatCount="indefinite" dur="0.5434782608695652s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
                <animate
                    attributeName="fill"
                    repeatCount="indefinite"
                    dur="2.1739130434782608s"
                    calcMode="discrete"
                    keyTimes="0;0.25;0.5;0.75;1"
                    values="#a80303;#e9e9e9;#dddddd;#e90000;#a80303"
                    begin="0s"></animate>
            </circle>
            <circle cx="16" cy="50" r="10" fill="#a80303">
                <animate
                    attributeName="r"
                    repeatCount="indefinite"
                    dur="2.1739130434782608s"
                    calcMode="spline"
                    keyTimes="0;0.25;0.5;0.75;1"
                    values="0;0;10;10;10"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                    begin="0s"></animate>
                <animate
                    attributeName="cx"
                    repeatCount="indefinite"
                    dur="2.1739130434782608s"
                    calcMode="spline"
                    keyTimes="0;0.25;0.5;0.75;1"
                    values="16;16;16;50;84"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                    begin="0s"></animate>
            </circle>
            <circle cx="50" cy="50" r="10" fill="#e90000">
                <animate
                    attributeName="r"
                    repeatCount="indefinite"
                    dur="2.1739130434782608s"
                    calcMode="spline"
                    keyTimes="0;0.25;0.5;0.75;1"
                    values="0;0;10;10;10"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                    begin="-0.5434782608695652s"></animate>
                <animate
                    attributeName="cx"
                    repeatCount="indefinite"
                    dur="2.1739130434782608s"
                    calcMode="spline"
                    keyTimes="0;0.25;0.5;0.75;1"
                    values="16;16;16;50;84"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                    begin="-0.5434782608695652s"></animate>
            </circle>
            <circle cx="84" cy="50" r="10" fill="#dddddd">
                <animate
                    attributeName="r"
                    repeatCount="indefinite"
                    dur="2.1739130434782608s"
                    calcMode="spline"
                    keyTimes="0;0.25;0.5;0.75;1"
                    values="0;0;10;10;10"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                    begin="-1.0869565217391304s"></animate>
                <animate
                    attributeName="cx"
                    repeatCount="indefinite"
                    dur="2.1739130434782608s"
                    calcMode="spline"
                    keyTimes="0;0.25;0.5;0.75;1"
                    values="16;16;16;50;84"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                    begin="-1.0869565217391304s"></animate>
            </circle>
            <circle cx="16" cy="50" r="10" fill="#e9e9e9">
                <animate
                    attributeName="r"
                    repeatCount="indefinite"
                    dur="2.1739130434782608s"
                    calcMode="spline"
                    keyTimes="0;0.25;0.5;0.75;1"
                    values="0;0;10;10;10"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                    begin="-1.6304347826086956s"></animate>
                <animate
                    attributeName="cx"
                    repeatCount="indefinite"
                    dur="2.1739130434782608s"
                    calcMode="spline"
                    keyTimes="0;0.25;0.5;0.75;1"
                    values="16;16;16;50;84"
                    keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                    begin="-1.6304347826086956s"></animate>
            </circle>
        </svg>
        <p v-if="props.text" class="text-center">{{ props.text }}</p>
    </div>
</template>

<script>
export default {
    name: 'Loader',
};
</script>
