import {bus} from '@/main';
import {haversineDistance} from '@/utils/helper';
import {showErrorToast} from '../services/ToastService';
import {showWarningDialog} from '@/services/DialogService';
import {stopWaiting} from '@/services/WaitService';

export const localWaitingUpdateMixin = {
    data() {
        return {
            isManualTimerRunning: false,
            waitPointId: null,
            localWaitingInterval: null,
            waitStartLocation: null,
        };
    },
    methods: {
        updateLocalWaiting() {
            bus.$emit('bookingLocalWaitingUpdate');
        },
        continueLastWaiting() {
            // check for any auto waiting
            if (this.booking.BookingStatus === 'InProgress' && this.booking.AutomaticStationaryTime) {
                const autoWaiting = localStorage.getItem(`${this.booking.Id}-auto-waiting`);
                if (autoWaiting && !this.localWaitingInterval) {
                    this.localWaitingInterval = setInterval(this.updateLocalWaiting, 1000);
                }
                return;
            }

            // check for any manual waiting
            let waiting = localStorage.getItem(`${this.booking.Id}-waiting`);
            if (waiting) {
                waiting = JSON.parse(waiting);

                this.isManualTimerRunning = true;
                this.waitPointId = waiting.id;

                const waitingStartTime = new Date(waiting.time);
                const currentTime = new Date();

                // update local timer with total time elapsed
                const secondsElapsed = Math.round((currentTime - waitingStartTime) / 1000);
                bus.$emit('bookingLocalWaitingUpdate', secondsElapsed);

                // update waiting timer every second
                if (!this.localWaitingInterval) this.localWaitingInterval = setInterval(this.updateLocalWaiting, 1000);
            }
        },
        async handleLocationUpdate(location) {
            if (!this.isManualTimerRunning) return;
            location.latitude = location.latitude || location.lat;
            location.longitude = location.longitude || location.lon;
            if (!this.waitStartLocation) {
                this.waitStartLocation = location;
                return;
            }

            const threshold = Number(process.env.VUE_APP_MANUAL_WAIT_ALLOWED_MOVEMENT) / 1000; // convert to km

            const distance = haversineDistance(this.waitStartLocation, location);
            if (distance > threshold) {
                try {
                    await stopWaiting(this.booking.Id, this.waitPointId);
                    bus.$emit('stopManualTimer');
                    showWarningDialog('Warning', 'Waiting was stopped as the vehicle was in motion.', 'OK');
                } catch (err) {
                    showErrorToast(err, 'Something went wrong whilst stopping wait time.');
                }
            }
        },
        startAutoTimer() {
            if (!this.localWaitingInterval) {
                this.localWaitingInterval = setInterval(this.updateLocalWaiting, 1000);
            }
        },
        stopAutoTimer() {
            if (this.localWaitingInterval) {
                clearInterval(this.localWaitingInterval);
                this.localWaitingInterval = null;
            }
        },
        startManualTimer(waitPoint) {
            this.isManualTimerRunning = true;
            this.waitPointId = waitPoint.Id;

            this.appData.latitude = this.appData.latitude || this.appData.lat;
            this.appData.longitude = this.appData.longitude || this.appData.lon;
            if (this.appData.latitude && this.appData.longitude) {
                this.waitStartLocation = {
                    latitude: this.appData.latitude,
                    longitude: this.appData.longitude,
                };
            }

            // store waiting info in local storage, to preserve state across reloads
            const currentTime = new Date();
            localStorage.setItem(
                `${this.booking.Id}-waiting`,
                JSON.stringify({
                    time: currentTime,
                    id: waitPoint.Id,
                })
            );

            // update waiting timer every second
            if (!this.localWaitingInterval) this.localWaitingInterval = setInterval(this.updateLocalWaiting, 1000);
        },
        stopManualTimer(fetchBooking = true) {
            this.isManualTimerRunning = false;
            this.waitStartLocation = null;
            this.waitPointId = null;

            // clear timer for updating waiting time
            if (this.localWaitingInterval) clearInterval(this.localWaitingInterval);
            this.localWaitingInterval = null;

            // re-fetch booking
            if (fetchBooking) bus.$emit('fetchUpdatedBooking', true);

            // remove waiting entry from local storage
            localStorage.removeItem(`${this.booking.Id}-waiting`);
        },
    },
    created() {
        this.continueLastWaiting();
        bus.$on('startAutoTimer', this.startAutoTimer);

        bus.$on('stopAutoTimer', this.stopAutoTimer);

        bus.$on('startManualTimer', this.startManualTimer);

        bus.$on('stopManualTimer', this.stopManualTimer);
    },
    destroyed() {
        bus.$emit('clearLocalWaiting');

        // clear timer for updating waiting time
        if (this.localWaitingInterval) clearInterval(this.localWaitingInterval);

        bus.$off('startAutoTimer', this.startAutoTimer);
        bus.$off('stopAutoTimer', this.stopAutoTimer);
        bus.$off('startManualTimer', this.startManualTimer);
        bus.$off('stopManualTimer', this.stopManualTimer);
    },
};
