<template>
    <div class="h-full flex flex-col">
        <Loader v-if="isLoading" />
        <Toast :message="toastMessage" :type="toastType" />
        <Transition :name="animation" mode="in-out">
            <RideMenu v-if="page === 'menu'" :booking="booking" @close="page = 'home'" @open="handlePageOpen" />
        </Transition>
        <Transition name="slide" mode="out-in">
            <RideDetails v-if="page === 'ride-details'" :booking="booking" @close="page = 'home'" :use-local-meter="useLocalMeter" :local-meter="localMeter" />
        </Transition>
        <Transition name="slide" mode="out-in">
            <DropOff v-if="page === 'add-drop'" :booking="booking" @close="page = 'menu'" type="add" />
        </Transition>
        <Transition name="slide" mode="out-in">
            <DropOff v-if="page === 'amend-drop'" :booking="booking" @close="page = 'menu'" type="amend" />
        </Transition>
        <Transition name="slide" mode="out-in">
            <AddViaStop v-if="page === 'add-via-stop'" :booking="booking" @close="page = 'menu'" />
        </Transition>
        <Transition name="slide" mode="out-in">
            <RemoveViaStop v-if="page === 'remove-via-stop'" :booking="booking" @close="page = 'menu'" />
        </Transition>
        <Transition name="slide" mode="out-in">
            <ChatScreen v-if="page === 'chat'" :booking="booking" @close="page = 'home'" :conversation="conversation" :claims="claims" />
        </Transition>
        <Transition name="slide-in" mode="in-out">
            <div class="h-full flex flex-col overflow-hidden" v-if="page === 'home'">
                <Header :socket-status="socketStatus" :button-type="buttonType" :status="booking.BookingStatus" :booking="booking"></Header>
                <div v-if="(booking.BookingStatus === 'InProgress' && booking.CompletingDateTime) || booking.BookingStatus === 'Completed'" class="journey flex-1 overflow-scroll">
                    <CompletedBooking :booking="booking" :app-data="appData" :payment-link-sent="paymentLinkSent" :use-local-meter="useLocalMeter" :local-meter="localMeter" />
                </div>
                <div class="journey flex-1 p-3 overflow-scroll" v-else>
                    <journey-total
                        :booking="booking"
                        v-if="showJourneyTotal"
                        :pause-meter="confirmMeterPause"
                        :resume-meter="resumeMeter"
                        :use-local-meter="useLocalMeter"
                        :local-meter="localMeter"></journey-total>
                    <journey-breakdown
                        :booking="booking"
                        v-if="showJourneyBreakdown"
                        :is-waiting="isManualTimerRunning"
                        :wait-point-id="waitPointId"
                        :use-local-meter="useLocalMeter"
                        :local-meter="localMeter"></journey-breakdown>
                    <journey-info :booking="booking" :chat-unread-count="conversation && conversation.UnreadCount"></journey-info>
                </div>
                <BookingStatusButton
                    :booking="booking"
                    :is-waiting="isManualTimerRunning"
                    :is-loading="isLoading"
                    :wait-point-id="waitPointId"
                    :edited-amount="editedAmount"
                    :app-data="appData"
                    :booking-complete-confirmation="bookingCompleteConfirmation"
                    :use-local-meter="useLocalMeter"
                    :local-meter="localMeter"
                    :sync-meter-on-server="updatePriceOnServer"
                    v-if="booking.BookingStatus !== 'Completed' || !disallowExit"></BookingStatusButton>
            </div>
        </Transition>
        <!-- Keeping in background, to allow acting on status change events on any screen -->
        <BookingStatusButton
            v-show="false"
            :booking="booking"
            :is-waiting="isManualTimerRunning"
            :is-loading="isLoading"
            :wait-point-id="waitPointId"
            :edited-amount="editedAmount"
            :app-data="appData"
            :booking-complete-confirmation="bookingCompleteConfirmation"
            :use-local-meter="useLocalMeter"
            :local-meter="localMeter"
            :sync-meter-on-server="updatePriceOnServer"
            v-if="page !== 'home'"></BookingStatusButton>
    </div>
</template>

<script>
import {arrivalWaitingMixin} from '@/mixins/arrivalWaitingMixin';
import {bus} from '@/main';
import {clearAllWaiting} from '@/utils/helper';
import {confirmAndRunAction, showErrorDialog} from '@/services/DialogService';
import {getConversationDetails} from '@/services/ChatService';
import {goOnBreak} from '../services/ShiftService';
import {localWaitingUpdateMixin} from '@/mixins/localWaitingUpdateMixin';
import {locationsMixin} from '@/mixins/locationsMixin';
import {navigateBack} from '@/services/PostMessageService';
import {pauseMeter, resumeMeter} from '@/services/MeterService';
import {showSuccessDialog} from '../services/DialogService';
import {showSuccessToast} from '../services/ToastService';
import {stationaryAutoWaitMixin} from '@/mixins/stationaryAutoWaitMixin';
import {stopWaiting} from '@/services/WaitService';
import AddViaStop from '@/components/journey-stops/AddViaStop';
import BookingStatusButton from '@/components/BookingStatusButton';
import ChatScreen from './chat/ChatScreen.vue';
import CompletedBooking from '@/components/completed-journey/CompletedBooking';
import DropOff from '@/components/journey-stops/DropOff';
import Header from '@/components/Header';
import JourneyBreakdown from '@/components/JourneyBreakdown';
import JourneyInfo from '@/components/JourneyInfo';
import JourneyTotal from '@/components/JourneyTotal';
import Loader from '@/components/loaders/Loader';
import RemoveViaStop from '@/components/journey-stops/RemoveViaStop';
import RideDetails from '@/components/RideDetails';
import RideMenu from '@/components/ride-menu/RideMenu';
import Swal from 'sweetalert2';
import Toast from './Toast.vue';
import Vue from 'vue';

export default {
    name: 'BookingWrapper',
    props: {
        booking: {
            type: Object,
        },
        useLocalMeter: {
            type: Boolean,
        },
        localMeter: {
            type: Object,
        },
    },
    components: {
        AddViaStop,
        ChatScreen,
        RemoveViaStop,
        DropOff,
        CompletedBooking,
        Header,
        JourneyTotal,
        JourneyBreakdown,
        BookingStatusButton,
        JourneyInfo,
        Loader,
        RideDetails,
        RideMenu,
        Toast,
    },
    mixins: [localWaitingUpdateMixin, arrivalWaitingMixin, stationaryAutoWaitMixin, locationsMixin],
    data() {
        return {
            appData: {
                speed: 0,
            },
            claims: null,
            isLoading: false,
            page: 'home',
            animation: 'slide',
            socketStatus: 'CONNECTING',
            editedAmount: null,
            disallowExit: false,
            toastMessage: null,
            toastType: null,
            paymentLinkSent: false,
            conversation: null,
            bookingCompleteConfirmation: false,
        };
    },
    methods: {
        setClaims() {
            let user = localStorage.getItem('user');
            if (user) {
                user = JSON.parse(user);
                let claims = user.Claims.split('|');
                let claimsObj = {};
                claims.forEach(claim => {
                    let keyValues = claim.split(':');
                    claimsObj[keyValues[0]] = keyValues[1];
                });
                this.claims = claimsObj;
            } else {
                this.claims = {};
            }
        },
        handleNewChatMessage({conversation, message}) {
            if (this.page === 'chat') {
                return;
            }
            if (conversation._id !== (this.conversation && this.conversation._id)) {
                return;
            }
            if (message.SenderId !== this.claims.DriverId) {
                showSuccessToast(
                    this.booking.Passenger ? `You have a new message from ${this.booking.Passenger.Firstname || ''} ${this.booking.Passenger.Suranme || ''}` : 'You have a new chat message'
                );
                Vue.set(this.conversation, 'UnreadCount', (this.conversation.UnreadCount || 0) + 1);
            }
        },
        showToast(data) {
            this.toastMessage = data.message;
            this.toastType = data.type;
            setTimeout(() => {
                this.toastMessage = null;
                this.toastType = null;
            }, 2000);
        },
        postMessageHandler(event) {
            if (typeof event.data !== 'string') return;
            const _messageData = JSON.parse(event.data);
            if (_messageData.type === 'geoLocation') {
                if (_messageData.data.speed < 0) {
                    return;
                }
                this.appData = _messageData.data;
                this.saveLocationToSessionStorage(_messageData.data);

                bus.$emit('speedUpdated', _messageData.data.speed);
                this.handleSpeedUpdate(_messageData.data.speed);
                if (this.isManualTimerRunning) this.handleLocationUpdate(_messageData.data);
            } else if (_messageData.type === 'sumUp') {
                bus.$emit('sumUp', _messageData);
            } else if (_messageData.type === 'ui' && _messageData.message === 'physicalBack') {
                this.gestureAndBackHandler();
            } else if (_messageData.type === 'ui' && _messageData.message === 'bookingComplete') {
                if (this.booking.BookingStatus === 'InProgress') {
                    this.bookingCompleteConfirmation = true;
                    this.$nextTick(() => {
                        bus.$emit('moveToCompleting');
                    });
                }
            } else if (_messageData.type === 'ui' && _messageData.message === 'startJob') {
                bus.$emit('moveToInProgress');
            } else if (_messageData.type === 'visibilityChange') {
                if (_messageData.data && _messageData.data.visible) {
                    bus.$emit('refetchPrice');
                    bus.$emit('refetchMessages');
                }
            }
        },
        handlePageOpen(page) {
            this.page = page;
        },
        gestureAndBackHandler() {
            //since Swal is not attached any component, it has to be manually closed, if opened
            if (Swal.isVisible()) {
                Swal.close();
                return;
            }

            // close emergency if open
            bus.$emit('cancelEmergency');

            switch (this.page) {
                case 'home':
                    if (this.booking.BookingStatus === 'Completed' && this.disallowExit) return;
                    navigateBack();
                    return;
                case 'ride-details':
                case 'menu':
                    this.page = 'home';
                    return;
                case 'add-via-stop':
                case 'remove-via-stop':
                case 'add-drop':
                case 'amend-drop':
                    this.page = 'menu';
                    return;
                case 'chat':
                default:
                    this.page = 'home';
                    return;
            }
        },
        handleBookingStatusUpdate(newStatus) {
            if (newStatus !== 'Arrived') {
                this.clearArrivalWaiting();
            }

            if (newStatus !== 'InProgress') {
                this.clearAutoWaiting();
            }

            if (newStatus === 'Completed') {
                clearAllWaiting(this.booking);
            } else if (newStatus === 'Cancelled' || newStatus === 'COA') {
                clearAllWaiting(this.booking);
                navigateBack();
                window.location.href = '/InvalidBooking.html';
            } else if (newStatus === 'Arrived') {
                this.initiateArrivalWaiting();
            }
        },
        confirmMeterPause() {
            confirmAndRunAction(
                'Pause Meter',
                'Are you sure you want to pause the meter?',
                'Meter Pause Successful.',
                'Please tap on confirm to continue',
                'Oops!',
                'Something went wrong whilst pausing the meter.',
                async () => {
                    await this.pauseMeter();
                },
                () => {}
            );
        },
        async pauseMeter() {
            // stop any ongoing waiting timers
            if (this.isManualTimerRunning) {
                await stopWaiting(this.booking.Id, this.waitPointId);
                bus.$emit('stopManualTimer', false);
            } else {
                this.clearAutoWaiting();
            }
            await pauseMeter(this.booking.Id);
            bus.$emit('bookingUpdate', {...this.booking, MeterPaused: true});
        },
        async resumeMeter() {
            try {
                await resumeMeter(this.booking.Id);
                bus.$emit('bookingUpdate', {...this.booking, MeterPaused: false});
                bus.$emit('fetchUpdatedBooking', true);
            } catch (err) {
                showErrorDialog(err, 'Something went wrong whilst resuming the meter.', 'Try Again');
            }
        },
        deregisterBusListeners() {
            bus.$off('loading');

            bus.$off('closeWindow');

            bus.$off('amountEdited');

            bus.$off('openHome');
            bus.$off('openMenu');
            bus.$off('openChat');
            bus.$off('openAddDropScreen');
            bus.$off('openDetails');

            bus.$off('disallowExit', this.setDisallowExit);

            bus.$off('newChatMessage', this.handleNewChatMessage);

            bus.$on('bookingCompleted', this.handleOnBreakStatus);

            bus.$off('SOCKET_CONNECTED');
            bus.$off('SOCKET_DISCONNECTED');
            bus.$off('SOCKET_RECONNECTING');
        },
        setDisallowExit(val) {
            this.disallowExit = val;
        },
        async fetchConversation() {
            if (this.booking && this.booking.BookingSource === 'APP' && this.driverState.ShowPassengerChat) {
                this.conversation = await getConversationDetails(this.booking.Id);
            }
        },
        resetUnreadCount() {
            Vue.set(this.conversation, 'UnreadCount', 0);
        },
        handleOnBreakStatus() {
            const enableBreak = localStorage.getItem(`${this.booking.Id}-break-after-job`);
            if (enableBreak === 'true') {
                this.enableShiftBreak();
            }
            localStorage.removeItem(`${this.booking.Id}-break-after-job`);
        },
        async enableShiftBreak() {
            try {
                await goOnBreak();
                showSuccessDialog('You are now on break.', 'Confirm', 'Break Started', {
                    htmlContainer: 'text-2xl',
                });
            } catch (err) {
                showErrorDialog(null, 'There was an error whilst trying to go on break');
            }
        },
    },
    computed: {
        showJourneyTotal() {
            if (!this.booking) return false;

            const {BookingStatus} = this.booking;
            if (BookingStatus !== 'Arrived' && BookingStatus !== 'InProgress') {
                return false;
            }

            if (this.isExternalMeterBooking) {
                return false;
            }

            return true;
        },
        showJourneyBreakdown() {
            if (!this.booking) return false;

            const {BookingStatus} = this.booking;
            if (BookingStatus !== 'Arrived' && BookingStatus !== 'InProgress') {
                return false;
            }

            if (this.isExternalMeterBooking) {
                return false;
            }

            return true;
        },
        buttonType() {
            if (this.booking.BookingStatus === 'InProgress' && this.booking.CompletingDateTime) {
                return 'back';
            } else if (this.booking.BookingStatus === 'Completed') {
                return 'none';
            }
            return 'close';
        },
    },
    watch: {
        page(pageVal) {
            setTimeout(() => {
                if (pageVal === 'home') {
                    this.animation = 'slide';
                } else {
                    this.animation = 'slide-in';
                }
            }, 0);
        },
        'booking.BookingStatus': {
            handler(newStatus) {
                this.handleBookingStatusUpdate(newStatus);
            },
        },
        'booking.Passenger.Id': {
            handler() {
                this.fetchConversation();
            },
            immediate: true,
        },
    },
    async mounted() {
        window.addEventListener('message', this.postMessageHandler);

        bus.$on('loading', isLoading => {
            this.isLoading = isLoading;
        });

        this.handleBookingStatusUpdate(this.booking.BookingStatus);
    },
    created() {
        window.iosCBHandler = iosData => {
            if (iosData.type === 'sumUp') {
                bus.$emit('sumUp', iosData);
                return;
            }
            if (iosData.type === 'ui' && iosData.message === 'gestureLeft') {
                this.gestureAndBackHandler();
            }
            if (iosData.type === 'geoLocation') {
                if (iosData.data.speed < 0) {
                    return;
                }
                this.appData = iosData.data;

                this.saveLocationToSessionStorage(iosData.data);

                bus.$emit('speedUpdated', iosData.data.speed);
                this.handleSpeedUpdate(iosData.data.speed);
                if (this.isManualTimerRunning) this.handleLocationUpdate(iosData.data);
            }

            if (iosData.type === 'visibilityChange') {
                if (iosData.data && iosData.data.visible) {
                    bus.$emit('refetchPrice');
                    bus.$emit('refetchMessages');
                }
            }

            if (iosData.type === 'ui' && iosData.message === 'bookingComplete') {
                if (this.booking.BookingStatus === 'InProgress') {
                    this.bookingCompleteConfirmation = true;
                    this.$nextTick(() => {
                        bus.$emit('moveToCompleting');
                    });
                }
            }

            if (iosData.type === 'ui' && iosData.message === 'startJob') {
                bus.$emit('moveToInProgress');
            }
        };

        bus.$on('closeWindow', () => {
            navigateBack();
        });

        bus.$on('openHome', () => {
            this.page = 'home';
        });

        bus.$on('openMenu', () => {
            this.page = 'menu';
        });

        bus.$on('openChat', () => {
            this.page = 'chat';
        });

        bus.$on('openAddDropScreen', () => {
            this.page = 'add-drop';
        });

        bus.$on('amountEdited', amount => {
            this.editedAmount = amount;
        });

        bus.$on('openDetails', () => {
            this.page = 'ride-details';
        });

        bus.$on('disallowExit', this.setDisallowExit);

        bus.$on('SOCKET_CONNECTED', () => {
            this.socketStatus = 'CONNECTED';
        });
        bus.$on('SOCKET_DISCONNECTED', () => {
            this.socketStatus = 'DISCONNECTED';
        });
        bus.$on('SOCKET_RECONNECTING', () => {
            this.socketStatus = 'CONNECTING';
        });

        bus.$on('SHOW_TOAST', this.showToast);

        bus.$on('PAYMENT_LINK_SENT', () => {
            this.paymentLinkSent = true;
        });

        bus.$on('newChatMessage', this.handleNewChatMessage);
        bus.$on('resetUnreadCount', this.resetUnreadCount);

        bus.$on('bookingCompleted', this.handleOnBreakStatus);

        this.setClaims();
    },
    destroyed() {
        this.deregisterBusListeners();
    },
};
</script>
