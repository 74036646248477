var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-full flex flex-col" }, [
    _c(
      "div",
      {
        staticClass:
          "flex items-center px-2 py-4 border-b border-gray-300 relative justify-center",
      },
      [
        _c("action-button", {
          staticClass: "absolute left-0",
          attrs: {
            icon: "arrow_back",
            color: "transparent",
            action: _vm.close,
          },
        }),
        _c("h5", { staticClass: "text-center m-0 font-semibold" }, [
          _vm._v(_vm._s(_vm.type === "add" ? "Add Dropoff" : "Amend Dropoff")),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex-1 flex flex-col p-3 gap-2 overflow-hidden" },
      [
        _c("span", { staticClass: "text-xl" }, [_vm._v("Search Address")]),
        _c("span", { staticClass: "text-sm text-gray-500" }, [
          _vm._v(
            "Enter the drop off location of your passenger to get navigation assistance."
          ),
        ]),
        _c("SearchBar", {
          staticClass: "flex-1",
          attrs: { booking: _vm.booking },
          on: { select: _vm.handleAddressSelection },
        }),
        _c("action-button", {
          attrs: {
            text: _vm.type === "add" ? "Add" : "Amend",
            circular: false,
            color: "#e90000",
            action: _vm.type === "add" ? _vm.addDropoff : _vm.amendDropoff,
            disabled: !_vm.selectedAddress,
            async: true,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }