var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col overflow-hidden" }, [
    _c(
      "div",
      { staticClass: "px-2" },
      [
        _c("action-button", {
          attrs: { icon: "west", color: "transparent", action: _vm.back },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "flex-1 overflow-scroll details" }, [
      _c("h3", { staticClass: "px-4 pt-4" }, [
        _vm._v("Booking " + _vm._s(_vm.booking.LocalId)),
      ]),
      _c(
        "div",
        { staticClass: "journey-info px-4 mb-4 flex flex-col gap-3" },
        [
          _c("InfoLabel", {
            attrs: {
              value: _vm.bookingTime,
              label: "Date/Time",
              size: "text-xl",
              "sub-text":
                _vm.booking.FlightInfo &&
                _vm.booking.FlightInfo.MinsAfterLanding
                  ? "Includes a delayed pickup time of " +
                    _vm.booking.FlightInfo.MinsAfterLanding +
                    " mins"
                  : null,
            },
          }),
          _c("InfoLabel", {
            attrs: {
              value: _vm.booking.ClientName || "-",
              label: "Account",
              size: "text-xl",
            },
          }),
          _c("InfoLabel", {
            attrs: {
              value:
                _vm.booking.Passenger.Firstname +
                " " +
                _vm.booking.Passenger.Surname,
              label: "Passenger",
              size: "text-xl",
            },
          }),
          _vm._l(_vm.booking.BookingStops, function (stop, index) {
            return _c(
              "div",
              { key: index },
              [
                index === 0
                  ? [
                      _c(
                        "InfoLabel",
                        {
                          attrs: {
                            value: stop.StopSummary,
                            label: "Pickup",
                            size: "text-xl",
                          },
                        },
                        [
                          stop.Passenger &&
                          (stop.Passenger.Firstname || stop.Passenger.Surname)
                            ? _c("template", { slot: "valueInfo" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-xl text-blue-400 font-medium",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        (stop.Passenger.Firstname || "-") +
                                          " " +
                                          (stop.Passenger.Surname || "-")
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.booking.FlightInfo &&
                      _vm.booking.FlightInfo.FlightNumber
                        ? _c("InfoLabel", {
                            staticClass: "mt-3",
                            attrs: {
                              value: _vm.booking.FlightInfo.FlightNumber,
                              label: "Flight Number",
                              size: "text-xl",
                              icon: "flight",
                            },
                          })
                        : _vm._e(),
                      _vm.booking.FlightInfo && _vm.booking.FlightInfo.Origin
                        ? _c("InfoLabel", {
                            staticClass: "mt-3",
                            attrs: {
                              value: _vm.booking.FlightInfo.Origin,
                              label: "Arriving From",
                              size: "text-xl",
                            },
                          })
                        : _vm._e(),
                    ]
                  : stop.StopSummary === "As Directed"
                  ? _c("AddDropButton", { attrs: { booking: _vm.booking } })
                  : index === _vm.booking.BookingStops.length - 1
                  ? _c(
                      "InfoLabel",
                      {
                        attrs: {
                          value: stop.StopSummary,
                          label: "DropOff",
                          size: "text-xl",
                        },
                      },
                      [
                        stop.Passenger &&
                        (stop.Passenger.Firstname || stop.Passenger.Surname)
                          ? _c("template", { slot: "valueInfo" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-xl text-blue-400 font-medium",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      (stop.Passenger.Firstname || "-") +
                                        " " +
                                        (stop.Passenger.Surname || "-")
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _c(
                      "InfoLabel",
                      {
                        attrs: {
                          value: stop.StopSummary,
                          label: "Via",
                          size: "text-xl",
                        },
                      },
                      [
                        stop.Passenger &&
                        (stop.Passenger.Firstname || stop.Passenger.Surname)
                          ? _c("template", { slot: "valueInfo" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-xl text-blue-400 font-medium",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      (stop.Passenger.Firstname || "-") +
                                        " " +
                                        (stop.Passenger.Surname || "-")
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
              ],
              2
            )
          }),
          _c("InfoLabel", {
            attrs: {
              value: _vm.booking.VehicleType,
              label: "Vehicle Type",
              size: "text-xl",
            },
          }),
          _c(
            "div",
            { staticClass: "grid grid-cols-2 gap-4" },
            [
              _c("InfoLabel", {
                attrs: {
                  value: _vm.booking.Pax,
                  label: "Passenger",
                  size: "text-xl",
                },
              }),
              _c("InfoLabel", {
                attrs: {
                  value: _vm.booking.Bax,
                  label: "Bags",
                  size: "text-xl",
                },
              }),
            ],
            1
          ),
          _vm.booking.DriverNotes
            ? _c("InfoLabel", {
                attrs: {
                  value: _vm.booking.DriverNotes,
                  label: "Driver Notes",
                  size: "text-xl",
                },
              })
            : _vm._e(),
        ],
        2
      ),
      _c("h3", { staticClass: "px-4 pt-4 border-t border-gray-300" }, [
        _vm._v("Financial Details"),
      ]),
      _c(
        "div",
        { staticClass: "journey-info px-4 mb-4 flex flex-col gap-2" },
        [
          _c("InfoLabel", {
            attrs: {
              value: _vm.displayedPaymentMethod,
              label: "Payment Method",
              size: "text-xl",
            },
          }),
          _vm.useLocalMeter
            ? _c("InfoLabel", {
                attrs: {
                  value: _vm.localMeter.ActualDistance || 0,
                  label: "Actual Distance (mi.)",
                  size: "text-xl",
                },
              })
            : _vm.booking.AsDirected ||
              _vm.booking.PricingMode === "ActualRoute"
            ? [
                _vm.booking.BookingStatus === "OnRoute"
                  ? _c("InfoLabel", {
                      attrs: {
                        value: _vm.booking.EstimatedDistance,
                        label: "Estimated Distance (mi.)",
                        size: "text-xl",
                      },
                    })
                  : _c("InfoLabel", {
                      attrs: {
                        value: _vm.booking.ActualDistance || 0,
                        label: "Actual Distance (mi.)",
                        size: "text-xl",
                      },
                    }),
              ]
            : _vm._e(),
          !_vm.isExternalMeterBooking
            ? _c("InfoLabel", {
                attrs: {
                  value: _vm._f("currency")(_vm.booking.DriverCommission),
                  label: "Driver Commission",
                  size: "text-xl",
                },
              })
            : _vm._e(),
        ],
        2
      ),
      _vm.fetchingExpenses
        ? _c(
            "div",
            [
              _c("LoadingTitle", {
                staticClass: "border-t border-gray-200",
                attrs: { title: "Fetching Expenses..." },
              }),
            ],
            1
          )
        : _vm.fetchExpensesError
        ? _c(
            "div",
            {
              staticClass: "py-6 px-2 border-t border-gray-200 text-center",
              style: { color: "#9e9e9e" },
            },
            [_vm._v("Couldn't fetch expenses")]
          )
        : _c("div", [
            _vm.expenses && _vm.expenses.length > 0
              ? _c("div", {}, [
                  _c(
                    "h3",
                    { staticClass: "px-4 pt-4 border-t border-gray-200" },
                    [_vm._v("Expenses")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "expense-info px-4 mb-4 flex flex-col gap-2 align-middle",
                    },
                    _vm._l(_vm.expenses, function (expense) {
                      return _c(
                        "div",
                        { key: expense.Id, staticClass: "grid grid-cols-4" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-span-2" },
                            [
                              _c("InfoLabel", {
                                attrs: {
                                  value: _vm._f("currency")(expense.value),
                                  label: expense.label,
                                  size: "text-xl",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-span-2 justify-between font-semibold flex items-center gap-3 py-1",
                            },
                            [
                              _c("div", { class: expense.textColor }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(expense.status) +
                                    "\n                            "
                                ),
                              ]),
                              expense.status !== "Approved"
                                ? _c("ActionButton", {
                                    attrs: {
                                      icon: "delete",
                                      action: function () {
                                        return _vm.deleteExpense(expense.id)
                                      },
                                      color: "#9e9e9e",
                                      "icon-color": "#fff",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }