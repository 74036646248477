<template>
    <div>
        <RatePassenger :booking="booking" class="mb-4" />
        <PaymentStatus :booking="booking" v-if="paymentLinkSent" />
        <DriverCommission :booking="booking" class="mb-4" v-if="booking.PaymentMethod === 'OnAccount'" />
        <div v-if="loadingUpcomingBooking" class="flex justify-center">
            <LoadingTitle title="Checking for any follow-on job" class="text-gray-400 text-lg font-normal"></LoadingTitle>
        </div>
        <FollowOnJob :upcoming-booking="upcomingBooking" v-else-if="upcomingBooking" />
    </div>
</template>

<script>
import {bus} from '@/main';
import {getFollowOnBooking} from '@/services/BookingService.js';
import {showErrorToast} from '../../services/ToastService';
import DriverCommission from './components/DriverCommission';
import FollowOnJob from './components/FollowOnJob';
import LoadingTitle from '@/components/loaders/LoadingTitle';
import PaymentStatus from './components/PaymentStatus.vue';
import RatePassenger from './components/RatePassenger';

export default {
    name: 'RideSubmit',
    props: {
        booking: {
            type: Object,
        },
        paymentLinkSent: {
            type: Boolean,
        },
    },
    components: {
        DriverCommission,
        FollowOnJob,
        LoadingTitle,
        RatePassenger,
        PaymentStatus,
    },
    data() {
        return {
            loadingUpcomingBooking: false,
            upcomingBooking: null,
        };
    },
    methods: {
        async fetchFollowOnJob() {
            try {
                this.loadingUpcomingBooking = true;
                bus.$emit('disallowExit', true);
                this.upcomingBooking = await getFollowOnBooking();
                if (!this.upcomingBooking) {
                    bus.$emit('disallowExit', false);
                }
                this.loadingUpcomingBooking = false;
            } catch (err) {
                this.loadingUpcomingBooking = false;
                bus.$emit('disallowExit', false);
                showErrorToast(err, 'There was an error fetching follow-on job.');
            }
        },
    },
    async mounted() {
        await this.fetchFollowOnJob();
    },
};
</script>

<style scoped></style>
