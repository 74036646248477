import {post, query} from '@/services/ApiService';

export const requestCallFromOffice = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post(`driver-api/call-request/?bookingId=${bookingId}`);
            resolve(result.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const getQueuePosition = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await query('driver-api/call-request/queue-position');
            resolve(result.data);
        } catch (err) {
            reject(err);
        }
    });
};
