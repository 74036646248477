import {handle401Error} from '@/utils/sentryErrors';
import axios from 'axios';
import VueCookies from 'vue-cookies';

let $http;
export const init = () => {
    $http = axios.create({
        baseURL: window.endpoint + '/',
    });
    let token = VueCookies.get('token');
    $http.defaults.headers.common['Content-Type'] = 'application/json';
    $http.defaults.headers.get['Accepts'] = 'application/json';

    $http.interceptors.request.use(
        config => {
            config.headers['Authorization'] = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    $http.interceptors.response.use(
        response => {
            return response;
        },
        async error => {
            if (error.response && error.response.status) {
                switch (error.response.status) {
                    case 401: {
                        VueCookies.remove('token');
                        await handle401Error(null, 'Driver token expired');
                        break;
                    }
                }
            }
            if (!error.response) {
                error.isNetworkError = true;
            }
            return Promise.reject(error.response || error);
        }
    );
    return $http;
};

export const query = (resource, params) => {
    return $http
        .get(`${resource}`, {
            params: params,
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export const get = (resource, id = '') => {
    return $http.get(`${resource}/${id}`).catch(error => {
        console.error(error);
        throw error;
    });
};

export const post = (resource, body, headers = undefined) => {
    return $http.post(`${resource}`, body, headers).catch(error => {
        console.error(error);
        throw error;
    });
};

export const update = (resource, id, params) => {
    return $http.put(`${resource}/${id}`, params).catch(error => {
        console.error(error);
        throw error;
    });
};

export const put = (resource, params, headers) => {
    return $http.put(`${resource}`, params, headers).catch(error => {
        console.error(error);
        throw error;
    });
};

export const remove = (resource, id) => {
    return $http.delete(id ? `${resource}/${id}` : resource).catch(error => {
        console.error(error);
        throw error;
    });
};

export {$http};
