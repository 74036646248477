var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Transition", { attrs: { name: "toast", duration: 400 } }, [
    _vm.message
      ? _c(
          "div",
          { staticClass: "px-5 py-3 flex justify-between", class: _vm.type },
          [
            _c("span", [
              _vm._v("\n            " + _vm._s(_vm.message) + "\n        "),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }