<template>
    <div class="rounded border border-gray-200 flex justify-center items-center gap-3 p-4 relative">
        <div class="absolute left-0 ml-2">
            <img v-if="booking.Passenger.ImageUrl" :src="booking.Passenger.ImageUrl" class="w-16 h-16 rounded-full" />
            <span v-else class="material-icons-outlined text-5xl text-center text-white rounded-full p-1 w-16 h-16" style="background: #9e9e9e">person</span>
        </div>
        <div class="flex flex-col items-center justify-center">
            <span class="font-semibold">{{ passengerName }}</span>
            <div>
                <span
                    class="material-icons-outlined text-4xl"
                    :class="{'text-yellow-400': highlight, 'text-gray-300': !highlight}"
                    v-for="(highlight, index) in starsHighlight"
                    :key="index"
                    @click="() => updateRating(index)">
                    star
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {ratePassenger} from '@/services/BookingService';
import {showErrorToast} from '../../../services/ToastService';

export default {
    name: 'RatePassenger',
    props: {
        booking: {
            type: Object,
        },
    },
    data() {
        return {
            rating: 0,
            starsHighlight: Array(5).fill(false),
        };
    },
    methods: {
        async updateRating(number) {
            const oldRating = this.rating;
            try {
                this.rating = number + 1;
                await ratePassenger(this.booking?.Id, this.rating);
            } catch (err) {
                this.rating = oldRating;
                showErrorToast(err, 'There was an error whilst rating the passenger');
            }
        },
    },
    computed: {
        passengerName() {
            const {Passenger} = this.booking;
            return `${Passenger.Firstname} ${Passenger.Surname}`;
        },
    },
    watch: {
        rating: {
            handler() {
                this.starsHighlight = [...Array(this.rating).fill(true), ...Array(5 - this.rating).fill(false)];
            },
        },
        'booking.PassengerScore': {
            immediate: true,
            handler() {
                if (this.booking.PassengerScore) {
                    this.rating = this.booking.PassengerScore;
                }
            },
        },
    },
};
</script>

<style scoped></style>
