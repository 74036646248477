var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Alert", {
    attrs: { show: _vm.showAlert, "show-close": true },
    on: { cancel: _vm.handleClose },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v("Add Extra")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("p", { staticClass: "block text-gray-500" }, [
              _vm._v(
                "Any extras added will need to be verified by the operations team."
              ),
            ]),
            _c(
              "form",
              [
                _c("div", { staticClass: "py-2 inline-block" }, [
                  _c(
                    "label",
                    {
                      staticClass: "block font-semibold",
                      attrs: { for: "expense-amount" },
                    },
                    [_vm._v("Amount")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-full bg-white flex items-center gap-2 p-2 my-1 border border-gray-200",
                    },
                    [
                      _c("action-button", {
                        attrs: {
                          icon: "remove",
                          "icon-color": "#1FD0A3",
                          color: "#fff",
                          action: _vm.decreaseAmount,
                          disabled: _vm.amount < 0.1,
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass:
                            "relative text-gray-600 dark:text-gray-100 text-xl w-24",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "px-1",
                              attrs: { "aria-hidden": "true" },
                            },
                            [_vm._v(_vm._s(_vm.amount))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.amount,
                                expression: "amount",
                              },
                            ],
                            staticClass:
                              "absolute w-full left-0 bg-white px-1 text-center outline-none",
                            attrs: {
                              name: "expense-amount",
                              id: "expense-amount",
                              type: "number",
                            },
                            domProps: { value: _vm.amount },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.amount = $event.target.value
                              },
                            },
                          }),
                        ]
                      ),
                      _c("action-button", {
                        attrs: {
                          icon: "add",
                          "icon-color": "#1FD0A3",
                          color: "#fff",
                          action: _vm.increaseAmount,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "relative" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "block font-semibold",
                        attrs: { for: "expense-types" },
                      },
                      [_vm._v("Select Type")]
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedType,
                            expression: "selectedType",
                          },
                        ],
                        staticClass: "form-input",
                        attrs: { name: "expense-types", id: "expense-types" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedType = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      _vm._l(_vm.expenseTypes, function (type) {
                        return _c(
                          "option",
                          { key: type.Id, domProps: { value: type.Id } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(type.Name) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm.loading
                      ? _c("LoaderIcon", {
                          staticClass: "absolute right-1 h-8 w-8 bottom-1",
                          attrs: { color: "#9e9e9e" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "mt-2 mb-4" }, [
                  _c(
                    "label",
                    {
                      staticClass: "block font-semibold",
                      attrs: { for: "expense-notes" },
                    },
                    [_vm._v("Notes")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.note,
                        expression: "note",
                      },
                    ],
                    staticClass: "form-input",
                    attrs: {
                      name: "expense-amount",
                      id: "expense-notes",
                      type: "text",
                      placeholder: "Optional",
                    },
                    domProps: { value: _vm.note },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.note = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("action-button", {
                  staticClass: "w-full mt-2",
                  attrs: {
                    text: "Add",
                    circular: false,
                    color: "#1FD0A3",
                    async: true,
                    action: _vm.addExpense,
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }