import {post, put, query} from '@/services/ApiService';
import axios from 'axios';

export const addBookingStop = (bookingId, bookingStop) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post('driver-api/booking/add-stop', {
                BookingId: bookingId,
                BookingStop: bookingStop,
            });
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const updateBookingStop = (bookingId, bookingStop) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put('driver-api/booking/edit-stop', {
                BookingId: bookingId,
                BookingStop: bookingStop,
            });
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const removeBookingStop = (bookingId, bookingStopId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put('driver-api/booking/remove-stop', {
                BookingId: bookingId,
                BookingStopId: bookingStopId,
            });
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const getBookingDropAddresses = (passengerId, clientId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await query('driver-api/booking-drop-addresses', {
                clientId,
                passengerId,
            });
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const getLocations = searchText => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await query('driver-api/location/search', {
                searchText,
            });
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const getCompleteAddress = placeId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await query('driver-api/location/google-places', {
                placeId,
            });
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const getDropLocation = (latitude, longitude) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${window.reversegeocodeendpoint}?lat=${latitude}&lon=${longitude}&format=json`);
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};
