//eslint-disable-next-line
import {put, query, post} from '@/services/ApiService';
import axios from 'axios';
import Vue from 'vue';

// get booking details
export const getBookingDetails = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await query(`driver-api/booking/details?bookingId=${bookingId}`);
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

// rate passenger on booking
export const ratePassenger = (bookingId, rating) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put(`driver-api/booking/rate-passenger?bookingId=${bookingId}&rating=${rating}`);
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const addCustomerSignature = (bookingId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(`${process.env.VUE_APP_CAB9ENDPOINT}/api/booking/passenger-signature?bookingId=${bookingId}`, data, {
                headers: {
                    Authorization: `bearer ${Vue.$cookies.get('token')}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const getFollowOnBooking = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await query('driver-api/follow-on-booking');
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const updateBookingAmount = (bookingId, amount) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put(`driver-api/booking/cost/?bookingId=${bookingId}&amount=${amount}`);
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const syncMeterUpdate = meterUpdate => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put('driver-api/booking/sync-meter-update', meterUpdate);
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const takeCardPayment = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post(`/driver-api/booking/take-card-payment/?bookingId=${bookingId}`);
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const takeCashPayment = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post(`/driver-api/booking/take-cash-payment/?bookingId=${bookingId}`);
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const sendPaymentLink = (bookingId, phoneNumber) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post(`/driver-api/booking/payment-link-sms/?bookingId=${bookingId}&phoneNumber=${phoneNumber}`);
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const getBookingTransactions = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await query(`/driver-api/booking/transactions/?bookingId=${bookingId}`);
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};
