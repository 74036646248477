var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex gap-1 px-2 pb-1 pt-2 overflow-scroll no-scrollbar w-full",
      },
      _vm._l(_vm.preWrittenMessages, function (msg, index) {
        return _c("PreWrittenMessage", {
          key: index,
          attrs: { text: msg },
          nativeOn: {
            click: function ($event) {
              return function () {
                return _vm.setPreWrittenMessage(msg)
              }.apply(null, arguments)
            },
          },
        })
      }),
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "flex bg-white shadow-lg px-3 py-2 items-center gap-2 border-t border-gray-100 rounded-t-2xl",
      },
      [
        _c("div", [
          _c("div", { staticClass: "emojis-wrapper" }, [
            _c(
              "div",
              { staticClass: "emojis-container" },
              [
                _c("emoji-picker", {
                  attrs: { search: _vm.search },
                  on: { emoji: _vm.append },
                  scopedSlots: _vm._u([
                    {
                      key: "emoji-invoker",
                      fn: function (ref) {
                        var clickEvent = ref.events.click
                        return _c(
                          "button",
                          {
                            staticClass: "icon-button flex items-center",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return clickEvent.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "material-icons-outlined" },
                              [_vm._v("sentiment_satisfied_alt")]
                            ),
                          ]
                        )
                      },
                    },
                    {
                      key: "emoji-picker",
                      fn: function (ref) {
                        var emojis = ref.emojis
                        var insert = ref.insert
                        return _c("div", {}, [
                          _c("div", { staticStyle: { position: "relative" } }, [
                            _c("div", { staticClass: "emoji-picker" }, [
                              _c("div", { staticClass: "form-group mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.search,
                                      expression: "search",
                                    },
                                  ],
                                  staticClass:
                                    "form-control border rounded-md px-1.5 py-1 w-full",
                                  attrs: {
                                    "aria-label": "emoji-picker",
                                    type: "text",
                                    placeholder: "Search",
                                  },
                                  domProps: { value: _vm.search },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.search = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                _vm._l(emojis, function (emojiGroup, category) {
                                  return _c("div", { key: category }, [
                                    _c("h5", [_vm._v(_vm._s(category))]),
                                    _c(
                                      "div",
                                      { staticClass: "emojis" },
                                      _vm._l(
                                        emojiGroup,
                                        function (emoji, emojiName) {
                                          return _c(
                                            "span",
                                            {
                                              key: emojiName,
                                              staticClass: "emoji",
                                              attrs: { title: emojiName },
                                              on: {
                                                click: function ($event) {
                                                  return insert(emoji)
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(emoji))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]),
                          ]),
                        ])
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "flex-1" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.content,
                expression: "content",
              },
            ],
            staticClass:
              "w-full border-b resize-none outline-none py-2 bg-transparent",
            attrs: { rows: "1", placeholder: "Type message" },
            domProps: { value: _vm.content },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.content = $event.target.value
              },
            },
          }),
        ]),
        _c("ActionButton", {
          staticClass: "send",
          attrs: {
            color: "#10B981",
            icon: "send",
            action: _vm.sendMessage,
            small: true,
            "icon-color": "white",
            async: true,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }