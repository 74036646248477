<template>
    <Alert :show="showAlert" :show-close="true" @cancel="handleClose">
        <template v-slot:title>Send Payment Link</template>
        <template v-slot:body>
            <form>
                <div class="flex flex-col text-gray-500">
                    <span v-if="booking.PassengerNotificationPhone">Please confirm the last three digits of the passenger's number or enter the number manually:</span>
                    <span v-else>Please enter the passenger's number to send SMS</span>
                </div>
                <div class="mt-2 mb-4">
                    <div v-if="!editMode" class="form-input flex">
                        <span class="flex-1 mt-1 font-semibold">{{ shownNumber }}</span>
                        <span class="material-icons-outlined" @click="editMode = true">edit</span>
                    </div>
                    <VueTelInput v-else v-model="phoneNumber" mode="international" class="form-input" :open="true" @validate="handleValidation">
                        <template v-slot:icon-right v-if="booking.PassengerNotificationPhone">
                            <span class="material-icons-outlined block mt-1" @click="editMode = false">close</span>
                        </template>
                    </VueTelInput>
                    <div v-if="phoneNumber && !isValidNumber" class="text-red-600 mt-1">
                        <span>Number provided is invalid</span>
                    </div>
                </div>
                <action-button :disabled="editMode && !isValidNumber" text="Send" :circular="false" color="#1FD0A3" :async="true" :action="sendLink" class="w-full mt-2" />
            </form>
        </template>
    </Alert>
</template>

<script>
import 'vue-tel-input/dist/vue-tel-input.css';
import {bus} from '@/main';
import {sendPaymentLink} from '@/services/BookingService';
import {showErrorDialog, showSuccessDialog} from '@/services/DialogService';
import {VueTelInput} from 'vue-tel-input';
import ActionButton from '@/components/ActionButton';
import Alert from '@/components/Alert';

export default {
    name: 'SendPaymentLink',
    props: {
        booking: {
            type: Object,
        },
        show: {
            type: Boolean,
        },
        completeBooking: {
            type: Boolean,
        },
    },
    components: {
        ActionButton,
        Alert,
        VueTelInput,
    },
    data() {
        return {
            showAlert: true,
            phoneNumber: '',
            editMode: false,
            isValidNumber: true,
        };
    },
    methods: {
        handleClose(linkSent = false) {
            this.showAlert = false;

            if (linkSent) {
                bus.$emit('PAYMENT_LINK_SENT');
            }

            setTimeout(() => {
                this.$emit('cancel');
            }, 400);
        },
        async sendLink() {
            try {
                const number = this.editMode ? this.phoneNumber : this.booking.PassengerNotificationPhone;
                await sendPaymentLink(this.booking.Id, number.replace(/\s/g, ''));
                if (this.completeBooking) {
                    this.showAlert = false;
                    const res = await showSuccessDialog('Payment link sent successfully.', 'Complete Booking');
                    if (res.isConfirmed) {
                        this.$emit('complete-booking');
                    }
                } else {
                    showSuccessDialog('Payment link sent successfully.', 'Confirm');
                }
                this.handleClose(true);
            } catch (err) {
                showErrorDialog(err, 'Something went wrong whilst sending payment link.', 'OK');
            }
        },
        handleValidation(val) {
            this.isValidNumber = val.valid;
        },
    },
    computed: {
        shownNumber: function () {
            let number = this.booking.PassengerNotificationPhone;
            if (!number) return '';
            number = number.replace(/\s/g, '');
            const length = number.length;
            const lastThree = number.substring(length - 3);
            return 'X'.repeat(10) + lastThree;
        },
    },
    mounted() {
        if (!this.booking.PassengerNotificationPhone) {
            this.editMode = true;
        }
    },
};
</script>

<style scoped lang="scss">
.form-input {
    @apply w-full p-1 h-10 px-2;
    border: solid 1px $light-grey;
    -webkit-appearance: none;
    border-radius: 4px;
}
</style>
