var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "span",
        {
          staticClass: "text-md font-medium flex gap-1 items-center mb-1",
          style: { color: "#9e9e9e" },
        },
        [_c("span", [_vm._v("DropOff")])]
      ),
      _c("ActionButton", {
        staticClass: "w-max shadow-md",
        attrs: {
          text: "Add DropOff",
          circular: false,
          icon: "add_location",
          color: "#18a480",
          action: _vm.openAddDrop,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }