<template>
    <div>
        <div v-if="person" class="w-full pb-1 px-4 flex items-start relative break-words overflow-hidden" :class="{'flex-row-reverse shift-right': isDriverSender}" @click="sendMessage">
            <div class="flex-1 overflow-hidden">
                <div :class="{'float-right driver-msg': isDriverSender, 'bg-white text-black dark:text-white': !isDriverSender}" class="chat-msg relative inline-block mt-1 rounded-xl shadow-sm">
                    <div class="content overflow-x-auto pt-2 pb-1 px-4 text-light-1 leading-snug lg:leading-normal" style="white-space: pre-line">{{ message.Body }}</div>
                    <div class="text-xs pb-1.5 px-4" :class="{'text-gray-200': isDriverSender, 'text-gray-500 text-left': !isDriverSender}">
                        {{ formatDate(message.DateTime) }}
                    </div>
                </div>
                <br class="clear-both" v-if="isDriverSender" />
                <div class="w-full flex justify-end py-0.5 cursor-pointer">
                    <div class="text-red-500 rounded-xl py-0.5 px-1 font-semibold text-sm flex items-center pr-2 gap-1" v-if="message.IsFailed">
                        <span class="material-icons-outlined text-base">error</span><span>Couldn't send, tap to retry</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {sendMessage} from '@/services/ChatService';
import {showErrorToast} from '@/services/ToastService';
import dayjs from 'dayjs';

export default {
    name: 'ChatMessage',
    props: {
        conversation: {
            type: Object,
            default: () => {},
        },
        message: {
            type: Object,
            required: true,
        },
        claims: {
            type: Object,
        },
    },
    data() {
        return {
            timezone: null,
        };
    },
    methods: {
        formatDate(val) {
            return dayjs(val)
                .tz(this.timezone || 'Europe/London')
                .format('HH:mm');
        },
        async sendMessage() {
            if (this.message.IsFailed) {
                this.message.IsFailed = false;
                try {
                    await sendMessage(this.message);
                    const index = this.messages.findIndex(msg => msg.RefId === this.message.RefId);
                    this.messages[index] = this.message;
                } catch (err) {
                    this.message.IsFailed = true;
                    showErrorToast(err, 'Something went wrong whilst sending message.');
                }
            }
        },
    },
    computed: {
        participantsMapping() {
            const map = {};
            if (this.conversation && this.conversation.Participants) {
                this.conversation.Participants.forEach(item => {
                    map[item.Id] = item;
                });
            }
            return map;
        },
        person() {
            if (this.participantsMapping[this.message.SenderId]) {
                return this.participantsMapping[this.message.SenderId];
            } else {
                return {
                    Name: 'Member',
                };
            }
        },
        isDriverSender() {
            return this.message.SenderId === this.claims.DriverId;
        },
    },
    created() {
        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        this.timezone = user.DefaultTimezone;
    },
};
</script>

<style lang="scss">
.chat-message {
    &.shift-right {
        margin-left: 15%;
        width: 85%;
    }
}
.content {
    font-size: 1rem;
    p {
        margin-bottom: 0;
    }
    a {
        color: $blue;
    }
    ul {
        li {
            list-style-type: circle;
            list-style-position: inside;
            p {
                display: inline-block;
            }
        }
    }
    ol {
        li {
            list-style-type: decimal;
            list-style-position: inside;
            p {
                display: inline-block;
            }
        }
    }
}

.driver-msg {
    background: #10b981;
    color: white;
}
.chat-msg {
    max-width: 80%;
}
</style>
