var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full flex flex-col" },
    [
      _c(
        "div",
        { staticClass: "flex items-center px-2" },
        [
          _c("action-button", {
            attrs: {
              icon: "west",
              color: "transparent",
              action: _vm.closeMenu,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-1 flex flex-col gap-0 p-4 overflow-scroll" },
        [
          _c("h2", [_vm._v("Ride Menu")]),
          _c("MenuItem", {
            attrs: { icon: "description", text: "Ride Details" },
            nativeOn: {
              click: function ($event) {
                return function () {
                  return _vm.open("ride-details")
                }.apply(null, arguments)
              },
            },
          }),
          _vm.booking.BookingSource === "APP" &&
          _vm.driverState.ShowPassengerChat
            ? _c("MenuItem", {
                attrs: { icon: "chat_bubble_outline", text: "Chat" },
                nativeOn: {
                  click: function ($event) {
                    return function () {
                      return _vm.open("chat")
                    }.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm.driverState.DriverConnect
            ? _c("MenuItem", {
                attrs: {
                  success: _vm.connectingPassenger,
                  icon: "call",
                  text: _vm.connectingPassenger
                    ? "Calling Passenger"
                    : "Passenger Connect",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.connectPassenger.apply(null, arguments)
                  },
                },
              })
            : [
                _c("MenuItem", {
                  attrs: { icon: "call", text: "Call Passenger" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.callPassenger.apply(null, arguments)
                    },
                  },
                }),
                _c("MenuItem", {
                  attrs: { icon: "textsms", text: "Text Passenger" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.textPassenger.apply(null, arguments)
                    },
                  },
                }),
              ],
          _c("MenuItem", {
            attrs: {
              icon: "call",
              text: _vm.booking._callRequested
                ? "Call From Office Requested"
                : "Request Call From Office",
              success: _vm.booking._callRequested,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.requestCall.apply(null, arguments)
              },
            },
          }),
          _vm.driverState.CanDriverSendPaymentLink &&
          _vm.booking.BookingStatus === "InProgress" &&
          !_vm.paymentCompleted &&
          _vm.booking.PaymentMethod !== "OnAccount" &&
          _vm.booking.PaymentMethod !== "Card"
            ? _c("MenuItem", {
                attrs: { icon: "textsms", text: "Send Payment Link" },
                nativeOn: {
                  click: function ($event) {
                    _vm.showPaymentLinkScreen = true
                  },
                },
              })
            : _vm._e(),
          _vm.driverState.CanDriverAmendStops &&
          (_vm.booking.BookingStatus === "Arrived" ||
            _vm.booking.BookingStatus === "InProgress")
            ? _c(
                "div",
                {},
                [
                  !_vm.booking.AsDirected
                    ? _c("MenuItem", {
                        attrs: {
                          icon: "add_location_alt",
                          text: "Add Via Stop",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return function () {
                              return _vm.open("add-via-stop")
                            }.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                  !_vm.booking.AsDirected && _vm.booking.BookingStops.length > 2
                    ? _c("MenuItem", {
                        attrs: {
                          icon: "wrong_location",
                          text: "Remove Via Stop",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return function () {
                              return _vm.open("remove-via-stop")
                            }.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.booking.AsDirected
                    ? _c("MenuItem", {
                        attrs: { icon: "add_location", text: "Add Dropoff" },
                        nativeOn: {
                          click: function ($event) {
                            return function () {
                              return _vm.open("add-drop")
                            }.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                  !_vm.booking.AsDirected
                    ? _c("MenuItem", {
                        attrs: { icon: "edit", text: "Amend Dropoff" },
                        nativeOn: {
                          click: function ($event) {
                            return function () {
                              return _vm.open("amend-drop")
                            }.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.followOnPaused
            ? _c("MenuItem", {
                attrs: {
                  icon: "play_circle",
                  text: "Resume Follow-on Jobs",
                  success: true,
                  disabled: _vm.loadingFollowOnState,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.resumeFollowOns.apply(null, arguments)
                  },
                },
              })
            : _c("MenuItem", {
                attrs: {
                  icon: "pause_circle",
                  text: "Pause Follow-on Jobs",
                  danger: true,
                  disabled: _vm.loadingFollowOnState,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.pauseFollowOns.apply(null, arguments)
                  },
                },
              }),
          _vm.driverState.CanDriverHandback &&
          (_vm.booking.BookingStatus === "Allocated" ||
            _vm.booking.BookingStatus === "OnRoute")
            ? _c("MenuItem", {
                attrs: {
                  icon: "block",
                  text: "Handback Booking",
                  danger: true,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.confirmHandBack.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm.driverState.CanDriverCOA &&
          _vm.booking.BookingStatus === "Arrived"
            ? _c("MenuItem", {
                attrs: {
                  icon: "person_off",
                  text: "Passenger No Show",
                  danger: true,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.confirmNoShow.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm.breakAfterJobEnabled
            ? _c("MenuItem", {
                attrs: {
                  icon: "timer",
                  text: "Cancel Break After Job",
                  danger: true,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.cancelBreak.apply(null, arguments)
                  },
                },
              })
            : _c("MenuItem", {
                attrs: {
                  icon: "timer",
                  text: "Go On Break After Job",
                  danger: true,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.enableBreak.apply(null, arguments)
                  },
                },
              }),
        ],
        2
      ),
      _vm.showPaymentLinkScreen
        ? _c("SendPaymentLink", {
            attrs: { booking: _vm.booking },
            on: {
              cancel: function ($event) {
                _vm.showPaymentLinkScreen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }