export const addParamsToLocation = params => {
    history.pushState(
        {},
        null,
        window.location.pathname +
            '?' +
            Object.keys(params)
                .map(key => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
                })
                .join('&')
    );
};

export const getParams = name => {
    const uri = window.location.search.substring(1);
    return new URLSearchParams(uri).get(name);
};

export const addCurrency = (value, num) => {
    if (num === undefined) {
        num = 2;
    }
    if (typeof value !== 'number') {
        return value;
    }
    let formatter = new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: num,
        maximumFractionDigits: num,
    });
    return formatter.format(value);
};

export function debounce(fn, delay) {
    let timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        const args = arguments;
        const that = this;
        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    };
}

export function throttle(fn, delay) {
    let oldTime;

    return function (...args) {
        const now = Date.now();

        if (!oldTime || now - oldTime > delay) {
            const result = fn.apply(this, args);
            oldTime = now;
            return result;
        }
    };
}

export const delay = async (delay = 1000, callback = () => {}) => {
    const delayPromise = ms => new Promise(res => setTimeout(res, ms));
    await delayPromise(delay);
    callback();
};

// Converts speed in mps (meters per second) to mph (miles per hour)
export const mpsToMph = value => {
    return value * 2.23694;
};

export const clearAllWaiting = booking => {
    localStorage.removeItem(`${booking.Id}-waiting`);
    localStorage.removeItem(`${booking.Id}-auto-waiting`);
};

// Calculates distance between two points in kilometers using the haversine formula
export const haversineDistance = (location1, location2) => {
    const toRadian = angle => (angle * Math.PI) / 180;
    const R = 6371; // in km

    const x1 = location2.latitude - location1.latitude;
    const dLat = toRadian(x1);

    const x2 = location2.longitude - location1.longitude;
    const dLon = toRadian(x2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadian(location1.latitude)) * Math.cos(toRadian(location2.latitude)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
};

export const scrollToLatest = (selector = '#message-list') => {
    setTimeout(() => {
        const container = document.querySelector(selector);
        if (container) {
            container.scrollTop = container.scrollHeight;
        }
    }, 0);
};
