<template>
    <div class="flex flex-col gap-2">
        <RidePayment :booking="booking" :app-data="appData" :use-local-meter="useLocalMeter" :local-meter="localMeter" />
    </div>
</template>

<script>
import RidePayment from './components/RidePayment';
export default {
    name: 'RideSettlement',
    props: {
        appData: {
            type: Object,
        },
        booking: {
            type: Object,
        },
        useLocalMeter: {
            type: Boolean,
        },
        localMeter: {
            type: Object,
        },
    },
    components: {
        RidePayment,
    },
};
</script>

<style scoped></style>
