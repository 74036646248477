var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col gap-2" },
    [
      _c("RidePayment", {
        attrs: {
          booking: _vm.booking,
          "app-data": _vm.appData,
          "use-local-meter": _vm.useLocalMeter,
          "local-meter": _vm.localMeter,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }