<template>
    <div class="font-semibold text-gray-400 mt-3 flex items-center gap-1">
        <div style="background: #9e9e9e" class="rounded-full pointer inline-block p-1">
            <img :src="waitAndReturnIcon" alt="" class="h-5 w-5" />
        </div>
        <span class="text-lg">This is a wait and return booking.</span>
    </div>
</template>

<script>
export default {
    name: 'WaitAndReturn',
    data() {
        return {
            waitAndReturnIcon: require('@/assets/images/icons/wait-and-return.svg'),
        };
    },
};
</script>
