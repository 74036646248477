var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-full w-full bg-gray-300 opacity-50 flex items-center justify-center fixed loader-overlay",
    },
    [_c("LoaderIcon", { staticClass: "h-16", attrs: { color: "#3d3d3d" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }