<template>
    <div class="flex justify-end items-start py-1 text-md">
        <label class="detail-label flex-1 font-bold w-auto pr-4 text-gray-500" v-if="label && label.length > 0">{{ label }}</label>
        <span class="detail-value font-semibold pl-4 text-right">{{ value ? value : '-' }}</span>
        <span v-if="additionalInfo" class="additional-info w-20 font-semibold text-right">{{ additionalInfo }}</span>
    </div>
</template>

<script>
export default {
    name: 'InfoLabel',
    props: {
        label: {
            type: String,
            default: 'Label',
        },
        value: {
            default: '-',
        },
        additionalInfo: {
            type: String,
        },
    },
};
</script>
