<template>
    <div>
        <template v-if="takeSignature">
            <span v-if="error" class="font-semibold text-red-500">{{ error }}</span>
            <div class="border-b border-gray-200 h-72 rounded bg-gray-100">
                <VueSignaturePad ref="signaturePad" class="signature-pad" />
            </div>
            <div class="flex flex-col items-center my-2 gap-1">
                <span class="font-semibold">Please sign here</span>
                <span class="text-center text-sm text-gray-400">By signing above you agree to our all ride charges and terms and conditions.</span>
            </div>
        </template>
        <div v-else>
            <img :src="signatureUrl || booking.PassengerSignatureImageUrl" class="w-full p-2 bg-gray-100" />
        </div>
        <div class="flex gap-1 my-2">
            <ActionButton v-if="takeSignature" text="Reset" :circular="false" :color="'#9e9e9e'" class="flex-1" :action="reset" />
            <ActionButton v-else text="Cancel" :circular="false" :color="'#9e9e9e'" class="flex-1" :action="cancel" />
            <ActionButton v-if="takeSignature" text="Save" :circular="false" color="#1FD0A3" :async="true" class="flex-1" :action="save" />
            <ActionButton v-else text="Change" :circular="false" color="#1FD0A3" :async="true" class="flex-1" :action="() => (takeSignature = true)" />
        </div>
    </div>
</template>

<script>
import {addCustomerSignature} from '@/services/BookingService.js';
import {bus} from '@/main';
import ActionButton from '@/components/ActionButton';

export default {
    name: 'SignaturePad',
    props: {
        booking: {
            type: Object,
        },
    },
    components: {
        ActionButton,
    },
    data() {
        return {
            error: null,
            takeSignature: true,
            signatureUrl: null,
        };
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        async save() {
            try {
                this.error = null;

                // check if the canvas is empty
                const {signaturePad} = this.$refs;
                if (signaturePad.isEmpty()) {
                    this.error = 'Cannot upload blank signature!';
                    return;
                }
                // get blob from canvas
                const signaturePadCanvas = document.querySelector('.signature-pad canvas');
                const blob = await new Promise(resolve => {
                    signaturePadCanvas.toBlob(resolve, 'image/png');
                });

                // convert blob to file and add to form data
                const formData = new FormData();
                const file = new File([blob], 'image.png', {type: 'image/png'});
                formData.append('file', file);

                // upload customer signature
                await addCustomerSignature(this.booking.Id, formData);

                this.signatureUrl = URL.createObjectURL(blob);
                this.takeSignature = false;
                this.$emit('uploaded');
                bus.$emit('fetchUpdatedBooking');
            } catch {
                this.error = 'There was an error uploading the signature.';
            }
        },
        reset() {
            this.$refs.signaturePad.clearSignature();
        },
    },
    created() {
        this.takeSignature = !this.booking.PassengerSignatureImageUrl;
    },
};
</script>

<style scoped lang="scss"></style>
