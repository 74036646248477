import {post, query, remove} from '@/services/ApiService';

// remove expense from a booking
export const removeExpense = expenseId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await remove(`driver-api/booking/delete-expense?expenseId=${expenseId}`);
            resolve(result);
        } catch (err) {
            reject(err);
        }
    });
};

// add expense to a booking
export const addExpense = (bookingId, {expenseId, expenseName, note, driverAmount}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post(`driver-api/booking/add-expense?bookingId=${bookingId}`, {
                ExpenseId: expenseId,
                ExepnseName: expenseName,
                Note: note,
                DriverAmount: driverAmount,
            });
            resolve(result);
        } catch (err) {
            reject(err);
        }
    });
};

// get expenses for booking
export const getBookingExpenses = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await query(`driver-api/booking/expenses?bookingId=${bookingId}`);
            resolve(result.data);
        } catch (err) {
            reject(err);
        }
    });
};

// get expense types available for the tenant
export const getExpenseTypes = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await query('driver-api/booking/expense-types');
            resolve(result.data);
        } catch (err) {
            reject(err);
        }
    });
};
