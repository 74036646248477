import {bus} from '@/main';

export const arrivalWaitingMixin = {
    data() {
        return {
            isArrivalTimerRunning: false,
            scheduledArrivalTimer: null,
        };
    },
    methods: {
        initiateArrivalWaiting() {
            if (this.isArrivalTimerRunning || this.booking.BookingStatus !== 'Arrived') return;

            const currentTime = new Date();
            const bookedDateTime = new Date(this.booking.BookedDateTimeBST);
            const arrivedDateTime = new Date(this.booking.DriverArrivedDateTimeBST);

            if (this.booking.BookedDateTimeBST && bookedDateTime > currentTime) {
                // schedule timer to start after booked date time
                const remainingTime = bookedDateTime - currentTime;
                if (this.scheduledArrivalTimer) return;
                this.scheduledArrivalTimer = setTimeout(() => {
                    bus.$emit('bookingLocalWaitingUpdate', -this.booking.WaitingTimeInSeconds);
                    bus.$emit('startAutoTimer');
                    this.isArrivalTimerRunning = true;
                    this.scheduledArrivalTimer = null;
                }, remainingTime);
            } else if (this.booking.DriverArrivedDateTimeBST && arrivedDateTime < currentTime) {
                const secondsElapsed = Math.floor((currentTime - arrivedDateTime) / 1000);
                bus.$emit('bookingLocalWaitingUpdate', -this.booking.WaitingTimeInSeconds + secondsElapsed);
                bus.$emit('startAutoTimer');
                this.isArrivalTimerRunning = true;
            } else {
                bus.$emit('bookingLocalWaitingUpdate', -this.booking.WaitingTimeInSeconds);
                bus.$emit('startAutoTimer');
                this.isArrivalTimerRunning = true;
            }
        },
        clearArrivalWaiting(clearLocalWaiting = true) {
            if (this.isArrivalTimerRunning) {
                bus.$emit('stopAutoTimer');
                if (clearLocalWaiting) bus.$emit('clearLocalWaiting');
                this.isArrivalTimerRunning = false;
            }
            if (this.scheduledArrivalTimer) {
                clearTimeout(this.scheduledArrivalTimer);
                this.scheduledArrivalTimer = null;
            }
        },
    },
    created() {
        bus.$on('clearArrivalWaiting', this.clearArrivalWaiting);
    },
    destroyed() {
        if (this.scheduledArrivalTimer) clearTimeout(this.scheduledArrivalTimer);

        bus.$off('clearArrivalWaiting', this.clearArrivalWaiting);
    },
};
