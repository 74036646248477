var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full relative select-none" },
    [
      _vm.booking
        ? _c("BookingWrapper", {
            attrs: {
              booking: _vm.booking,
              "use-local-meter": _vm.useLocalMeter,
              "local-meter": _vm.localMeter,
            },
          })
        : _c(
            "div",
            {
              staticClass:
                "flex align-middle h-screen items-center text-center p-4",
            },
            [
              _c(
                "div",
                {
                  staticClass: "w-full text-center flex flex-col items-center",
                },
                [
                  _c("img", {
                    staticClass: "w-24 border border-gray-100 rounded-xl mb-4",
                    attrs: { src: require("@/assets/images/icons/icon.jpg") },
                  }),
                  _c("h2", { staticClass: "mb-2 text-gray-400" }, [
                    _vm._v("Cab9 Go"),
                  ]),
                  _c("p", [
                    _vm._v(
                      "There was an error loading the booking details. Please close this page and try again."
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      on: {
                        click: function ($event) {
                          return _vm.closePage()
                        },
                      },
                    },
                    [_vm._v("Close Page")]
                  ),
                ]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }