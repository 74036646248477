var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-2 overflow-y-auto scrolling-touch no-scrollbar" },
    [
      _vm.conversation
        ? _c(
            "div",
            [
              _c(
                "infinite-loading",
                {
                  attrs: { direction: "top", identifier: _vm.reloadKey },
                  on: { infinite: _vm.infiniteMessagesHandler },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "spinner" }, slot: "spinner" },
                    [
                      _c("Loader", {
                        attrs: { size: "80px", text: "Fetching messages..." },
                      }),
                    ],
                    1
                  ),
                  _c("div", {
                    staticClass: "text-center",
                    attrs: { slot: "no-more" },
                    slot: "no-more",
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "text-center",
                      attrs: { slot: "no-results" },
                      slot: "no-results",
                    },
                    [
                      _vm._v(
                        "This is the very beginning of your conversation. "
                      ),
                      _c("br"),
                      _vm._v("Say Hi! 😀"),
                    ]
                  ),
                ]
              ),
              _vm._l(_vm.messages, function (msg) {
                return _c(
                  "div",
                  { key: msg._id },
                  [
                    _c("ChatMessage", {
                      attrs: {
                        message: msg,
                        "get-participants-mapping": _vm.participantsMapping,
                        conversation: _vm.conversation,
                        claims: _vm.claims,
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _c("Loader", {
            attrs: { size: "80px", text: "Initialising please wait..." },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }