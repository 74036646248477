<template>
    <div class="flex flex-col gap-2">
        <div v-if="showCardPaymentCollected" class="py-4 box flex flex-col gap-4">
            <div class="flex justify-between items-end">
                <div class="">
                    <InfoLabel value="Card" label="Payment Method" size="text-4xl"></InfoLabel>
                </div>
                <ActionButton :icon="'more_horiz'" :action="openRideDetails" :color="'#9e9e9e'" :icon-color="'#fff'"></ActionButton>
            </div>
            <div>
                <span v-if="booking.PaymentCollectionMode === 'Completion'" class="text-green-500 font-medium text-xl">Payment for this journey will be collected via credit card.</span>
                <span v-else class="text-green-500 font-medium text-xl">Payment for this journey has been successfully collected via credit card.</span>
            </div>
        </div>
        <div class="box" v-else>
            <!--PaymentTotal/PaymentMethod-->
            <div class="flex justify-between items-end">
                <div>
                    <InfoLabel v-if="isExternalMeterBooking" :value="booking.PaymentMethod" label="Payment Method" size="text-4xl mt-1"></InfoLabel>
                    <div class="w-16 py-2" v-else-if="localPriceLoader">
                        <LoaderIcon class="h-16 w-16" color="#10b981" spin-duration="1s" />
                    </div>
                    <InfoLabel
                        :value="editedTotal | currency"
                        :label="booking.PaymentMethod === 'Card' ? 'Prepaid/Card' : 'Total Fare'"
                        size="text-6xl"
                        v-else-if="booking.FlagDown && driverState.CanDriverAmendPriceOnFlagDown"></InfoLabel>
                    <InfoLabel value="On Account" label="Payment" v-else-if="booking.PaymentMethod === 'OnAccount' || booking._grandTotal < 0" size="text-4xl"></InfoLabel>
                    <InfoLabel :value="booking._grandTotal | currency" :label="booking.PaymentMethod === 'Card' ? 'Prepaid/Card' : 'Total Fare'" size="text-6xl" v-else></InfoLabel>
                </div>
                <ActionButton :icon="'more_horiz'" :action="openRideDetails" :color="'#9e9e9e'" :icon-color="'#fff'"></ActionButton>
            </div>
            <div v-if="booking.PaymentMethod === 'Card'" class="mt-2 text-green-500 font-semibold text-xl">
                <span>The payment for this booking has been made via a credit card.</span>
            </div>
            <!--Cost Breakdown (Non account bookings)-->
            <div class="mt-6" v-if="(booking.PaymentMethod !== 'OnAccount' && booking._grandTotal >= 0) || isExternalMeterBooking">
                <!--Base Cost-->
                <div v-if="allowPriceEdit" class="flex gap-2 items-center justify-between">
                    <span class="text-md font-bold text-gray-500">Journey Cost</span>
                    <div class="flex border border-gray-200 bg-white text-3xl rounded focus-within:border-blue-500 focus-within:border-2">
                        <span class="bg-gray-300 px-2 py-1 text-gray-700 font-medium">£</span>
                        <input type="number" v-model="editedAmount" class="px-1 w-32 text-gray-700 dark:text-white font-semibold focus:outline-none text-right px-2 py-1" ref="priceInput" />
                    </div>
                </div>
                <Detail :value="journeyCostDisplayed | currency" label="Journey Cost" v-else />

                <!-- Waiting Cost-->
                <template v-if="!driverState.HideWaitingCost">
                    <template v-if="useLocalMeter">
                        <Detail :value="localMeter.WaitingTime | timeDuration(false)" label="Waiting Time" :additional-info="localMeter.WaitingCost | currency" />
                        <Detail :value="0 | currency" label="Waiting Tax" />
                    </template>
                    <template v-else>
                        <Detail :value="booking.WaitingTimeInSeconds | timeDuration(false)" label="Waiting Time" :additional-info="booking.WaitingTotal | currency" />
                        <Detail :value="booking.WaitingTax | currency" label="Waiting Tax" />
                    </template>
                </template>

                <!--Tax Amount-->
                <Detail v-if="useLocalMeter" :value="localMeter.TaxAmount | currency" label="Tax Amount" />
                <Detail v-else-if="booking.TaxAmount || booking.TaxAmount === 0" :value="booking.TaxAmount | currency" label="Tax Amount" />

                <!--Adjustments-->
                <div v-if="booking.Adjustments && booking.Adjustments.length > 0" class="mt-1 pt-2 border-gray-200 border-t">
                    <InfoLabel value="" label="Ride Charges" class="mb-1"></InfoLabel>
                    <Detail v-for="adjustment in booking.Adjustments" :key="adjustment.Id" :value="(adjustment.Amount + adjustment.TaxAmount) | currency" :label="adjustment.Name" />
                </div>

                <!--Expenses-->
                <div v-if="fetchingExpenses">
                    <LoadingTitle title="Fetching Extras..." class="border-t border-gray-200 pl-0" />
                </div>
                <div v-else-if="fetchExpensesError" class="pt-6 pb-2 px-2 text-center text-gray-400">Error fetching extras</div>
                <div class="mt-1 pt-2 border-gray-200 border-t" v-else>
                    <div class="flex items-center gap-1 justify-between mb-2">
                        <InfoLabel value="" label="Ride Extras"></InfoLabel>
                        <ActionButton :icon="'add'" :action="addExpense" :color="'#ff8907'" :icon-color="'#fff'"></ActionButton>
                    </div>
                    <div v-for="cost in expenses" :key="cost.label" class="grid grid-cols-2 py-1">
                        <label class="font-bold w-auto pr-4 text-gray-500 col-span-1">{{ cost.label }}</label>
                        <div class="col-span-1 flex justify-between">
                            <span class="w-20 font-semibold" :class="cost.textColor">{{ cost.status }}</span>
                            <span class="font-semibold pl-4 text-right">{{ cost.value | currency }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AddExpense v-if="showExpenseDialog" :booking="booking" @cancel="hideExpenseModal" />

        <!--Payment Options (SumUp/Cash/PaymentLink)-->
        <ActionButton
            v-if="!paymentCompleted && showRidePayment && hasSumup && booking.PaymentMethod !== 'OnAccount'"
            :circular="false"
            icon="credit_card"
            text="Card Terminal Payment"
            :color="'#3063E9'"
            :action="initiatePayment"
            :disabled="disablePayment"
            :async="true" />
        <ActionButton
            v-if="driverState.CanDriverSendPaymentLink && !paymentCompleted && booking.PaymentMethod !== 'OnAccount' && booking.PaymentMethod !== 'Card'"
            :circular="false"
            icon="textsms"
            text="Send Payment Link"
            :color="'#007BA7'"
            :action="() => (showPaymentLinkScreen = true)" />
        <ActionButton v-if="booking.SignatureRequired" :circular="false" icon="draw" text="Passenger Signature" :color="'#059669'" :action="() => (showSignatureScreen = true)" />
        <SendPaymentLink v-if="showPaymentLinkScreen" :booking="booking" @cancel="showPaymentLinkScreen = false" @complete-booking="completeBooking" :complete-booking="true" />
        <CustomerSignature v-if="showSignatureScreen" :booking="booking" @cancel="showSignatureScreen = false" />
    </div>
</template>

<script>
import {bookingExpensesMixin} from '@/mixins/bookingExpensesMixin.js';
import {bus} from '@/main';
import {flagdownMixin} from '@/mixins/flagdownMixin.js';
import {initiatePayment} from '@/services/PostMessageService';
import {recheckMobileState} from '@/services/PostMessageService';
import {setBookingComplete} from '@/services/StatusService.js';
import {showErrorDialog, showSuccessDialog, showWarningDialog} from '@/services/DialogService.js';
import {updateBookingAmount} from '@/services/BookingService.js';
import ActionButton from '@/components/ActionButton';
import AddExpense from '@/components/AddExpense';
import CustomerSignature from './CustomerSignature.vue';
import Detail from '@/components/Detail';
import InfoLabel from '@/components/InfoLabel';
import LoaderIcon from '@/components/loaders/LoaderIcon.vue';
import LoadingTitle from '@/components/loaders/LoadingTitle.vue';
import SendPaymentLink from '../../SendPaymentLink.vue';

export default {
    name: 'RidePayment',
    props: {
        appData: {
            type: Object,
        },
        booking: {
            type: Object,
        },
        useLocalMeter: {
            type: Boolean,
        },
        localMeter: {
            type: Object,
        },
    },
    components: {
        ActionButton,
        AddExpense,
        Detail,
        InfoLabel,
        LoadingTitle,
        LoaderIcon,
        SendPaymentLink,
        CustomerSignature,
    },
    mixins: [bookingExpensesMixin, flagdownMixin],
    data() {
        return {
            disablePayment: false,
            showExpenseDialog: false,
            showRideDetails: false,
            showRidePayment: true,
            editedAmount: null,
            showPaymentLinkScreen: false,
            showSignatureScreen: false,
            completionPaymentSuccessful: false,
            localPriceLoader: false,
        };
    },
    methods: {
        addExpense() {
            this.showExpenseDialog = true;
        },
        hideExpenseModal() {
            this.showExpenseDialog = false;
        },
        async completeBooking() {
            try {
                bus.$emit('loading', true);
                await setBookingComplete(this.booking.Id);
                bus.$emit('fetchUpdatedBooking');
                bus.$emit('bookingCompleted');
            } catch (err) {
                bus.$emit('loading', false);
                showErrorDialog(err, 'There was an error whilst trying to complete the booking.', 'Try Again');
                recheckMobileState();
            }
        },
        async initiatePayment() {
            try {
                if (this.allowPriceEdit) {
                    if (this.editedAmount == null && this.isExternalMeterBooking) {
                        showErrorDialog(null, 'Please update the journey cost before taking the payment.', 'Confirm');
                        return;
                    }

                    if (this.priceUpdateRequired) {
                        let estimateAmount = this.useLocalMeter ? this.localMeter.MeterCost : this.booking.ActualCost;
                        estimateAmount = estimateAmount ? estimateAmount * 2 : 200;
                        if (this.editedAmount > 100 && this.editedAmount > estimateAmount) {
                            showErrorDialog(
                                null,
                                'The amount exceeds the billing limits, please verify and check again. If you believe this is in error, please contact office.',
                                'Confirm',
                                'Amount Exceeds Limits'
                            );
                            return;
                        }
                        await updateBookingAmount(this.booking.Id, Number(this.editedAmount));
                    }
                    initiatePayment(this.booking, this.editedTotal);
                } else {
                    initiatePayment(this.booking, this.booking._grandTotal);
                }
            } catch (err) {
                showErrorDialog(err, 'There was an error whilst trying to update the amount.', 'Try Again');
            }
        },
        openRideDetails() {
            bus.$emit('openDetails');
        },
        async handlePaymentStatusUpdate(appData) {
            const {status} = appData.data || {};

            switch (status) {
                case 'SUCCESSFUL':
                    showSuccessDialog('Payment completed successfully.', 'OK');
                    this.showRidePayment = false;
                    this.completeBooking();
                    break;
                case 'FAILED':
                    await showErrorDialog(null, appData.data?.message || 'There was a payment error. Please try again.', 'OK');
                    break;
                case 'PENDING':
                    await showWarningDialog('Pending', appData.data?.message || 'Payment pending.', 'OK');
                    this.disablePayment = true;
                    break;
                case 'CANCELLED':
                    await showErrorDialog(null, appData.data?.message || 'Payment was cancelled.', 'OK');
            }
        },
        setPriceLoader(val) {
            this.localPriceLoader = val;
        },
        focusPriceInput() {
            try {
                if (this.allowPriceEdit) {
                    this.$nextTick(() => {
                        if (this.$refs.priceInput) {
                            this.$refs.priceInput.focus();
                        }
                    });
                }
            } catch (err) {
                console.log(err);
            }
        },
    },
    computed: {
        showCardPaymentCollected() {
            if (this.booking.PaymentMethod === 'Card') {
                if (this.paymentCompleted) return true;
                if (this.isExternalMeterBooking) return false; // different UI shown for external meters
                if (this.booking.PaymentCollectionMode !== 'Completion') return true;
                if (!this.driverState.ShowCardBookingsAmount) return true;
            }

            return false;
        },
        paymentCompleted() {
            return (this.booking?.CardPaymentStatus && this.booking?.CardPaymentStatus.toLowerCase() === 'success') || this.completionPaymentSuccessful;
        },
        hasSumup() {
            if (this.driverState.SumupMode === 'None') return false;
            if (this.driverState.SumupMode === 'Driver') return true;
            if (this.driverState.SumupMode === 'Company' && this.driverState._showSumup) return true;
            return false;
        },
        journeyCostDisplayed() {
            let journeyCost;
            if (this.useLocalMeter) {
                journeyCost = Number(this.localMeter.MeterCost);
            } else if (this.booking.PricingMode === 'ActualRoute' || this.booking.AsDirected) {
                journeyCost = Number(this.booking.MeterCost || this.booking.ActualCost);
            } else {
                journeyCost = Number(this.booking.ActualCost);
            }
            if (this.driverState.HideWaitingCost) {
                if (this.useLocalMeter) {
                    journeyCost += Number(this.localMeter.WaitingCost);
                } else {
                    journeyCost += Number(this.booking.WaitingTotal);
                }
            }

            return journeyCost;
        },
    },
    watch: {
        editedAmount(amount) {
            bus.$emit('amountEdited', Number(amount));
        },
        booking: {
            handler(value) {
                this.setExpenses(value);
            },
        },
    },
    created() {
        bus.$on('setPriceLoader', this.setPriceLoader);
    },
    mounted() {
        bus.$on('sumUp', this.handlePaymentStatusUpdate);
        bus.$on('cardPaymentCompleted', () => {
            this.completionPaymentSuccessful = true;
        });
        this.setExpenses(this.booking);
        this.focusPriceInput();
    },
    destroyed() {
        bus.$off('sumUp');
        bus.$off('cardPaymentCompleted');
        bus.$off('setPriceLoader', this.setPriceLoader);
    },
};
</script>
