var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      { staticClass: "relative" },
      [
        _c(
          "div",
          { staticClass: "flex flex-col" },
          [
            _vm.displayedPaymentMethod
              ? _c("InfoLabel", {
                  staticClass: "mb-2",
                  attrs: {
                    label: "Payment",
                    value: _vm.displayedPaymentMethod,
                    size: "text-3xl",
                  },
                })
              : [
                  _vm.updatingPrice || _vm.localPriceLoader
                    ? _c("div", [
                        _c(
                          "label",
                          {
                            staticClass: "font-medium",
                            style: { color: "#9e9e9e" },
                          },
                          [_vm._v(_vm._s(_vm.journeyTotalLabel))]
                        ),
                        _c(
                          "div",
                          { staticClass: "w-16 py-2" },
                          [
                            _c("LoaderIcon", {
                              staticClass: "h-16 w-16",
                              attrs: {
                                color: "#10b981",
                                "spin-duration": "1s",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _c("InfoLabel", {
                        attrs: {
                          label: _vm.journeyTotalLabel,
                          value: _vm._f("currency")(_vm.journeyTotal),
                          size: "text-7xl",
                        },
                      }),
                ],
            _vm.partialPaymentsActive
              ? _c("span", { staticClass: "text-red-500 font-semibold" }, [
                  _vm._v("Partial Payments Active"),
                ])
              : _vm._e(),
            _vm.voucherApplied
              ? _c(
                  "span",
                  { staticClass: "text-green-500 font-semibold mt-0.5" },
                  [_vm._v("Voucher Applied")]
                )
              : _vm._e(),
            !_vm.displayedPaymentMethod && _vm.booking.PaymentMethod === "Card"
              ? _c(
                  "span",
                  {
                    staticClass: "text-green-500 font-semibold mt-0.5 text-xl",
                  },
                  [_vm._v("Prepaid Card")]
                )
              : _vm._e(),
            _vm.booking.BookingStatus === "InProgress" &&
            _vm.booking.PricingMode === "ActualRoute"
              ? _c(
                  "span",
                  {
                    staticClass: "font-semibold mb-0",
                    class: _vm.booking.MeterPaused
                      ? "text-red-500 pulse"
                      : "text-yellow-500",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.booking.MeterPaused
                          ? "Meter Paused"
                          : "Meter Running"
                      )
                    ),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "flex justify-between items-center" }, [
              _c(
                "div",
                { staticClass: "flex flex-col" },
                [
                  _vm.useLocalMeter
                    ? _c("span", [
                        _vm._v(
                          "Actual Distance: " +
                            _vm._s(
                              (_vm.localMeter &&
                                _vm.localMeter.ActualDistance) ||
                                0
                            ) +
                            " mi"
                        ),
                      ])
                    : _vm.booking.AsDirected ||
                      _vm.booking.PricingMode === "ActualRoute"
                    ? [
                        _vm.booking.BookingStatus === "OnRoute"
                          ? _c("span", { staticClass: "text-sm" }, [
                              _vm._v(
                                " Estimated Distance: " +
                                  _vm._s(_vm.booking.EstimatedDistance) +
                                  " mi "
                              ),
                            ])
                          : _c("span", { staticClass: "text-sm" }, [
                              _vm._v(
                                " Actual Distance: " +
                                  _vm._s(_vm.booking.ActualDistance || 0) +
                                  " mi "
                              ),
                            ]),
                      ]
                    : _vm._e(),
                  _vm.booking.PaymentMethod !== "OnAccount"
                    ? [
                        _vm.useLocalMeter
                          ? _c("strong", { staticClass: "text-lg" }, [
                              _vm._v(
                                "Includes Waiting: " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.localMeter.WaitingCost || 0
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("strong", { staticClass: "text-lg" }, [
                              _vm._v(
                                "Includes Waiting: " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.booking.WaitingTotal +
                                        (_vm.booking.WaitingTax || 0)
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ],
          2
        ),
        _c("Speed"),
        _vm.booking.BookingStatus === "InProgress" &&
        _vm.booking.PricingMode === "ActualRoute"
          ? _c(
              "div",
              { staticClass: "pause-journey absolute bottom-0 right-0" },
              [
                _c("ActionButton", {
                  attrs: {
                    async: true,
                    icon: _vm.booking.MeterPaused ? "play_circle" : "pause",
                    action: _vm.booking.MeterPaused
                      ? _vm.resumeMeter
                      : _vm.pauseMeter,
                    color: _vm.booking.MeterPaused ? "#12c343" : "#9e9e9e",
                    "icon-color": "#fff",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }