import {post, query} from '@/services/ApiService';

export const getConversationDetails = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await query('driver-api/passenger-chat/conversation-details', {
                bookingId,
            });
            resolve(result.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const getMessages = (conversationId, lastMessageId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await query('driver-api/passenger-chat/messages', {
                conversationId,
                lastMessageId,
            });
            resolve(result.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const sendMessage = message => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post('driver-api/passenger-chat/message', {
                message,
            });
            resolve(result.data);
        } catch (err) {
            reject(err);
        }
    });
};
