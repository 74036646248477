import * as Sentry from '@sentry/vue';
import {throttle} from './helper';

class AuthorizationError extends Error {
    constructor(msg, stack) {
        super(msg);
        this.name = 'AuthorizationError';
        if (stack) this.stack = stack;
    }
}

class InvalidBookingError extends Error {
    constructor(msg, stack) {
        super(msg);
        this.name = 'InvalidBookingError';
        if (stack) this.stack = stack;
    }
}

// class MeterPriceError extends Error {
//     constructor(msg, stack) {
//         super(msg);
//         this.name = 'MeterPriceError';
//         if (stack) this.stack = stack;
//     }
// }

class LocationsStoringError extends Error {
    constructor(msg, stack) {
        super(msg);
        this.name = 'LocationsStoringError';
        if (stack) this.stack = stack;
    }
}

class DoublePaymentError extends Error {
    constructor(msg, stack) {
        super(msg);
        this.name = 'DoublePaymentError';
        if (stack) this.stack = stack;
    }
}

export const handlePageLoadError = async err => {
    await Sentry.captureException(err);
    window.location.href = '/PageLoadError.html';
};

export const handle401Error = async (err, errMessage) => {
    const error = err ? new AuthorizationError(err.message, err.stack) : new AuthorizationError(errMessage);
    await Sentry.captureException(error);
    window.location.href = '/401.html';
};

export const handleInvalidBookingError = async (err, errMessage) => {
    if (err || errMessage) {
        const error = err ? new InvalidBookingError(err.message, err.stack) : new InvalidBookingError(errMessage);
        await Sentry.captureException(error);
    }
    window.location.href = '/InvalidBooking.html';
};

export const handleLocationsStoringError = async (err, errMessage) => {
    const error = err ? new LocationsStoringError(err.message, err.stack) : new LocationsStoringError(errMessage);
    await Sentry.captureException(error);
};

export const handleMeterPriceError = throttle(async errMessage => {
    console.debug(errMessage);
    // const error = new MeterPriceError(errMessage);
    // await Sentry.captureException(error);
}, 300000);

export const handleDoublePaymentError = async errMessage => {
    const error = new DoublePaymentError(errMessage);
    await Sentry.captureException(error);
};
