<template>
    <div class="p-3">
        <RideSettlement v-if="booking.BookingStatus === 'InProgress' && booking.CompletingDateTime" :booking="booking" :app-data="appData" :use-local-meter="useLocalMeter" :local-meter="localMeter" />
        <RideSubmit :booking="booking" :payment-link-sent="paymentLinkSent" v-else />
    </div>
</template>

<script>
import RideSettlement from '@/components/completed-journey/RideSettlement';
import RideSubmit from '@/components/completed-journey/RideSubmit';

export default {
    name: 'CompletedBooking',
    props: {
        appData: {
            type: Object,
        },
        booking: {
            type: Object,
        },
        paymentLinkSent: {
            type: Boolean,
        },
        useLocalMeter: {
            type: Boolean,
        },
        localMeter: {
            type: Object,
        },
    },
    components: {
        RideSettlement,
        RideSubmit,
    },
};
</script>
