import './scss/style.scss';
import './utils/filters';
import '@/config.js';
import * as Sentry from '@sentry/vue';
import {addParamsToLocation, getParams} from '@/utils/helper';
import {init as ApiServiceInit} from '@/services/ApiService';
import {getBookingDetails, getDriverAppConfig, getDriverState} from '@/services/AuthService';
import {handle401Error, handleInvalidBookingError, handlePageLoadError} from '@/utils/sentryErrors';
import {verifyDriverToken} from '@/services/AuthService';
import App from './App.vue';
import calendar from 'dayjs/plugin/calendar';
import dayjs from 'dayjs';
import jQuery from 'jquery';
import Swal from 'sweetalert2';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueSignaturePad from 'vue-signature-pad';

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENV,
});

Vue.use(VueSignaturePad);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);

Vue.config.productionTip = false;
export const bus = new Vue();
Vue.use(VueCookies);
Vue.prototype.Swal = Swal;

const token = getParams('token');
const tokenFromCookies = Vue.$cookies.get('token');
const bookingId = getParams('bookingId') || localStorage.getItem('bookingId', bookingId);

// This will remove the params
addParamsToLocation({});

// Check if token is valid
(async function () {
    if (token || tokenFromCookies) {
        try {
            await Vue.$cookies.set('token', token ?? tokenFromCookies);
            let result;

            try {
                result = await verifyDriverToken();
            } catch (err) {
                if (err && err.response && (err.response.status === 401 || err.response.status === 400)) {
                    await handle401Error(err);
                } else {
                    await handlePageLoadError(err);
                }
                return;
            }

            if (result && result.DriverId) {
                if (!bookingId) {
                    await handleInvalidBookingError(null, `Missing booking id. Driver id - ${result.DriverId}`);
                    return;
                }

                const [driverState, driverAppConfig] = await Promise.all([getDriverState(), getDriverAppConfig()]);

                if (driverState.SumupDetails && driverState.SumupDetails.Id) {
                    driverState._showSumup = true;
                    delete driverState.SumupDetails;
                }
                Vue.prototype.driverState = driverState;

                let booking;
                try {
                    booking = await getBookingDetails(bookingId);
                } catch (err) {
                    await handleInvalidBookingError(err);
                    return;
                }

                if (!booking.DriverId) {
                    await handleInvalidBookingError(null, `No driver assigned to the booking. Booking id - ${booking.Id}. Driver id - ${result.DriverId}`);
                    return;
                }

                if (booking.DriverId.toLowerCase() !== result.DriverId.toLowerCase()) {
                    await handleInvalidBookingError(null, `Booking assigned to another driver. Booking id - ${booking.Id}. Driver id - ${result.DriverId}`);
                    return;
                }

                if (booking.BookingStatus === 'COA' || booking.BookingStatus === 'Cancelled') {
                    await handleInvalidBookingError();
                    return;
                }

                localStorage.setItem('user', JSON.stringify(result));
                localStorage.setItem('bookingId', bookingId);

                window.jQuery = window.$ = jQuery;
                window.$http = ApiServiceInit();

                const externalMeterEnabled = driverAppConfig && driverAppConfig.BluetoothMeter && driverAppConfig.BluetoothMeter.Active;
                const isExternalMeterBooking = booking.PricingMode === 'ActualRoute' && externalMeterEnabled;

                if (isExternalMeterBooking) {
                    const meterEventsRequired = driverAppConfig.BluetoothMeter.EnforceMeterEvents;
                    const driverExceptions = driverAppConfig.BluetoothMeter.DriverExceptions || [];

                    Vue.prototype.isExternalMeterBooking = true;
                    Vue.prototype.enforceMeterEvents = meterEventsRequired && driverExceptions.every(item => (item && item.toLowerCase()) !== booking.DriverId.toLowerCase());
                } else {
                    Vue.prototype.isExternalMeterBooking = false;
                    Vue.prototype.enforceMeterEvents = false;
                }

                new Vue({
                    render: h =>
                        h(App, {
                            props: {
                                initialBooking: booking,
                            },
                        }),
                }).$mount('#app');
            } else {
                await handle401Error(null, 'Not a driver token');
            }
        } catch (err) {
            if (err && err.response && err.response.status === 401) {
                await handle401Error(err);
            } else {
                await handlePageLoadError(err);
            }
        }
    } else {
        await handle401Error(null, 'Missing driver token');
    }
})();
