var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col gap-2" },
    [
      _vm.showCardPaymentCollected
        ? _c("div", { staticClass: "py-4 box flex flex-col gap-4" }, [
            _c(
              "div",
              { staticClass: "flex justify-between items-end" },
              [
                _c(
                  "div",
                  {},
                  [
                    _c("InfoLabel", {
                      attrs: {
                        value: "Card",
                        label: "Payment Method",
                        size: "text-4xl",
                      },
                    }),
                  ],
                  1
                ),
                _c("ActionButton", {
                  attrs: {
                    icon: "more_horiz",
                    action: _vm.openRideDetails,
                    color: "#9e9e9e",
                    "icon-color": "#fff",
                  },
                }),
              ],
              1
            ),
            _c("div", [
              _vm.booking.PaymentCollectionMode === "Completion"
                ? _c(
                    "span",
                    { staticClass: "text-green-500 font-medium text-xl" },
                    [
                      _vm._v(
                        "Payment for this journey will be collected via credit card."
                      ),
                    ]
                  )
                : _c(
                    "span",
                    { staticClass: "text-green-500 font-medium text-xl" },
                    [
                      _vm._v(
                        "Payment for this journey has been successfully collected via credit card."
                      ),
                    ]
                  ),
            ]),
          ])
        : _c("div", { staticClass: "box" }, [
            _c(
              "div",
              { staticClass: "flex justify-between items-end" },
              [
                _c(
                  "div",
                  [
                    _vm.isExternalMeterBooking
                      ? _c("InfoLabel", {
                          attrs: {
                            value: _vm.booking.PaymentMethod,
                            label: "Payment Method",
                            size: "text-4xl mt-1",
                          },
                        })
                      : _vm.localPriceLoader
                      ? _c(
                          "div",
                          { staticClass: "w-16 py-2" },
                          [
                            _c("LoaderIcon", {
                              staticClass: "h-16 w-16",
                              attrs: {
                                color: "#10b981",
                                "spin-duration": "1s",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm.booking.FlagDown &&
                        _vm.driverState.CanDriverAmendPriceOnFlagDown
                      ? _c("InfoLabel", {
                          attrs: {
                            value: _vm._f("currency")(_vm.editedTotal),
                            label:
                              _vm.booking.PaymentMethod === "Card"
                                ? "Prepaid/Card"
                                : "Total Fare",
                            size: "text-6xl",
                          },
                        })
                      : _vm.booking.PaymentMethod === "OnAccount" ||
                        _vm.booking._grandTotal < 0
                      ? _c("InfoLabel", {
                          attrs: {
                            value: "On Account",
                            label: "Payment",
                            size: "text-4xl",
                          },
                        })
                      : _c("InfoLabel", {
                          attrs: {
                            value: _vm._f("currency")(_vm.booking._grandTotal),
                            label:
                              _vm.booking.PaymentMethod === "Card"
                                ? "Prepaid/Card"
                                : "Total Fare",
                            size: "text-6xl",
                          },
                        }),
                  ],
                  1
                ),
                _c("ActionButton", {
                  attrs: {
                    icon: "more_horiz",
                    action: _vm.openRideDetails,
                    color: "#9e9e9e",
                    "icon-color": "#fff",
                  },
                }),
              ],
              1
            ),
            _vm.booking.PaymentMethod === "Card"
              ? _c(
                  "div",
                  { staticClass: "mt-2 text-green-500 font-semibold text-xl" },
                  [
                    _c("span", [
                      _vm._v(
                        "The payment for this booking has been made via a credit card."
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            (_vm.booking.PaymentMethod !== "OnAccount" &&
              _vm.booking._grandTotal >= 0) ||
            _vm.isExternalMeterBooking
              ? _c(
                  "div",
                  { staticClass: "mt-6" },
                  [
                    _vm.allowPriceEdit
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "flex gap-2 items-center justify-between",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "text-md font-bold text-gray-500",
                              },
                              [_vm._v("Journey Cost")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex border border-gray-200 bg-white text-3xl rounded focus-within:border-blue-500 focus-within:border-2",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "bg-gray-300 px-2 py-1 text-gray-700 font-medium",
                                  },
                                  [_vm._v("£")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.editedAmount,
                                      expression: "editedAmount",
                                    },
                                  ],
                                  ref: "priceInput",
                                  staticClass:
                                    "px-1 w-32 text-gray-700 dark:text-white font-semibold focus:outline-none text-right px-2 py-1",
                                  attrs: { type: "number" },
                                  domProps: { value: _vm.editedAmount },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.editedAmount = $event.target.value
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      : _c("Detail", {
                          attrs: {
                            value: _vm._f("currency")(_vm.journeyCostDisplayed),
                            label: "Journey Cost",
                          },
                        }),
                    !_vm.driverState.HideWaitingCost
                      ? [
                          _vm.useLocalMeter
                            ? [
                                _c("Detail", {
                                  attrs: {
                                    value: _vm._f("timeDuration")(
                                      _vm.localMeter.WaitingTime,
                                      false
                                    ),
                                    label: "Waiting Time",
                                    "additional-info": _vm._f("currency")(
                                      _vm.localMeter.WaitingCost
                                    ),
                                  },
                                }),
                                _c("Detail", {
                                  attrs: {
                                    value: _vm._f("currency")(0),
                                    label: "Waiting Tax",
                                  },
                                }),
                              ]
                            : [
                                _c("Detail", {
                                  attrs: {
                                    value: _vm._f("timeDuration")(
                                      _vm.booking.WaitingTimeInSeconds,
                                      false
                                    ),
                                    label: "Waiting Time",
                                    "additional-info": _vm._f("currency")(
                                      _vm.booking.WaitingTotal
                                    ),
                                  },
                                }),
                                _c("Detail", {
                                  attrs: {
                                    value: _vm._f("currency")(
                                      _vm.booking.WaitingTax
                                    ),
                                    label: "Waiting Tax",
                                  },
                                }),
                              ],
                        ]
                      : _vm._e(),
                    _vm.useLocalMeter
                      ? _c("Detail", {
                          attrs: {
                            value: _vm._f("currency")(_vm.localMeter.TaxAmount),
                            label: "Tax Amount",
                          },
                        })
                      : _vm.booking.TaxAmount || _vm.booking.TaxAmount === 0
                      ? _c("Detail", {
                          attrs: {
                            value: _vm._f("currency")(_vm.booking.TaxAmount),
                            label: "Tax Amount",
                          },
                        })
                      : _vm._e(),
                    _vm.booking.Adjustments &&
                    _vm.booking.Adjustments.length > 0
                      ? _c(
                          "div",
                          { staticClass: "mt-1 pt-2 border-gray-200 border-t" },
                          [
                            _c("InfoLabel", {
                              staticClass: "mb-1",
                              attrs: { value: "", label: "Ride Charges" },
                            }),
                            _vm._l(
                              _vm.booking.Adjustments,
                              function (adjustment) {
                                return _c("Detail", {
                                  key: adjustment.Id,
                                  attrs: {
                                    value: _vm._f("currency")(
                                      adjustment.Amount + adjustment.TaxAmount
                                    ),
                                    label: adjustment.Name,
                                  },
                                })
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.fetchingExpenses
                      ? _c(
                          "div",
                          [
                            _c("LoadingTitle", {
                              staticClass: "border-t border-gray-200 pl-0",
                              attrs: { title: "Fetching Extras..." },
                            }),
                          ],
                          1
                        )
                      : _vm.fetchExpensesError
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pt-6 pb-2 px-2 text-center text-gray-400",
                          },
                          [_vm._v("Error fetching extras")]
                        )
                      : _c(
                          "div",
                          { staticClass: "mt-1 pt-2 border-gray-200 border-t" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center gap-1 justify-between mb-2",
                              },
                              [
                                _c("InfoLabel", {
                                  attrs: { value: "", label: "Ride Extras" },
                                }),
                                _c("ActionButton", {
                                  attrs: {
                                    icon: "add",
                                    action: _vm.addExpense,
                                    color: "#ff8907",
                                    "icon-color": "#fff",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._l(_vm.expenses, function (cost) {
                              return _c(
                                "div",
                                {
                                  key: cost.label,
                                  staticClass: "grid grid-cols-2 py-1",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-bold w-auto pr-4 text-gray-500 col-span-1",
                                    },
                                    [_vm._v(_vm._s(cost.label))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-span-1 flex justify-between",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "w-20 font-semibold",
                                          class: cost.textColor,
                                        },
                                        [_vm._v(_vm._s(cost.status))]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-semibold pl-4 text-right",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(cost.value)
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                  ],
                  2
                )
              : _vm._e(),
          ]),
      _vm.showExpenseDialog
        ? _c("AddExpense", {
            attrs: { booking: _vm.booking },
            on: { cancel: _vm.hideExpenseModal },
          })
        : _vm._e(),
      !_vm.paymentCompleted &&
      _vm.showRidePayment &&
      _vm.hasSumup &&
      _vm.booking.PaymentMethod !== "OnAccount"
        ? _c("ActionButton", {
            attrs: {
              circular: false,
              icon: "credit_card",
              text: "Card Terminal Payment",
              color: "#3063E9",
              action: _vm.initiatePayment,
              disabled: _vm.disablePayment,
              async: true,
            },
          })
        : _vm._e(),
      _vm.driverState.CanDriverSendPaymentLink &&
      !_vm.paymentCompleted &&
      _vm.booking.PaymentMethod !== "OnAccount" &&
      _vm.booking.PaymentMethod !== "Card"
        ? _c("ActionButton", {
            attrs: {
              circular: false,
              icon: "textsms",
              text: "Send Payment Link",
              color: "#007BA7",
              action: function () {
                return (_vm.showPaymentLinkScreen = true)
              },
            },
          })
        : _vm._e(),
      _vm.booking.SignatureRequired
        ? _c("ActionButton", {
            attrs: {
              circular: false,
              icon: "draw",
              text: "Passenger Signature",
              color: "#059669",
              action: function () {
                return (_vm.showSignatureScreen = true)
              },
            },
          })
        : _vm._e(),
      _vm.showPaymentLinkScreen
        ? _c("SendPaymentLink", {
            attrs: { booking: _vm.booking, "complete-booking": true },
            on: {
              cancel: function ($event) {
                _vm.showPaymentLinkScreen = false
              },
              "complete-booking": _vm.completeBooking,
            },
          })
        : _vm._e(),
      _vm.showSignatureScreen
        ? _c("CustomerSignature", {
            attrs: { booking: _vm.booking },
            on: {
              cancel: function ($event) {
                _vm.showSignatureScreen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }