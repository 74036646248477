var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box mt-2 grid grid-cols-2 gap-2" }, [
    _c(
      "div",
      { staticClass: "flex items-center" },
      [
        _c("ActionButton", {
          staticClass: "mr-2",
          attrs: {
            async: true,
            icon: !_vm.allowWaiting
              ? "play_disabled"
              : _vm.isWaiting
              ? "stop_circle"
              : "play_circle",
            action: _vm.isWaiting ? _vm.stopWaiting : _vm.startWaiting,
            color: _vm.isWaiting ? "#e90000" : "#12c343",
            "icon-color": "#fff",
            disabled: !_vm.allowWaiting,
          },
        }),
        !_vm.localPriceLoader
          ? _c("InfoLabel", {
              attrs: {
                label: "Waiting",
                value: _vm._f("timeDuration")(_vm.waitingTime, false),
              },
            })
          : _c(
              "div",
              { staticClass: "w-8 py-2" },
              [
                _c("LoaderIcon", {
                  staticClass: "h-8 w-8",
                  attrs: { color: "#9e9e9e", "spin-duration": "1s" },
                }),
              ],
              1
            ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex items-center" },
      [
        _c("ActionButton", {
          staticClass: "mr-2",
          attrs: {
            disabled:
              _vm.booking.FlagDown &&
              _vm.driverState.CanDriverAmendPriceOnFlagDown,
            icon: "add",
            action: _vm.addExpense,
            color: "#ff8907",
            "icon-color": "#fff",
          },
        }),
        _c("InfoLabel", {
          attrs: {
            label: "Extras",
            value: _vm._f("currency")(_vm.booking._extrasTotal || 0),
          },
        }),
        _vm.showExpenseDialog
          ? _c("AddExpense", {
              attrs: { booking: _vm.booking },
              on: { cancel: _vm.hideExpenseModal },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }