<template>
    <button
        class="btn"
        :class="{'rounded-full w-12 h-12': circular && !small, 'h-12 w-full p-4 text-xl rounded-md text-white relative': !circular, 'rounded-full h-8 w-8': small}"
        type="button"
        :disabled="isLoading || disabled"
        @click="callAction"
        :style="{'background-color': color}"
        ref="button">
        <LoaderIcon class="loader" v-if="isLoading" />
        <template v-else>
            <div v-if="circular" class="flex flex-col">
                <span class="material-icons-outlined" :class="small ? 'text-base' : 'text-3xl'" :style="{color: iconColor}" v-if="icon">
                    {{ icon }}
                </span>
            </div>
            <div v-else class="flex items-center gap-2">
                <span class="material-icons-outlined -ml-1" :class="small ? 'text-base' : 'text-3xl'" :style="{color: iconColor}" v-if="icon">
                    {{ icon }}
                </span>
                <span v-if="text" :style="{color: textColor}">{{ text }}</span>
            </div>
        </template>
    </button>
</template>

<script>
import LoaderIcon from '@/components/loaders/LoaderIcon.vue';

export default {
    name: 'Button',
    props: {
        action: {
            type: Function,
            default: () => {},
        },
        async: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: '#ef4444',
        },
        iconColor: {
            type: String,
        },
        textColor: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
        },
        text: {
            type: String,
        },
        circular: {
            type: Boolean,
            default: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        LoaderIcon,
    },
    data() {
        return {
            isLoading: false,
            actions: {},
        };
    },
    methods: {
        async callAction() {
            if (this.isLoading || this.disabled) return;
            if (this.async) {
                this.isLoading = true;
                try {
                    await this.action();
                    this.isLoading = false;
                } catch (e) {
                    this.isLoading = false;
                }
            } else {
                this.action();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.5;
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0 !important;

    .loader {
        height: 50%;
    }

    &:focus {
        outline: none !important;
    }

    &:disabled {
        cursor: not-allowed !important;
        opacity: 0.5;
    }
}
</style>
