import {debounce} from '@/utils/helper.js';
import {getBookingDropAddresses, getLocations} from '@/services/StopService.js';

export const searchAddressMixin = {
    data() {
        return {
            searchText: '',
            passengerStops: [],
            searchResults: [],
            selectedAddress: null,
        };
    },
    methods: {
        async selectAddress(result) {
            this.$emit('select', result);
            this.selectedAddress = result;
            this.searchText = result.StopSummary;
            this.searchResults = [];
        },
        clearSearch() {
            this.$emit('select', null);
            this.selectedAddress = null;
            this.searchText = '';
        },
    },
    watch: {
        searchText: {
            handler: debounce(async function (text) {
                if (this.selectedAddress && text === this.selectedAddress.StopSummary) {
                    this.searchResults = [];
                } else if (text) {
                    this.$emit('select', null);
                    this.selectedAddress = null;
                    if (text.length > 3) {
                        this.searchResults = await getLocations(text);
                    } else {
                        this.searchResults = [];
                    }
                } else {
                    this.searchResults = this.passengerStops;
                }
            }, 500),
        },
    },
    async mounted() {
        const {Passenger, FlagDown} = this.booking;

        if (FlagDown) {
            this.passengerStops = [];
        } else {
            this.passengerStops = await getBookingDropAddresses(Passenger.Id);
        }

        this.searchResults = this.passengerStops;
    },
};
