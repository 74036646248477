export const handleJourneyCompletion = () => {
    try {
        if (window.iOSMessageListener) {
            window.iOSMessageListener(
                JSON.stringify({
                    type: 'journey',
                    message: 'journeyCompleted',
                })
            );
            window.iOSMessageListener(
                JSON.stringify({
                    type: 'ui',
                    message: 'navigateBack',
                })
            );
        }

        // eslint-disable-next-line
        if (cab9GoWeb) {
            // eslint-disable-next-line
            cab9GoWeb.postMessage(
                JSON.stringify({
                    type: 'journey',
                    message: 'journeyCompleted',
                })
            );
        }
    } catch (err) {
        console.error(err);
    }
};

export const handlePriceUpdate = data => {
    try {
        if (window.iOSMessageListener) {
            window.iOSMessageListener(
                JSON.stringify({
                    type: 'journey',
                    message: 'priceUpdate',
                    data,
                })
            );
        }

        // eslint-disable-next-line
        if (cab9GoWeb) {
            // eslint-disable-next-line
            cab9GoWeb.postMessage(
                JSON.stringify({
                    type: 'journey',
                    message: 'priceUpdate',
                    data,
                })
            );
        }
    } catch (err) {
        // ignore
    }
};

export const initiatePayment = (booking, totalCost) => {
    try {
        const payload = JSON.stringify({
            type: 'ui',
            message: 'sumUp',
            data: {
                localId: booking.LocalId,
                bookingId: booking.Id,
                driverId: booking.DriverId,
                amount: totalCost,
            },
        });

        if (window.iOSMessageListener) {
            window.iOSMessageListener(payload);
            return;
        }

        // eslint-disable-next-line
        if (cab9GoWeb) cab9GoWeb.postMessage(payload);
    } catch (err) {
        console.error(err);
    }
};

export const navigateBack = () => {
    try {
        if (window.iOSMessageListener) {
            window.iOSMessageListener(
                JSON.stringify({
                    type: 'ui',
                    message: 'navigateBack',
                })
            );
        }

        // eslint-disable-next-line
        if (cab9GoWeb)
            // eslint-disable-next-line
            cab9GoWeb.postMessage(
                JSON.stringify({
                    type: 'ui',
                    message: 'navigateBack',
                })
            );
    } catch (err) {
        console.error(err);
    }
};

export const recheckMobileState = () => {
    try {
        if (window.iOSMessageListener) {
            window.iOSMessageListener(
                JSON.stringify({
                    type: 'ui',
                    message: 'recheckMobileState',
                })
            );
        }

        // eslint-disable-next-line
        if (cab9GoWeb)
            // eslint-disable-next-line
            cab9GoWeb.postMessage(
                JSON.stringify({
                    type: 'ui',
                    message: 'recheckMobileState',
                })
            );
    } catch (err) {
        console.error(err);
    }
};

export const dialEmergencyNumber = () => {
    try {
        const payload = {
            type: 'ui',
            message: 'openExternalLink',
            data: {
                link: 'tel:112',
            },
        };

        if (window.iOSMessageListener) {
            window.iOSMessageListener(payload);
        } else {
            window.open('tel:112', '_self');
        }
    } catch (err) {
        console.error(err);
    }
};

export const openLink = link => {
    try {
        const payload = {
            type: 'ui',
            message: 'openExternalLink',
            data: {
                link,
            },
        };

        if (window.iOSMessageListener) {
            window.iOSMessageListener(payload);
        } else {
            window.open(link);
        }
    } catch (err) {
        console.error(err);
    }
};

export const callPassenger = number => {
    const payload = {
        type: 'ui',
        message: 'openExternalLink',
        data: {
            link: `tel:${number}`,
        },
    };

    if (window.iOSMessageListener) {
        window.iOSMessageListener(payload);
    } else {
        window.open(`tel:${number}`, '_self');
    }
};

export const textPassenger = number => {
    const payload = {
        type: 'ui',
        message: 'openExternalLink',
        data: {
            link: `sms:${number}`,
        },
    };

    if (window.iOSMessageListener) {
        window.iOSMessageListener(payload);
    } else {
        window.open(`sms:${number}`, '_self');
    }
};
