import {post, query} from '@/services/ApiService';

export const getLocationsFromServer = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await query('driver-api/locations', {
                bookingId,
            });
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const saveLocationsDebugInfo = (bookingId, locationDetails) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post('driver-api/locations/debug-info', {
                bookingId,
                ...locationDetails,
            });
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};
