var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          staticClass:
            "alert fixed bottom-0 left-0 top-0 flex items-end w-full",
        },
        [
          _c("Transition", { attrs: { name: "slide-up-down" } }, [
            _vm.showBox
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-col rounded-tl-xl rounded-tr-xl w-full bg-white p-4",
                  },
                  [
                    _c("div", { staticClass: "flex justify-between" }, [
                      _c("h3", { staticClass: "mb-0" }, [_vm._t("title")], 2),
                      _c(
                        "div",
                        { staticClass: "close-btn" },
                        [
                          _vm.showClose
                            ? _c("ActionButton", {
                                attrs: {
                                  async: false,
                                  icon: "close",
                                  action: _vm.hideAlert,
                                  color: "#e90000",
                                  "icon-color": "#fff",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("div", [_vm._t("body")], 2),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }