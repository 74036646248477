var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "rounded border border-gray-200 flex flex-col items-center pb-2 pt-0 relative gap-2",
    },
    [
      _c("div", { staticClass: "w-full p-2" }, [
        _c(
          "span",
          {
            staticClass:
              "text-xl text-center bg-gray-100 rounded p-4 inline-block w-full font-semibold dark:text-gray-600",
          },
          [_vm._v(_vm._s(_vm.nextJobTime))]
        ),
      ]),
      _c(
        "div",
        {
          staticClass:
            "flex justify-between w-full border-b border-gray-200 p-2",
        },
        [
          _vm.remainingTime > 0
            ? _c("div", { staticClass: "flex flex-col items-start" }, [
                _c("span", { staticClass: "text-lg font-semibold" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatDateTime")(
                        _vm.upcomingBooking.BookedDateTime,
                        "HH:mm"
                      )
                    )
                  ),
                ]),
                _c("span", { staticClass: "text-gray-400 font-semibold" }, [
                  _vm._v(_vm._s(_vm.day)),
                ]),
              ])
            : _c("div", { staticClass: "flex flex-col items-start" }, [
                _c("span", { staticClass: "text-lg font-semibold" }, [
                  _vm._v("ASAP"),
                ]),
              ]),
          _c("div", { staticClass: "flex flex-col items-end" }, [
            _c("span", { staticClass: "text-lg font-semibold uppercase" }, [
              _vm._v(_vm._s(_vm.upcomingBooking.VehicleType)),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "flex justify-center w-full p-2" }, [
        _c("div", { staticClass: "flex flex-col items-start flex-1" }, [
          _c("span", { staticClass: "text-lg font-semibold" }, [
            _vm._v(_vm._s(_vm.pickup.Address1)),
          ]),
          _c("span", { staticClass: "text-gray-400 font-semibold" }, [
            _vm._v(_vm._s(_vm.pickup.Postcode)),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-col items-center justify-center relative" },
          [
            _vm.extraStops
              ? _c("span", { staticClass: "absolute mb-8" }, [
                  _vm._v(_vm._s("+" + _vm.extraStops)),
                ])
              : _vm._e(),
            _c("span", { staticClass: "material-icons-outlined text-4xl" }, [
              _vm._v("trending_flat"),
            ]),
          ]
        ),
        _c("div", { staticClass: "flex flex-col items-end flex-1" }, [
          _c("span", { staticClass: "text-lg font-semibold text-right" }, [
            _vm._v(_vm._s(_vm.drop.Address1)),
          ]),
          _c("span", { staticClass: "text-gray-400 font-semibold" }, [
            _vm._v(_vm._s(_vm.drop.Postcode)),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "px-2 pt-2 w-full flex justify-center" },
        [
          _c("SwipeDragButton", {
            ref: "SwipeDragButton",
            attrs: {
              "handler-icon": "keyboard_double_arrow_right",
              background: "bg-green-500",
              "progress-bar-bg": "bg-green-500",
              "completed-bg": "bg-green-500",
              "handler-bg": "bg-green-600",
              width: _vm.buttonWidth,
              height: 60,
              "text-size": "24px",
              radius: "32px",
              text: "Start Ride",
            },
            on: { passcallback: _vm.startUpcomingRide },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }