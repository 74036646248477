import {getDropLocation} from './StopService';
import {post, put} from '@/services/ApiService';

export const setBookingOnRoute = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put(`driver-api/booking/status/onroute?bookingId=${bookingId}`);
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const setBookingArrived = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put(`driver-api/booking/status/arrived?bookingId=${bookingId}`);
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const setBookingInProgress = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put(`driver-api/booking/status/inprogress?bookingId=${bookingId}`);
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const setBookingNextStop = (bookingId, nextStop) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put(`driver-api/booking/next-stop?bookingId=${bookingId}&nextStop=${nextStop}`);
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const setBookingComplete = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put(`driver-api/booking/status/complete?bookingId=${bookingId}`);
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const completeFlagDownBooking = (bookingId, {latitude, longitude}, asDirected) => {
    return new Promise(async (resolve, reject) => {
        try {
            const dropLocation = await getDropLocation(latitude, longitude);
            const result = await post(`driver-api/flagdown/complete-booking?bookingId=${bookingId}`, {
                AsDirected: asDirected,
                DropLatitude: latitude,
                DropLongitude: longitude,
                DropStopSummary: dropLocation.label,
            });
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const resumeBooking = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put(`driver-api/resume-booking?bookingId=${bookingId}`);
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const setBookingAsCompleting = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put(`driver-api/completing-booking?bookingId=${bookingId}`);
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const coaBooking = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post(`driver-api/booking/status/COA?bookingId=${bookingId}`);
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};

export const handbackBooking = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post(`driver-api/booking/status/handback?bookingId=${bookingId}`);
            resolve(result);
        } catch (err) {
            return reject(err);
        }
    });
};
