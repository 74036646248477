<template>
    <div class="distance absolute top-0 right-0 flex flex-col text-right">
        <strong class="text-sm text-green-500">{{ journeySpeed | mph }} mph</strong>
    </div>
</template>

<script>
import {bus} from '@/main';

export default {
    name: 'Speed',
    data() {
        return {
            journeySpeed: 0,
        };
    },
    mounted() {
        bus.$on('speedUpdated', speed => {
            this.journeySpeed = speed;
        });
    },
};
</script>
