import {post} from '@/services/ApiService';

export const goOnBreak = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post('/driver-api/shift/onbreak');
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};
