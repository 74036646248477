var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "rounded-full px-4 py-1 border pre-written-message border whitespace-nowrap cursor-pointer no-select",
    },
    [_c("span", [_vm._v(_vm._s(_vm.text))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }