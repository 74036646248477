<template>
    <div class="py-2 overflow-y-auto scrolling-touch no-scrollbar">
        <div v-if="conversation">
            <infinite-loading direction="top" @infinite="infiniteMessagesHandler" :identifier="reloadKey">
                <div slot="spinner">
                    <Loader size="80px" text="Fetching messages..." />
                </div>
                <div slot="no-more" class="text-center"></div>
                <div slot="no-results" class="text-center">This is the very beginning of your conversation. <br />Say Hi! 😀</div>
            </infinite-loading>
            <div v-for="msg in messages" :key="msg._id">
                <ChatMessage :message="msg" :get-participants-mapping="participantsMapping" :conversation="conversation" :claims="claims"> </ChatMessage>
            </div>
        </div>
        <Loader size="80px" text="Initialising please wait..." v-else />
    </div>
</template>

<script>
import {bus} from '@/main';
import {getMessages} from '@/services/ChatService';
import {scrollToLatest} from '@/utils/helper';
import {showErrorToast} from '../../services/ToastService';
import ChatMessage from './ChatMessage';
import InfiniteLoading from 'vue-infinite-loading';
import Loader from './Loader';

export default {
    name: 'MessageList',
    props: {
        conversation: {
            type: Object,
        },
        claims: {
            type: Object,
        },
        messages: {
            type: Array,
        },
    },
    components: {
        ChatMessage,
        InfiniteLoading,
        Loader,
    },
    data() {
        return {
            participantsMapping: {},
            lastReceived: null,
            reloadKey: 0,
            messagesLoaded: false,
        };
    },
    methods: {
        async refetchMessages() {
            if (this.messagesLoaded) {
                this.lastReceived = null;
                this.$emit('setMessages', []);
                this.reloadKey += 1;
            }
        },
        async infiniteMessagesHandler($state) {
            try {
                this.messagesLoaded = true;
                const messages = await getMessages(this.conversation._id, this.lastReceived);
                if (messages?.length) {
                    const msgs = this.messages.slice();
                    msgs.unshift(...messages.reverse());
                    let isFirstTime;
                    if (!this.messages.length) {
                        isFirstTime = true;
                    }
                    this.$emit('setMessages', msgs);
                    this.lastReceived = msgs[0]?._id;
                    if ($state) {
                        $state.loaded();
                        bus.$emit('resetUnreadCount', 0);
                        if (isFirstTime) {
                            scrollToLatest();
                        }
                    }
                    // Save 1 API Call - if length is less than 20 then it means we have reached the end.
                    if (messages.length < 20) {
                        $state.complete();
                    }
                } else {
                    $state.complete();
                }
            } catch (err) {
                console.error(err);
                showErrorToast(err, 'Something went wrong whilst loading messages');
                $state.error();
            }
        },
    },
    mounted() {
        bus.$on('refetchMessages', this.refetchMessages);
    },
    unmounted() {
        bus.$off('refetchMessages', this.refetchMessages);
    },
};
</script>
