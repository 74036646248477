<template>
    <div class="rounded border border-gray-200 flex items-center justify-center gap-2 p-4 relative">
        <div class="flex flex-col flex-1">
            <span class="font-semibold">Journey Total</span>
            <div class="flex flex-col">
                <span class="text-5xl font-semibold">{{ booking.DriverCommission | currency }}</span>
                <span class="mt-2">This excludes any extras or waiting charges. Extras and waiting charges will be paid every week when driver statement is issued.</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DriverCommission',
    props: {
        booking: {
            type: Object,
        },
    },
};
</script>

<style scoped></style>
