import {put} from '@/services/ApiService';

export const pauseResumeFollowOn = ({pause}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put('driver-api/follow-on/pause-resume', {
                PauseFollowOn: pause,
            });
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};
