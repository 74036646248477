<template>
    <div class="rounded-md border border-gray-200 px-2 py-4" :class="paymentCompleted ? 'bg-green-200' : 'bg-yellow-200'">
        <div class="text-center font-semibold text-lg" :class="paymentCompleted ? 'text-green-700' : 'text-yellow-600'">{{ paymentCompleted ? 'Payment Succeeded' : 'Pending Payment' }}</div>
        <div v-if="paymentCompleted" class="pt-4 px-4 flex flex-col gap-1 items-center text-gray-500">
            <span class="material-icons-outlined text-6xl text-green-500">check_circle</span>
            <span class="text-center">Payment has now been successfully collected via credit card</span>
        </div>
        <div v-else class="pt-4 pb-2 px-8 flex flex-col gap-1 items-center">
            <LoaderIcon class="h-16 w-16" color="rgb(217, 119, 6)" />
            <span class="text-center font-medium text-gray-500">Waiting for the passenger to complete the payment</span>
        </div>
    </div>
</template>

<script>
import {bus} from '@/main';
import LoaderIcon from '@/components/loaders/LoaderIcon.vue';

export default {
    name: 'PaymentStatus',
    props: {
        booking: {
            type: Object,
        },
    },
    components: {
        LoaderIcon,
    },
    data() {
        return {
            pollBookings: null,
        };
    },
    computed: {
        paymentCompleted() {
            return this.booking?.CardPaymentStatus && this.booking?.CardPaymentStatus.toLowerCase() === 'success';
        },
    },
    watch: {
        paymentCompleted: function (val) {
            if (val && this.pollBookings) {
                clearInterval(this.pollBookings);
                this.pollBookings = null;
            }
        },
    },
    mounted() {
        if (!this.paymentCompleted) {
            this.pollBookings = setInterval(() => {
                bus.$emit('fetchUpdatedBooking', false, true);
            }, 10000);
        }
    },
};
</script>
