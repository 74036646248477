var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      ref: "button",
      staticClass: "btn",
      class: {
        "rounded-full w-12 h-12": _vm.circular && !_vm.small,
        "h-12 w-full p-4 text-xl rounded-md text-white relative": !_vm.circular,
        "rounded-full h-8 w-8": _vm.small,
      },
      style: { "background-color": _vm.color },
      attrs: { type: "button", disabled: _vm.isLoading || _vm.disabled },
      on: { click: _vm.callAction },
    },
    [
      _vm.isLoading
        ? _c("LoaderIcon", { staticClass: "loader" })
        : [
            _vm.circular
              ? _c("div", { staticClass: "flex flex-col" }, [
                  _vm.icon
                    ? _c(
                        "span",
                        {
                          staticClass: "material-icons-outlined",
                          class: _vm.small ? "text-base" : "text-3xl",
                          style: { color: _vm.iconColor },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.icon) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "flex items-center gap-2" }, [
                  _vm.icon
                    ? _c(
                        "span",
                        {
                          staticClass: "material-icons-outlined -ml-1",
                          class: _vm.small ? "text-base" : "text-3xl",
                          style: { color: _vm.iconColor },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.icon) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.text
                    ? _c("span", { style: { color: _vm.textColor } }, [
                        _vm._v(_vm._s(_vm.text)),
                      ])
                    : _vm._e(),
                ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }