<template>
    <div class="rounded-full px-4 py-1 border pre-written-message border whitespace-nowrap cursor-pointer no-select">
        <span>{{ text }}</span>
    </div>
</template>

<script>
export default {
    name: 'PreWrittenMessage',
    props: {
        text: {
            type: String,
        },
    },
};
</script>

<style>
.pre-written-message {
    background-color: #fdfdfd;
}
@media (prefers-color-scheme: dark) {
    .pre-written-message {
        background-color: #313131;
    }
}
</style>
