var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "font-semibold text-gray-400 mt-3 flex items-center gap-1" },
    [
      _c(
        "div",
        {
          staticClass: "rounded-full pointer inline-block p-1",
          staticStyle: { background: "#9e9e9e" },
        },
        [
          _c("img", {
            staticClass: "h-5 w-5",
            attrs: { src: _vm.waitAndReturnIcon, alt: "" },
          }),
        ]
      ),
      _c("span", { staticClass: "text-lg" }, [
        _vm._v("This is a wait and return booking."),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }