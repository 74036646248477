var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "distance absolute top-0 right-0 flex flex-col text-right" },
    [
      _c("strong", { staticClass: "text-sm text-green-500" }, [
        _vm._v(_vm._s(_vm._f("mph")(_vm.journeySpeed)) + " mph"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }