var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: {
        margin: "auto",
        background: "rgba(255, 255, 255, 0)",
        display: "block",
      },
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "101px",
        height: "101px",
        viewBox: "0 0 100 100",
        preserveAspectRatio: "xMidYMid",
      },
    },
    [
      _c(
        "circle",
        {
          attrs: {
            cx: "50",
            cy: "50",
            fill: "none",
            stroke: _vm.color,
            "stroke-width": "10",
            r: "35",
            "stroke-dasharray": "164.93361431346415 56.97787143782138",
            transform: "rotate(199.888 50 50)",
          },
        },
        [
          _c("animateTransform", {
            attrs: {
              attributeName: "transform",
              type: "rotate",
              repeatCount: "indefinite",
              dur: _vm.spinDuration,
              values: "0 50 50;360 50 50",
              keyTimes: "0;1",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }