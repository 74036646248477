var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Alert",
    { attrs: { show: _vm.show } },
    [
      _c("template", { slot: "title" }, [
        _c("span", { staticClass: "text-red-500 mt-2 text-center" }, [
          _vm._v("Driver Emergency"),
        ]),
      ]),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "div",
            { staticClass: "flex-1 flex flex-col gap-4 items-center" },
            [
              _c("span", { staticClass: "font-semibold text-lg" }, [
                _vm._v("Initiating SOS"),
              ]),
              _c(
                "span",
                {
                  staticClass:
                    "rounded-full bg-red-500 h-20 w-20 flex items-center justify-center text-white text-6xl",
                },
                [_vm._v(_vm._s(_vm.count))]
              ),
            ]
          ),
          _c("action-button", {
            staticClass: "w-full mt-6",
            attrs: {
              text: "CANCEL",
              color: "#34D399",
              circular: false,
              action: _vm.cancel,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }