<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; background: rgba(255, 255, 255, 0); display: block"
        width="101px"
        height="101px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" :stroke="color" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(199.888 50 50)">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" :dur="spinDuration" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
    </svg>
</template>

<script>
export default {
    name: 'LoaderIcon',
    props: {
        color: {
            type: String,
            default: '#ffffff',
        },
        spinDuration: {
            type: String,
            default: '1.5873015873015872s',
        },
    },
};
</script>
