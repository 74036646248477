var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-label flex flex-col" },
    [
      _c("div", { staticClass: "label flex items-center gap-2" }, [
        _c(
          "span",
          {
            staticClass: "text-md font-medium flex gap-1 items-center",
            style: { color: _vm.labelColour },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.label))]),
            _vm.link ? _c("span", [_vm._v("(Tap to Open)")]) : _vm._e(),
            _vm._t("labelInfo"),
          ],
          2
        ),
        _vm.icon
          ? _c(
              "span",
              {
                staticClass:
                  "material-icons-outlined text-base text-white rounded w-5 h-5 text-center leading-5",
                staticStyle: { background: "#9e9e9e" },
              },
              [_vm._v(_vm._s(_vm.icon))]
            )
          : _vm._e(),
      ]),
      _vm._t("valueInfo"),
      _vm.link
        ? _c("a", { attrs: { href: _vm.link, target: "_blank" } }, [
            _c("span", { staticClass: "value font-medium", class: _vm.size }, [
              _vm._v(_vm._s(_vm.value)),
            ]),
          ])
        : _c("span", { staticClass: "value font-medium", class: _vm.size }, [
            _vm._v(_vm._s(_vm.value)),
          ]),
      _vm.subText
        ? _c("span", { staticClass: "text-sm sub-text" }, [
            _vm._v(_vm._s(_vm.subText)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }