import {getBookingExpenses} from '@/services/ExpenseService.js';
import {showErrorToast} from '../services/ToastService';

export const bookingExpensesMixin = {
    data() {
        return {
            expenses: [],
            fetchingExpenses: true,
            fetchExpensesError: false,
        };
    },
    methods: {
        async setExpenses() {
            try {
                this.fetchExpensesError = false;
                this.fetchingExpenses = true;
                const expenses = await getBookingExpenses(this.booking?.Id);

                this.expenses = expenses.map(expense => {
                    // set expense status
                    let status = 'Pending',
                        color = 'text-yellow-500';
                    if (expense.Approved) {
                        status = 'Approved';
                        color = 'text-green-500';
                    } else if (expense.Approved === false) {
                        status = 'Declined';
                        color = 'text-red-500';
                    }
                    return {
                        id: expense.Id,
                        label: expense.ExpenseName,
                        value: expense.DriverAmount + (expense.TaxAmount || 0),
                        textColor: color,
                        status: status,
                    };
                });
                this.fetchingExpenses = false;
            } catch (err) {
                this.fetchingExpenses = false;
                showErrorToast(err, 'Couldn\'t fetch expenses');
                this.fetchExpensesError = true;
            }
        },
    },
};
