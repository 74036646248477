<template>
    <Alert :show="show">
        <template slot="title"><span class="text-red-500 mt-2 text-center">Driver Emergency</span></template>
        <template slot="body">
            <div class="flex-1 flex flex-col gap-4 items-center">
                <span class="font-semibold text-lg">Initiating SOS</span>
                <span class="rounded-full bg-red-500 h-20 w-20 flex items-center justify-center text-white text-6xl">{{ count }}</span>
            </div>
            <action-button text="CANCEL" class="w-full mt-6" color="#34D399" :circular="false" :action="cancel" />
        </template>
    </Alert>
</template>

<script>
import {addDriverEmergency} from '@/services/EmergencyService.js';
import {bus} from '@/main';
import {dialEmergencyNumber} from '@/services/PostMessageService';
import {showErrorDialog, showSuccessDialog} from '@/services/DialogService';
import ActionButton from '@/components/ActionButton';
import Alert from '@/components/Alert';
import sound from '@/assets/audio/alert.wav';

export default {
    name: 'Emergency',
    props: {
        booking: {
            type: Object,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ActionButton,
        Alert,
    },
    data() {
        return {
            count: 3,
        };
    },
    methods: {
        cancel() {
            // clear count interval
            if (this.countInterval) {
                clearInterval(this.countInterval);
            }

            // stop audio
            this.audio.pause();
            this.audio.currentTime = 0;

            this.$emit('cancel');
        },
    },
    watch: {
        show: {
            handler(val) {
                if (val) {
                    // play emergency sound
                    this.audio.play();

                    // start count from 3 to 0
                    this.count = 3;
                    this.countInterval = setInterval(async () => {
                        if (this.count > 0) {
                            this.count -= 1;
                        } else {
                            clearInterval(this.countInterval);
                            this.countInterval = null;

                            // stop audio
                            this.audio.pause();
                            this.audio.currentTime = 0;

                            addDriverEmergency()
                                .then(() => {
                                    this.$emit('cancel');
                                    showSuccessDialog('Emergency reported successfully.', 'OK');
                                })
                                .catch(err => {
                                    this.$emit('cancel');
                                    showErrorDialog(err, 'Some Error occurred whilst reporting the emergency', 'Try Again');
                                });

                            // call emergency number
                            dialEmergencyNumber();
                        }
                    }, 1000);
                }
            },
        },
    },
    mounted() {
        // load emergency sound
        this.audio = new Audio(sound);

        bus.$on('cancelEmergency', this.cancel);
    },
    destroyed() {
        bus.$off('cancelEmergency');
    },
};
</script>
