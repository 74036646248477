var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "action-area py-6 px-8" },
    [
      _c("SwipeDragButton", {
        ref: "SwipeDragButton",
        attrs: {
          "handler-icon": "keyboard_double_arrow_right",
          background: _vm.buttonInfo.color,
          "progress-bar-bg": _vm.buttonInfo.color,
          "completed-bg": _vm.buttonInfo.color,
          "handler-bg": _vm.buttonInfo.handlerColor,
          width: _vm.buttonWidth,
          height: 60,
          "text-size": "24px",
          radius: "32px",
          text: _vm.buttonInfo.text,
        },
        on: { passcallback: _vm.swipeHandler },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }