import {bus} from '@/main';

export const showErrorToast = (err, failureMessage) => {
    bus.$emit('SHOW_TOAST', {
        type: 'error',
        message: err?.data?.message || failureMessage,
    });
};

export const showSuccessToast = message => {
    bus.$emit('SHOW_TOAST', {
        type: 'success',
        message,
    });
};
