<template>
    <div
        class="flex p-2 border border-gray-300 rounded mb-2 gap-4 items-center"
        :class="{'border-red-600 bg-red-600 text-white': danger, 'border-green-600 bg-green-600 text-white': success, 'opacity-50': disabled}">
        <span class="material-icons-outlined text-3xl">{{ icon }}</span>
        <span class="text-lg font-semibold flex-1">{{ text }}</span>
        <span class="material-icons-outlined text-3xl">east</span>
    </div>
</template>

<script>
export default {
    name: 'MenuItem',
    props: {
        icon: {
            type: String,
        },
        text: {
            type: String,
            required: true,
        },
        danger: {
            type: Boolean,
            required: false,
        },
        success: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
        },
    },
};
</script>

<style scoped></style>
