<template>
    <Alert :show="showAlert" :show-close="true" @cancel="handleClose">
        <template v-slot:title>Passenger Signature</template>
        <template v-slot:body>
            <SignaturePad @cancel="handleClose" @uploaded="handleSignatureUploaded" :booking="booking" />
        </template>
    </Alert>
</template>

<script>
import Alert from '@/components/Alert';
import SignaturePad from './SignaturePad';

export default {
    name: 'CustomerSignature',
    props: {
        booking: {
            type: Object,
        },
        show: {
            type: Boolean,
        },
    },
    components: {
        Alert,
        SignaturePad,
    },
    data() {
        return {
            showAlert: true,
        };
    },
    methods: {
        handleClose() {
            this.showAlert = false;

            setTimeout(() => {
                this.$emit('cancel');
            }, 400);
        },
        handleSignatureUploaded() {
            this.handleClose();
        },
    },
};
</script>

<style scoped lang="scss"></style>
