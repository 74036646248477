var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("h3", { staticClass: "px-4 pt-4 flex items-center" }, [
    _c(
      "svg",
      {
        staticClass: "animate-spin mr-2 h-5 w-5 text-white",
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
        },
      },
      [
        _c("circle", {
          staticClass: "opacity-25",
          attrs: {
            cx: "12",
            cy: "12",
            r: "10",
            stroke: "#3d3d3d",
            "stroke-width": "4",
          },
        }),
        _c("path", {
          staticClass: "opacity-75",
          attrs: {
            fill: "#3d3d3d",
            d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z",
          },
        }),
      ]
    ),
    _vm._v("\n    " + _vm._s(_vm.title) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }