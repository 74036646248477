<template>
    <div class="flex flex-col overflow-hidden">
        <div class="px-2">
            <action-button icon="west" color="transparent" :action="back" />
        </div>
        <div class="flex-1 overflow-scroll details">
            <h3 class="px-4 pt-4">Booking {{ booking.LocalId }}</h3>
            <div class="journey-info px-4 mb-4 flex flex-col gap-3">
                <InfoLabel
                    :value="bookingTime"
                    label="Date/Time"
                    size="text-xl"
                    :sub-text="booking.FlightInfo && booking.FlightInfo.MinsAfterLanding ? `Includes a delayed pickup time of ${booking.FlightInfo.MinsAfterLanding} mins` : null"></InfoLabel>
                <InfoLabel :value="booking.ClientName || '-'" label="Account" size="text-xl"></InfoLabel>
                <InfoLabel :value="booking.Passenger.Firstname + ' ' + booking.Passenger.Surname" label="Passenger" size="text-xl"></InfoLabel>
                <div v-for="(stop, index) in booking.BookingStops" :key="index">
                    <template v-if="index === 0">
                        <InfoLabel :value="stop.StopSummary" label="Pickup" size="text-xl">
                            <template slot="valueInfo" v-if="stop.Passenger && (stop.Passenger.Firstname || stop.Passenger.Surname)">
                                <span class="text-xl text-blue-400 font-medium">{{ `${stop.Passenger.Firstname || '-'} ${stop.Passenger.Surname || '-'}` }}</span>
                            </template>
                        </InfoLabel>
                        <InfoLabel
                            v-if="booking.FlightInfo && booking.FlightInfo.FlightNumber"
                            :value="booking.FlightInfo.FlightNumber"
                            label="Flight Number"
                            size="text-xl"
                            class="mt-3"
                            icon="flight"></InfoLabel>
                        <InfoLabel v-if="booking.FlightInfo && booking.FlightInfo.Origin" :value="booking.FlightInfo.Origin" label="Arriving From" size="text-xl" class="mt-3"></InfoLabel>
                    </template>
                    <AddDropButton v-else-if="stop.StopSummary === 'As Directed'" :booking="booking" />
                    <InfoLabel :value="stop.StopSummary" label="DropOff" size="text-xl" v-else-if="index === booking.BookingStops.length - 1">
                        <template slot="valueInfo" v-if="stop.Passenger && (stop.Passenger.Firstname || stop.Passenger.Surname)">
                            <span class="text-xl text-blue-400 font-medium">{{ `${stop.Passenger.Firstname || '-'} ${stop.Passenger.Surname || '-'}` }}</span>
                        </template>
                    </InfoLabel>
                    <InfoLabel :value="stop.StopSummary" label="Via" size="text-xl" v-else>
                        <template slot="valueInfo" v-if="stop.Passenger && (stop.Passenger.Firstname || stop.Passenger.Surname)">
                            <span class="text-xl text-blue-400 font-medium">{{ `${stop.Passenger.Firstname || '-'} ${stop.Passenger.Surname || '-'}` }}</span>
                        </template>
                    </InfoLabel>
                </div>
                <InfoLabel :value="booking.VehicleType" label="Vehicle Type" size="text-xl"></InfoLabel>
                <div class="grid grid-cols-2 gap-4">
                    <InfoLabel :value="booking.Pax" label="Passenger" size="text-xl"></InfoLabel>
                    <InfoLabel :value="booking.Bax" label="Bags" size="text-xl"></InfoLabel>
                </div>
                <InfoLabel v-if="booking.DriverNotes" :value="booking.DriverNotes" label="Driver Notes" size="text-xl"></InfoLabel>
            </div>
            <h3 class="px-4 pt-4 border-t border-gray-300">Financial Details</h3>
            <div class="journey-info px-4 mb-4 flex flex-col gap-2">
                <InfoLabel :value="displayedPaymentMethod" label="Payment Method" size="text-xl"></InfoLabel>
                <InfoLabel :value="localMeter.ActualDistance || 0" label="Actual Distance (mi.)" size="text-xl" v-if="useLocalMeter"></InfoLabel>
                <template v-else-if="booking.AsDirected || booking.PricingMode === 'ActualRoute'">
                    <InfoLabel :value="booking.EstimatedDistance" label="Estimated Distance (mi.)" size="text-xl" v-if="booking.BookingStatus === 'OnRoute'"></InfoLabel>
                    <InfoLabel :value="booking.ActualDistance || 0" label="Actual Distance (mi.)" size="text-xl" v-else></InfoLabel>
                </template>
                <InfoLabel v-if="!isExternalMeterBooking" :value="booking.DriverCommission | currency" label="Driver Commission" size="text-xl"></InfoLabel>
            </div>
            <div v-if="fetchingExpenses">
                <LoadingTitle title="Fetching Extras..." class="border-t border-gray-200"></LoadingTitle>
            </div>
            <div v-else-if="fetchExpensesError" class="py-6 px-2 border-t border-gray-200 text-center" :style="{color: '#9e9e9e'}">Couldn't fetch extras</div>
            <div v-else>
                <div class="" v-if="expenses && expenses.length > 0">
                    <h3 class="px-4 pt-4 border-t border-gray-200">Extras</h3>
                    <div class="expense-info px-4 mb-4 flex flex-col gap-2 align-middle">
                        <div v-for="expense in expenses" :key="expense.Id" class="grid grid-cols-4">
                            <div class="col-span-2">
                                <InfoLabel :value="expense.value | currency" :label="expense.label" size="text-xl"></InfoLabel>
                            </div>
                            <div class="col-span-2 justify-between font-semibold flex items-center gap-3 py-1">
                                <div :class="expense.textColor">
                                    {{ expense.status }}
                                </div>
                                <ActionButton v-if="expense.status !== 'Approved'" :icon="'delete'" :action="() => deleteExpense(expense.id)" :color="'#9e9e9e'" :icon-color="'#fff'"></ActionButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {bookingExpensesMixin} from '@/mixins/bookingExpensesMixin.js';
import {bus} from '@/main';
import {confirmAndRunAction} from '@/services/DialogService';
import {removeExpense} from '@/services/ExpenseService.js';
import {showErrorToast, showSuccessToast} from '../services/ToastService';
import ActionButton from '@/components/ActionButton';
import AddDropButton from '@/components/journey-stops/AddDropButton';
import dayjs from 'dayjs';
import InfoLabel from '@/components/InfoLabel';
import LoadingTitle from '@/components/loaders/LoadingTitle';

export default {
    name: 'RideDetails',
    props: {
        booking: {
            type: Object,
        },
        navigateToMenu: {
            type: Boolean,
            default: true,
        },
        useLocalMeter: {
            type: Boolean,
        },
        localMeter: {
            type: Object,
        },
    },
    components: {
        ActionButton,
        AddDropButton,
        InfoLabel,
        LoadingTitle,
    },
    mixins: [bookingExpensesMixin],
    data() {
        return {
            journeyInfo: [],
        };
    },
    methods: {
        async deleteExpense(id) {
            confirmAndRunAction(
                'Delete Extra',
                'Are you sure you want to delete this extra?',
                null,
                null,
                null,
                null,
                async () => {
                    try {
                        await removeExpense(id);
                        showSuccessToast('Extra deleted successfully');
                    } catch (err) {
                        showErrorToast(err, 'Some error occurred whilst deleting the extra.');
                    }
                },
                () => {
                    this.expenses = this.expenses.filter(expense => expense.id !== id);
                    bus.$emit('fetchUpdatedBooking');
                }
            );
        },
        back() {
            this.$emit('close');
        },
    },
    computed: {
        displayedPaymentMethod() {
            if (this.booking._grandTotal < 0) return 'Voucher/PrePaid';
            return this.booking.PaymentMethod;
        },
        bookingTime() {
            const currentTime = new Date();
            const bookedDateTime = new Date(this.booking.BookedDateTimeBST);
            const arrivedDateTime = new Date(this.booking.DriverArrivedDateTimeBST);

            if (this.booking.DriverArrivedDateTimeBST && arrivedDateTime > bookedDateTime) {
                return 'ASAP';
            } else if (!this.booking.DriverArrivedDateTimeBST && currentTime > bookedDateTime) {
                return 'ASAP';
            } else {
                return dayjs(this.booking.BookedDateTimeBST).format('DD/MM/YYYY HH:mm');
            }
        },
    },
    watch: {
        'booking.Extras': {
            immediate: false,
            deep: true,
            handler() {
                this.setExpenses(this.booking);
            },
        },
    },
    async mounted() {
        await this.setExpenses(this.booking);
    },
};
</script>
