var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "rounded-md border border-gray-200 px-2 py-4",
      class: _vm.paymentCompleted ? "bg-green-200" : "bg-yellow-200",
    },
    [
      _c(
        "div",
        {
          staticClass: "text-center font-semibold text-lg",
          class: _vm.paymentCompleted ? "text-green-700" : "text-yellow-600",
        },
        [
          _vm._v(
            _vm._s(
              _vm.paymentCompleted ? "Payment Succeeded" : "Pending Payment"
            )
          ),
        ]
      ),
      _vm.paymentCompleted
        ? _c(
            "div",
            {
              staticClass:
                "pt-4 px-4 flex flex-col gap-1 items-center text-gray-500",
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "material-icons-outlined text-6xl text-green-500",
                },
                [_vm._v("check_circle")]
              ),
              _c("span", { staticClass: "text-center" }, [
                _vm._v(
                  "Payment has now been successfully collected via credit card"
                ),
              ]),
            ]
          )
        : _c(
            "div",
            { staticClass: "pt-4 pb-2 px-8 flex flex-col gap-1 items-center" },
            [
              _c("LoaderIcon", {
                staticClass: "h-16 w-16",
                attrs: { color: "rgb(217, 119, 6)" },
              }),
              _c(
                "span",
                { staticClass: "text-center font-medium text-gray-500" },
                [_vm._v("Waiting for the passenger to complete the payment")]
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }