var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.person
      ? _c(
          "div",
          {
            staticClass:
              "w-full pb-1 px-4 flex items-start relative break-words overflow-hidden",
            class: { "flex-row-reverse shift-right": _vm.isDriverSender },
            on: { click: _vm.sendMessage },
          },
          [
            _c("div", { staticClass: "flex-1 overflow-hidden" }, [
              _c(
                "div",
                {
                  staticClass:
                    "chat-msg relative inline-block mt-1 rounded-xl shadow-sm",
                  class: {
                    "float-right driver-msg": _vm.isDriverSender,
                    "bg-white text-black dark:text-white": !_vm.isDriverSender,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "content overflow-x-auto pt-2 pb-1 px-4 text-light-1 leading-snug lg:leading-normal",
                      staticStyle: { "white-space": "pre-line" },
                    },
                    [_vm._v(_vm._s(_vm.message.Body))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "text-xs pb-1.5 px-4",
                      class: {
                        "text-gray-200": _vm.isDriverSender,
                        "text-gray-500 text-left": !_vm.isDriverSender,
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.formatDate(_vm.message.DateTime)) +
                          "\n                "
                      ),
                    ]
                  ),
                ]
              ),
              _vm.isDriverSender
                ? _c("br", { staticClass: "clear-both" })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "w-full flex justify-end py-0.5 cursor-pointer",
                },
                [
                  _vm.message.IsFailed
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "text-red-500 rounded-xl py-0.5 px-1 font-semibold text-sm flex items-center pr-2 gap-1",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "material-icons-outlined text-base",
                            },
                            [_vm._v("error")]
                          ),
                          _c("span", [_vm._v("Couldn't send, tap to retry")]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }