import {put} from '@/services/ApiService';

export const pauseMeter = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put(`driver-api/pause-meter?bookingId=${bookingId}`);
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};

export const resumeMeter = bookingId => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await put(`driver-api/resume-meter?bookingId=${bookingId}`);
            resolve(result.data);
        } catch (err) {
            return reject(err);
        }
    });
};
