<template>
    <div class="box">
        <div class="relative">
            <div class="flex flex-col">
                <InfoLabel label="Payment" :value="displayedPaymentMethod" size="text-3xl" class="mb-2" v-if="displayedPaymentMethod"></InfoLabel>
                <template v-else>
                    <div v-if="updatingPrice || localPriceLoader">
                        <label class="font-medium" :style="{color: '#9e9e9e'}">{{ journeyTotalLabel }}</label>
                        <div class="w-16 py-2">
                            <LoaderIcon class="h-16 w-16" color="#10b981" spin-duration="1s" />
                        </div>
                    </div>
                    <InfoLabel v-else :label="journeyTotalLabel" :value="journeyTotal | currency" size="text-7xl"></InfoLabel>
                </template>
                <span class="text-red-500 font-semibold" v-if="partialPaymentsActive">Partial Payments Active</span>
                <span class="text-green-500 font-semibold mt-0.5" v-if="voucherApplied">Voucher Applied</span>
                <span class="text-green-500 font-semibold mt-0.5 text-xl" v-if="!displayedPaymentMethod && booking.PaymentMethod === 'Card'">Prepaid Card</span>
                <span
                    v-if="booking.BookingStatus === 'InProgress' && booking.PricingMode === 'ActualRoute'"
                    class="font-semibold mb-0"
                    :class="booking.MeterPaused ? 'text-red-500 pulse' : 'text-yellow-500'">{{ booking.MeterPaused ? 'Meter Paused' : 'Meter Running' }}</span>
                <div class="flex justify-between items-center">
                    <div class="flex flex-col">
                        <span v-if="useLocalMeter">Actual Distance: {{ (localMeter && localMeter.ActualDistance) || 0 }} mi</span>
                        <template v-else-if="booking.AsDirected || booking.PricingMode === 'ActualRoute'">
                            <span v-if="booking.BookingStatus === 'OnRoute'" class="text-sm"> Estimated Distance: {{ booking.EstimatedDistance }} mi </span>
                            <span v-else class="text-sm"> Actual Distance: {{ booking.ActualDistance || 0 }} mi </span>
                        </template>
                        <template v-if="booking.PaymentMethod !== 'OnAccount'">
                            <strong class="text-lg" v-if="useLocalMeter">Includes Waiting: {{ (localMeter.WaitingCost || 0) | currency }} </strong>
                            <strong class="text-lg" v-else>Includes Waiting: {{ (booking.WaitingTotal + (booking.WaitingTax || 0)) | currency }} </strong>
                        </template>
                    </div>
                </div>
            </div>
            <Speed />
            <div class="pause-journey absolute bottom-0 right-0" v-if="booking.BookingStatus === 'InProgress' && booking.PricingMode === 'ActualRoute'">
                <ActionButton
                    :async="true"
                    :icon="booking.MeterPaused ? 'play_circle' : 'pause'"
                    :action="booking.MeterPaused ? resumeMeter : pauseMeter"
                    :color="booking.MeterPaused ? '#12c343' : '#9e9e9e'"
                    :icon-color="'#fff'"></ActionButton>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from '@/main';
import {getBookingDetails} from '@/services/BookingService';
import ActionButton from '@/components/ActionButton';
import InfoLabel from '@/components/InfoLabel';
import LoaderIcon from '@/components/loaders/LoaderIcon.vue';
import Speed from '@/components/Speed';

export default {
    name: 'JourneyTotal',
    props: {
        booking: {
            type: Object,
            required: true,
        },
        journeySpeed: {
            type: Number,
        },
        pauseMeter: {
            type: Function,
        },
        resumeMeter: {
            type: Function,
        },
        useLocalMeter: {
            type: Boolean,
        },
        localMeter: {
            type: Object,
        },
    },
    components: {
        ActionButton,
        InfoLabel,
        Speed,
        LoaderIcon,
    },
    data() {
        return {
            partialPaymentsActive: false,
            updatingPrice: false,
            localPriceLoader: false,
        };
    },
    methods: {
        async refetchPrice() {
            try {
                this.updatingPrice = true;
                const updatedBooking = await getBookingDetails(this.booking.Id);
                updatedBooking.LocalWaitingTime = this.booking.LocalWaitingTime;

                bus.$emit('bookingUpdate', updatedBooking);

                this.updatingPrice = false;
            } catch (err) {
                console.error(err);
                this.updatingPrice = false;
            }
        },
        setPriceLoader(val) {
            this.localPriceLoader = val;
        },
    },
    computed: {
        journeyTotal() {
            return this.booking._grandTotal;
        },
        voucherApplied() {
            if (this.booking.Adjustments && this.booking.Adjustments.length) {
                const found = this.booking.Adjustments.find(ad => ad.Name && (ad.Name.includes('Coupon Code Discount') || ad.Name.includes('Loyalty Points Discount')) && ad.Amount);
                if (found) {
                    return true;
                }
            }
            return false;
        },
        journeyTotalLabel() {
            if (this.booking.BookingStatus === 'Arrived' || this.booking.BookingStatus === 'InProgress' || this.booking.BookingStatus === 'Clearing') {
                return 'Journey Total';
            } else {
                return 'Journey Estimate';
            }
        },
        displayedPaymentMethod() {
            if (this.booking.PaymentMethod === 'OnAccount') return 'OnAccount';
            if (this.booking._grandTotal < 0) return 'Voucher/PrePaid';
            ///TEMP FIX FOR ACCOUNT COMPANIES
            const clientsWhoSeeCardValue = [
                '64F9A648-9140-4191-854E-468B7A172E3A',
                '88EECACE-0F9E-48ED-9A84-0215B9734FD7',
                '6D50F460-6ECB-475F-BDCD-92BDD88AEBA6',
                'ECCD058B-E704-4AFC-9EBD-10D778B50926',
                'E8B23333-0498-4827-88B1-86A30B9279CC',
                '7E815512-2955-4B2F-8450-F80F95EA9F82',
                'A9BB8457-923B-4868-993C-EA700F9175C4',
                'FD3A20D2-3541-46EF-A46A-FBB8B27FED5F',
                '86D67E5F-83AF-47E6-AE8E-54E02D935C51',
                'E97F4D1C-094D-4DEF-A990-03E51FEB1AC5',
                'B21CBC10-05B8-4541-98FD-15F175E17754',
                'EADD6360-A80C-4560-94D6-491C7BEE1937',
                '4563DAF4-7A80-4748-A8BA-1AEF9C33CFC5',
                '7A17701B-A729-4EB8-8EB6-B18E03F90CBF',
                '425DD39C-890B-4AFB-A253-2315BCFC5FB3',
                '513D6033-273C-4866-8A3B-088A0FB59404',
                '4ADF55CE-0095-437E-A8F6-0E2AF180F44F',
                'CC5F5F08-7BBA-4F02-B4FB-5DDED2835CFE',
                '3C49FD24-F12B-407B-A3B9-FA4830CE349D',
                '53D998CC-025D-4D18-96AD-D13FD6E26261',
                'E7963623-D941-443A-8992-16BCC498920F',
                '241F9A04-BD38-4813-9EF6-FB7A4C9E8489',
                'BBD71D18-AC7A-4246-8E98-B87C01451480',
            ];
            if (this.booking.PaymentMethod !== 'Card' || clientsWhoSeeCardValue.indexOf(this.booking.TenantId.toUpperCase()) > -1) {
                return null;
            } else {
                return this.booking.PaymentMethod === 'Card' ? 'Prepaid/Card' : this.booking.PaymentMethod;
            }
            ///TEMP FIX FOR ACCOUNT COMPANIES
        },
    },
    created() {
        bus.$on('refetchPrice', this.refetchPrice);
        bus.$on('setPriceLoader', this.setPriceLoader);
        if (this.booking.Adjustments && this.booking.Adjustments.length) {
            const found = this.booking.Adjustments.find(ad => ad.Name === 'Account Partial Payment');
            if (found) {
                this.partialPaymentsActive = true;
            }
        }
    },
    destroyed() {
        bus.$off('refetchPrice', this.refetchPrice);
        bus.$off('setPriceLoader', this.setPriceLoader);
    },
};
</script>

<style scoped></style>
