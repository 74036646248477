var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Alert", {
    attrs: { show: _vm.showAlert, "show-close": true },
    on: { cancel: _vm.handleClose },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v("Passenger Signature")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("SignaturePad", {
              attrs: { booking: _vm.booking },
              on: {
                cancel: _vm.handleClose,
                uploaded: _vm.handleSignatureUploaded,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }