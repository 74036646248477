<template>
    <div class="h-full flex flex-col">
        <div class="flex items-center px-2 py-4 border-b border-gray-300 relative justify-center">
            <action-button icon="arrow_back" color="transparent" :action="close" class="absolute left-0" />
            <h5 class="text-center m-0 font-semibold">Remove Via Stop</h5>
        </div>
        <div class="flex-1 flex flex-col px-1 py-3 gap-2 overflow-hidden">
            <div class="flex flex-col flex-1 overflow-hidden pr-6">
                <template v-for="(stop, index) in booking.BookingStops">
                    <div v-if="removedStop !== stop.Id" class="flex items-center py-1" :class="{stop: index < booking.BookingStops.length - 1}" :key="stop.StopOrder">
                        <span class="bg-green-500 w-2 h-2 rounded-full m-2"></span>
                        <div class="flex flex-1 items-center bg-white border border-gray-200 rounded px-2 py-1">
                            <input class="flex-1 h-10 bg-white p-2" :value="stop.StopSummary" disabled />
                            <span
                                v-if="!removedStop && booking.NextStop <= stop.StopOrder && index !== booking.BookingStops.length - 1"
                                class="material-icons-outlined cursor-pointer text-gray-500"
                                @click="clearStop(stop.Id)">close</span>
                        </div>
                    </div>
                </template>
            </div>
            <div class="px-6 mt-2">
                <action-button text="Remove" :circular="false" color="#e90000" :action="removeViaStop" :disabled="!removedStop" :async="true" />
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from '@/main';
import {removeBookingStop} from '@/services/StopService';
import {showErrorDialog} from '@/services/DialogService';
import {showSuccessToast} from '../../services/ToastService';
import ActionButton from '@/components/ActionButton';

export default {
    name: 'RemoveViaStop',
    props: {
        booking: {
            type: Object,
        },
    },
    components: {
        ActionButton,
    },
    data() {
        return {
            removedStop: null,
        };
    },
    methods: {
        clearStop(stopId) {
            this.removedStop = stopId;
        },
        async removeViaStop() {
            try {
                await removeBookingStop(this.booking.Id, this.removedStop);
                bus.$emit('openHome');
                showSuccessToast('Stop removed successfully.');
                bus.$emit('fetchUpdatedBooking');
            } catch (err) {
                this.removedStop = null;
                showErrorDialog(err, 'There was an error removing the stop.', 'Try Again');
            }
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss">
.stop {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        left: 0.75rem;
        top: 50%;
        width: 1px;
        height: 100%;
        @apply bg-gray-300;
        z-index: -1;
    }
}
</style>
