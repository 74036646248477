var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col mt-2" },
    [
      _c(
        "div",
        { staticClass: "flex justify-between items-center mt-0 mb-3" },
        [
          _c(
            "div",
            { staticClass: "flex justify-center gap-2" },
            [
              _c("ActionButton", {
                attrs: {
                  icon: "navigation",
                  action: _vm.navigate,
                  color: "#9e9e9e",
                  "icon-color": "#fff",
                  text: "Navigate",
                  disabled: !(
                    _vm.nextStop &&
                    _vm.nextStop.Latitude &&
                    _vm.nextStop.Longitude
                  ),
                },
              }),
              _c("ActionButton", {
                attrs: {
                  icon: "list",
                  action: _vm.openMenu,
                  color: "#9e9e9e",
                  "icon-color": "#fff",
                  text: "Menu",
                },
              }),
              _vm.booking.BookingSource === "APP" &&
              _vm.driverState.ShowPassengerChat
                ? _c(
                    "div",
                    { staticClass: "relative" },
                    [
                      _vm.chatUnreadCount
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "bg-red-500 text-white text-xs absolute -right-1.5 rounded-full w-5 h-5 flex items-center justify-center -top-1",
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.chatUnreadCount))])]
                          )
                        : _vm._e(),
                      _c("ActionButton", {
                        attrs: {
                          icon: "chat_bubble_outline",
                          action: _vm.openChat,
                          color: "#9e9e9e",
                          "icon-color": "#fff",
                          text: "Chat",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mr-2" },
            [
              _vm.booking.BookingStatus === "InProgress"
                ? _c("ActionButton", {
                    attrs: {
                      async: true,
                      icon: "report",
                      action: function () {
                        return (_vm.startEmergency = true)
                      },
                      color: "#e90000",
                      "icon-color": "#fff",
                      text: "Emergency",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "col-span-3" }, [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("div", { staticClass: "flex justify-between" }, [
              _c(
                "div",
                { staticClass: "flex-1" },
                [
                  _c("InfoLabel", {
                    staticClass: "mb-3",
                    attrs: { label: "Name", value: _vm.passengerName },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "w-12" },
                [
                  _c("ActionButton", {
                    attrs: {
                      icon: "assignment",
                      action: _vm.openDetails,
                      color: "#9e9e9e",
                      "icon-color": "#fff",
                      text: "Details",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm.booking.BookingStatus === "OnRoute" &&
            _vm.booking.DriverNotes &&
            _vm.booking.DriverNotes.length > 0
              ? _c("InfoLabel", {
                  staticClass: "mb-4",
                  attrs: {
                    value: _vm._f("truncate")(_vm.booking.DriverNotes, 32),
                    label: "Driver Notes",
                  },
                })
              : _vm._e(),
            _vm.booking.FlightInfo && _vm.booking.FlightInfo.FlightNumber
              ? _c("div", { staticClass: "mb-2 text-gray-600" }, [
                  _vm._v("\n                ✈️ Flight No: "),
                  _c("span", {}, [
                    _vm._v(_vm._s(_vm.booking.FlightInfo.FlightNumber)),
                  ]),
                ])
              : _vm._e(),
            _vm.booking.BookingStatus === "OnRoute"
              ? _c(
                  "div",
                  { staticClass: "grid gap-4" },
                  _vm._l(_vm.booking.BookingStops, function (stop, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        index === 0
                          ? _c(
                              "InfoLabel",
                              {
                                attrs: {
                                  value: stop.StopSummary,
                                  label: "Pickup",
                                },
                              },
                              [
                                _c("template", { slot: "labelInfo" }, [
                                  _vm.pickupTime
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-semibold rounded flex items-center gap-1 text-base rounded-full px-3 py-1",
                                          class: _vm.runningLate
                                            ? "bg-red-100 text-red-700"
                                            : "bg-green-100 text-green-700",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "material-icons-outlined",
                                            },
                                            [_vm._v("schedule")]
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.pickupTime)),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              2
                            )
                          : stop.StopSummary === "As Directed"
                          ? _c("AddDropButton", {
                              attrs: { booking: _vm.booking },
                            })
                          : index === _vm.booking.BookingStops.length - 1
                          ? _c("InfoLabel", {
                              attrs: {
                                value: stop.StopSummary,
                                label: "DropOff",
                              },
                            })
                          : _c("InfoLabel", {
                              attrs: { value: stop.StopSummary, label: "Via" },
                            }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : (_vm.nextStop && _vm.nextStop.StopSummary) === "As Directed"
              ? [_c("AddDropButton", { attrs: { booking: _vm.booking } })]
              : _c(
                  "div",
                  [
                    _c("InfoLabel", {
                      attrs: {
                        label:
                          _vm.stopType +
                          " (" +
                          _vm.booking.BookingStops.length +
                          " Stops)",
                        value: _vm.nextStop && _vm.nextStop.StopSummary,
                      },
                    }),
                  ],
                  1
                ),
            _vm.booking.WaitAndReturn ? _c("WaitAndReturn") : _vm._e(),
          ],
          2
        ),
      ]),
      _c("Emergency", {
        attrs: { show: _vm.startEmergency },
        on: {
          cancel: function ($event) {
            _vm.startEmergency = false
          },
        },
      }),
      _vm.showNavigationDialog
        ? _c("Navigate", {
            attrs: { "next-stop": _vm.nextStop },
            on: {
              cancel: function ($event) {
                _vm.showNavigationDialog = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }